import React, { Component, Suspense } from "react";
import Spinner from "react-spinner";
import classNames from "classnames";
import AccCore from "opentok-accelerator-core";
import "opentok-solutions-css";
import moment from "moment";
import "./App.css";
import $ from "jquery";
import screenRecording from "../image/icons/video-recorder.gif"
import screenRecord from "../image/icons/vi-recod.png"
import { apiCalling } from "../apiService";
import AWS from "aws-sdk";
import { Link } from "react-router-dom";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
let otCore;
let recorder, stream;
var s3;
let projectId="";
let archaveId=""; 
const otCoreOptions = {
  credentials: {
    apiKey: window.localStorage.getItem("apiKey"),
    sessionId: window.localStorage.getItem("sessionId"),
    token: window.localStorage.getItem("token"),
  },

  streamContainers(pubSub, type, data, stream) {
    return {
      publisher: {
        camera: "#cameraPublisherContainer",
        screen: "#screenPublisherContainer",
      },
      subscriber: {
        camera: "#cameraSubscriberContainer",
        screen: "#screenSubscriberContainer",
      },
    }[pubSub][type];
  },
  controlsContainer: "#controls",
  // packages: ["textChat", "archiving", "annotation"],
  packages: ['textChat'],
  communication: {
    autoSubscribe: true,
    subscribeOnly: false,
    connectionLimit: null,

  },
  textChat: {
    name: [window.localStorage.getItem("userName")], // eslint-disable-line no-bitwise
    waitingMessage: "Messages will be delivered when other users arrive",
    container: "#chat",
    alwaysOpen: false,

  },
  screenSharing: {
    extensionID: "plocfffmbcclpdifaikiikgplfnepkpo",
    annotation: true,
    externalWindow: false,
    dev: true,
    screenProperties: {
      insertMode: "append",
      width: "100%",
      height: "100%",
      showControls: true,
      style: {
        buttonDisplayMode: "off",
      },
      videoSource: "window",
      fitMode: "contain", // Using default
    },
  },
  annotation: {
    absoluteParent: {
      publisher: ".App-video-container",
      subscriber: ".App-video-container",
    },
  },  
  
};

/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
const containerClasses = (state) => {
  const { active, meta, localAudioEnabled, localVideoEnabled } = state;
  const sharingScreen = meta ? !!meta.publisher.screen : false;
  const viewingSharedScreen = meta ? meta.subscriber.screen : false;
  const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
  const activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
  const activeCameraSubscribersOdd = activeCameraSubscribers % 2;
  const screenshareActive = viewingSharedScreen || sharingScreen;

  return {
    controlClass: classNames("App-control-container", { hidden: !active }),
    localAudioClass: classNames("ots-video-control circle audio", {
      hidden: !active,
      muted: !localAudioEnabled,
    }),
    localVideoClass: classNames("ots-video-control circle video", {
      hidden: !active,
      muted: !localVideoEnabled,
    }),
    localCallClass: classNames("ots-video-control circle end-call", {
      hidden: !active,
    }),
    cameraPublisherClass: classNames("video-container", {
      hidden: !active,
      small: !!activeCameraSubscribers || screenshareActive,
      left: screenshareActive,
    }),
    screenPublisherClass: classNames("video-container", {
      hidden: !active || !sharingScreen,
    }),
    cameraSubscriberClass: classNames(
      "video-container",
      { hidden: !active || !activeCameraSubscribers },
      { "active-gt2": activeCameraSubscribersGt2 && !screenshareActive },
      { "active-odd": activeCameraSubscribersOdd && !screenshareActive },
      { small: screenshareActive }
    ),
    screenSubscriberClass: classNames("video-container", {
      hidden: !viewingSharedScreen || !active,
    }),
  };
};

const connectingMask = () => (
  <div className="App-mask">
    <Spinner />
    <div className="message with-spinner">Connecting...</div>
  </div>
);

const startCallMask = (start) => (
  <div className="App-mask" style={{ display: "none" }}>
    <button className="message button clickable" onClick={start}>
      Click to Start Call{" "}
    </button>
  </div>
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      active: false,
      publishers: null,
      stream: null,
      subscribers: null,
      meta: null,
      localAudioEnabled: true,
      localVideoEnabled: true,
      streamCreated: false,
      chatStartTime: "",
      chatEndTime: "",
      chatEndFlag: false,
      s3bucketAccessKey: "",
      AWSAccessKeyId: "",
      bucket: "",
      screenRecordFile: "",
      screenRecordFileName: "",
      screenRecordFileNameFlag: false,
      vcStartTime: "",
      vcEndTime: "",
      showSession: false,
      bucketFolder:"",
      noChecking:"NO",
      popupvideoClassFlag:"0"
    };
    this.startCall = this.startCall.bind(this);
    this.endCall = this.endCall.bind(this);
    this.toggleLocalAudio = this.toggleLocalAudio.bind(this);
    this.toggleLocalVideo = this.toggleLocalVideo.bind(this);
    this.enableVc = this.enableVc.bind(this);
    this.getEventHandling = this.getEventHandling.bind(this);
    this.fileChange = this.fileChange.bind(this);
    this.endChatButton = this.endChatButton.bind(this);
    this.fullscreen = this.fullscreen.bind(this);
    this.stopArchive=this.stopArchive.bind(this);
  }
  awsCredentials() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        siteId: window.localStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          s3bucketAccessKey: data.configPathDetails[0].s3bucketAccessKey,
        });
        this.setState({
          AWSAccessKeyId: data.configPathDetails[0].s3bucketSecretKey,
        });

        this.setState({
          bucket: data.configPathDetails[0].s3BucketReliance,
        });
        this.setState({
          bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
      });
      }
    });
  }
  fileChange(e) {
    $(".loader").show();
    this.setState({ file: e.target.files[0] });
    if (e.target.files[0]) {
      this.fileUpload(e.target.files[0]);

     // console.log("1111111", e.target.files[0]);
      $("#attachImg").text(e.target.files[0].name);
      $("#aattachImg").attr("href", URL.createObjectURL(e.target.files[0]));
      $(".uploadattach").hide();
      $(".uploadattachBtn").show();
    } else {
      $(".App-chat-attach .messageFileUpload").removeClass("messageloader");
    }
  }
  fileUpload(file) {
    AWS.config.update({
      accessKeyId: this.state.s3bucketAccessKey,
      secretAccessKey: this.state.AWSAccessKeyId,
      region: this.state.region,
    });
    var bucketParams = {
      Bucket: this.state.bucket,
    };
    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = "test";
    let fileName = this.imagepath1() + file.name;
    let fullFilePath = S3BucketMainFolder + "/" + fileName;
    //alert(fullFilePath);
    this.setState({ fileName: fileName });

    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        $(".loader").hide();

        $(".App-chat-attach .messageFileUpload").removeClass("messageloader");

        if (err) {
          alert("There was an error uploading your photo: ", err.message);
          return false;
        } else if (data) {
         // console.log(data);
          this.setState({ fullFilePath: data.Location });
          alert("Successfully uploaded");
          
          return true;
        }
      }
    );
  }
  checkURL(url) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }
  onChangeFileUpload() {
    let fullPAth = this.state.fullFilePath;
    //$("#messageBox").val("sss");
    //$("#sendMessage").click();
    $("#messageBox").val(fullPAth);
    $("#sendMessage").click();
    $(".uploadattachBtn").hide();
    $(".uploadattach").show();
  }
  imagepath1 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
      c
    ) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };
  closeIt() {
    return (
      "Any string value here forces a dialog box to \n" +
      "appear before closing the window."
    );
  }

  getEventHandling() {
    var test = "";
    //alert(this.state.meta.subscriber.camera)
    if (this.state.meta) {
      if (this.state.meta.subscriber.camera === 0) {
        if (window.localStorage.getItem("role") === "ROLE_DOCTOR" && this.state.noChecking==="NO") {
          // test = window.confirm(
          //   "Patient not available ! Do you want to close the chat ?"
          // );
          this.setState({ popupvideoClassFlag: "1" });
          $(".popupvideoClass").attr("hidden", false);
          // if (test === true) {
          //   $(".loader").show();
          //   this.callDisconnectPush();
          //   this.endChatButton();
          // }
        }
        if (window.localStorage.getItem("role") === "ROLE_USER") {
          // test = window.confirm(
          //   "Doctor not available ! Do you want to close the chat ?"
          // );
          // if (test === true) {
          //   $(".loader").show();
          //   this.endChatButton();
          $(".popupvideoClassPatient").attr("hidden", false);
          // }
        }
      }
      // else{
      //this.setState({ showSession: true });
      // }
    }
  }
  videoRefresh() {
    this.setState({ popupvideoClassFlag: "0" });
    $(".popupvideoClass").attr("hidden", true);
    $(".popupvideoClassPatient").attr("hidden", true);
    window.location.reload(false);
  }
  videoOk() {
    $(".popupvideoClass").attr("hidden", true);
    $(".popupvideoClassPatient").attr("hidden", true);
    $(".loader").show();
    this.callDisconnectPush();
    this.endChatButton();
  }
  videoclose() {
    this.setState({ popupvideoClassFlag: "0" });
    $(".popupvideoClass").attr("hidden", true);
    $(".popupvideoClassPatient").attr("hidden", true);
  }
  componentWillMount() {
    // if (window.localStorage.getItem("role") === "ROLE_DOCTOR") {
    if (window.localStorage.getItem("drcallType") === "audio") {
      setInterval(async () => {
        //alert(this.state.meta.subscriber.camera)
        if (this.state.meta) {
          if (this.state.meta.subscriber.camera === 0) {
            $("#cameraSubscriberContainer").hide();
          }
          else {
            // $("#cameraSubscriberContainer").show();
          }
        }
      }, 10000);
    }
    // }
    ////if (window.localStorage.getItem("role") === "ROLE_USER") {
    setInterval(async () => {
      this.getEventHandling();
    }, 50000);
    //}

  }
//**************************************************************************** */
  startArchave(){
   // alert("Start")
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "startArchieve",
        sessionId: window.localStorage.getItem("sessionId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
     // console.log(data)
      if (data.success == "1") {
        $("#start").hide();
        $("#stop").show();
      archaveId=data.result.id;
      projectId=data.result.projectId
      }else{
        alert("Screen Recording Faild")
      }
    });
  }
  stopArchive(){
   // alert("Stop")
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "stopArchive",
        archiveId: archaveId,
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
       // alert("ok")
      this.saveScreenRecord();
      $("#start").show();
      $("#stop").hide();
      }
      else{
        alert("Screen Recording Faild")
      }
      
    });
  }
  saveScreenRecord() {  
   let filename=projectId+"/"+archaveId+"/archive.mp4";
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "saveRecordingDetails",
        appoinmentId: window.localStorage.getItem("reqAppointmentId"),
        doctorId: window.localStorage.getItem("userId"),
        fileName: filename,
      }),
    };  
   // console.log(apiJson.body)
    apiCalling(apiJson).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
       // console.log(data)
        alert("Recorded  Successfully");        
      }
    });

  }
//**************************************************************************** */
 
 

  componentDidMount() {
    //$("#cameraSubscriberContainer").hide();
    if (window.localStorage.getItem("role") === "ROLE_DOCTOR") {
      $("#popupvideoClass").show();
    } else {
      $("#popupvideoClassPatient").show();
    }

    if (window.localStorage.getItem("isRecordingAvailable" + window.localStorage.getItem("vcHospitalId")) === "true") {
      if (window.localStorage.getItem("role") === "ROLE_DOCTOR") {
        $(".recordContainer").show();
        $("#stop").hide();
   
      }
    }
    $("#enableTextChat").click(function () {
      $(".video-screen-icon").click();
    });

    $("#ots-dup-chat").click(function () {
      // alert("22")
      $("#chat").toggleClass("ots-hiddenn");
      $(this).toggle();
    });

    if (window.performance) {
      //alert(performance.navigation.type);
      if (performance.navigation.type === 1) {
        // alert("This page is reloaded");
      }
      if (performance.navigation.type === 2) {
        this.endChatButton();
      }
      if (performance.navigation.TYPE_RELOAD) {
        //alert("unloaod");
      }
    }

    this.awsCredentials();
    otCore = new AccCore(otCoreOptions);
    otCore.connect().then(() => {
      if (window.localStorage.getItem("drcallType") === "audio") {
        $("#cameraSubscriberContainer").hide();
      }
      this.setState({ connected: true });

      this.startCall();

    });
    
 
    // otCore.on("streamCreated", function (event) {
    //   this.setState({ streamCreated: true });
    // });

    otCore.on("messageSent", (event) => {
      $(".ots-item-text").find(":contains('purplehealthfiles')").hide();
      let curdateTime = moment().format("DD MMM YYYY hh:mm A");
      var d = new Date();
      let messages = "";
      var milliseconds = d.getMilliseconds();
      $(".ots-message-item.ots-message-sent").hide();
      $(".my-chat-span").next().hide();
      let str = "";
      let checkme = "";
      if (event.message.includes("https://")) {
        checkme = this.checkURL(event.message);
        if (checkme === true) {
          str =
            "<a href=" +
            event.message +
            " ><img src=" +
            event.message +
            "></a>";
        } else {
          str =
            "<a href=" +
            event.message +
            " ><img src='https://purplehealthfiles.s3.amazonaws.com/test/3c8b7d84-d3a1-4773-9ba4-8a2e3174fb3bgoogle-docs.png'></a>";
        }
      } else {
        str = event.message;
        messages = str.replace("?-0", "?").replace("?-1", "?");
        str = messages;
      }
      $("#messagesHolder").append(
        '<div class="ots-message-item ots-message-sent my-chat">' +
        '<div class="ots-user-name-initial"> ' +
        window.localStorage.getItem("userName") +
        "</div>" +
        '<div class="ots-item-timestamp"> ' +
        window.localStorage.getItem("userName") +
        ", <span> " +
        curdateTime +
        "</span></div>" +
        '<div class="ots-item-text my-chat">' +
        "<span class='my-chat-span'> " +
        str +
        "</span>" +
        "</div>" +
        "</div>"
      );
      $(".ots-message-item.ots-message-sent.my-chat").show();
      $("#messagesHolder").scrollTop($("#messagesHolder")[0].scrollHeight);

      const saveData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "chat/saveChat",
          siteId: window.localStorage.getItem("siteId"),
          hospitalId: window.localStorage.getItem("vcHospitalId"),
          chatGroupId: window.localStorage.getItem("vcchatGroupId"),
          userId: window.localStorage.getItem("userId"),
          message: event.message,
          currentTimeMillsec: milliseconds,
          bundleId: "",
        }),
      };
      // alert(saveData.body);
      apiCalling(saveData).then((data) => {
        //  alert(data.success);
      });
    });
    otCore.on("signal", (event) => {
      if (event.data.includes("sender")) {
        // alert("if")
        // let text = JSON.parse(event.data);
        //       let  textReceiver = text.text;
        //       alert(textReceiver)
      } else {
        let fromMobileMessage = event.data;
        let createdDate = event.from.creationTime;
        let who = event.from.data;
        let senderName = who.split(/\s*,\s*/);
        senderName = senderName[0];
        this.messageReceived(fromMobileMessage, createdDate, senderName);
        //***************************************** */
      }
    });
    otCore.on("messageReceived", (event) => {
     // console.log(event);
      let recMsg = "";
      let messages = "";
      //alert("event.data..." + event.data);
      let dataSet = JSON.parse(event.data);
      let sender = JSON.stringify(dataSet.sender);
      //alert("dataSet..." + sender);
      let dataSet2 = JSON.parse(sender);
      let senderName = dataSet2.alias;
      let curdateTime = moment(dataSet2.sentOn).format("DD MMM YYYY hh:mm A");
      //alert(curdateTime);
      let recMessage = dataSet.text;
      this.messageReceived(recMessage, dataSet2.sentOn, senderName);
    });

    // otCore.on("messageReceived", (event) => {
    //   $("#chat").removeClass("ots-hiddenn");
    //   $("#ots-dup-chat").toggle();
    //   console.log(event);
    //   let recMsg = "";
    //   let messages = "";
    //   //alert("event.data..." + event.data);
    //   let dataSet = JSON.parse(event.data);
    //   let sender = JSON.stringify(dataSet.sender);
    //   //alert("dataSet..." + sender);
    //   let dataSet2 = JSON.parse(sender);
    //   let senderName = dataSet2.alias;
    //   let curdateTime = moment(dataSet2.sentOn).format("DD MMM YYYY hh:mm A");
    //   //let senderName = dataSet2.alias;
    //   // alert(senderName);
    //   let recMessage = dataSet.text;
    //   $(".ots-message-item").hide();
    //   //$(".ots-message-item.ots-message-sent").show();
    //   $(".ots-message-item.my-chat").show();
    //   $(".my-chat-span").next().hide();
    //   let str = "";
    //   let checkme = "";
    //   if (recMessage.includes("https://")) {
    //     checkme = this.checkURL(recMessage);
    //     if (checkme === true) {
    //       str = "<a href=" + recMessage + " ><img src=" + recMessage + "></a>";
    //     } else {
    //       str =
    //         "<a href=" +
    //         recMessage +
    //         " ><img src='https://purplehealthfiles.s3.amazonaws.com/test/3c8b7d84-d3a1-4773-9ba4-8a2e3174fb3bgoogle-docs.png'></a>";
    //     }
    //   } else {
    //     str = recMessage;
    //     messages = str.replace("?-0", "?").replace("?-1", "?");
    //     str = messages;
    //   }
    //   $("#messagesHolder").append(
    //     '<div class="ots-message-item  my-chat">' +
    //       '<div class="ots-user-name-initial"> ' +
    //       senderName +
    //       "</div>" +
    //       '<div class="ots-item-timestamp">' +
    //       senderName +
    //       "," +
    //       curdateTime +
    //       " <span>" +
    //       "</span>" +
    //       "</div>" +
    //       '<div class="ots-item-text my-chat">' +
    //       "<span class='my-chat-span'> " +
    //       str +
    //       "</span>" +
    //       "</div>" +
    //       "</div>"
    //   );
    //   $("#messagesHolder").scrollTop($("#messagesHolder")[0].scrollHeight);

    //   if (window.localStorage.getItem("role") === "ROLE_USER") {
    //     recMsg = recMessage.split(/\s*-\s*/);
    //     this.setState({ question: recMsg[1] });
    //     this.setState({ questionId: recMsg[0] });
    //     this.setState({ questionFlag: recMsg[2] });
    //     //alert(recMsg[1]);
    //     //this.setState({question:recMsg[1]})
    //     if (recMsg.length === 2) {
    //       // recMsg = recMessage.split(/\s*-\s*/);
    //       if (recMsg[1] === "0") {
    //         $(".ots-message-item:last-child .ots-item-text").append(
    //           $("#questionDiv0")
    //         );
    //       } else {
    //         $(".ots-message-item:last-child .ots-item-text").append(
    //           $("#questionDiv1")
    //         );
    //       }
    //     }
    //   }
    // });

    // setInterval(async () => {

    const events = [
      'subscribeToCamera',
      'unsubscribeFromCamera',
      'subscribeToScreen',
      'unsubscribeFromScreen',
      'startScreenShare',
      'endScreenShare',
    //  'startArchive',
   // 'stopArchive',
   
    ];


    events.forEach((event) =>
      otCore.on(event, ({ publishers, subscribers, meta, stream }) => {        
        this.setState({ publishers, subscribers, meta, stream });
        this.setState({ popupvideoClassFlag: "0" });
        $(".popupvideoClass").attr("hidden", true);
        $("#ButtonendCall").attr("hidden", false);
      })
    );
    // $("#enableTextChat").click(function () {
    //   $(".video-chat-main").toggleClass("video-chat-fullScreen");
    //   $(".video-chat-wrapper").toggleClass("video-chatwrapper-fullScreen");
    // });

  }
  checkURLMP3(url) {
    return url.match(/\.(mp3)$/) != null;
  }
  messageReceived(fromMobileMessage, createdDate, senderName) {
    $("#chat").removeClass("ots-hiddenn");
    $("#ots-dup-chat").toggle();
    let recMsg = "";
    let messages = "";
    let curdateTime = moment(createdDate).format("DD MMM YYYY hh:mm A");
    //alert(curdateTime);
    let recMessage = fromMobileMessage;
    $(".ots-message-item").hide();
    //$(".ots-message-item.ots-message-sent").show();
    $(".ots-message-item.my-chat").show();
    $(".my-chat-span").next().hide();
    //$("#questionDiv1").show();

    let str = "";
    let checkme = "";
    if (recMessage.includes("https://")) {
      let checkmemp3 = this.checkURLMP3(recMessage);
      if (checkmemp3 === true) {
        str = "<audio src=" + recMessage + " controls='controls' />"
      }
      else {
        checkme = this.checkURL(recMessage);
        if (checkme === true) {
          str = "<a href=" + recMessage + " ><img src=" + recMessage + "></a>";
        } else {
          str =
            "<a href=" +
            recMessage +
            " ><img src='https://purplehealthfiles.s3.amazonaws.com/test/3c8b7d84-d3a1-4773-9ba4-8a2e3174fb3bgoogle-docs.png'></a>";
        }
      }
    } else {
      str = recMessage;
      messages = str.replace("?-0", "?");
      messages = messages.replace("?-1", "?").replace("-1", "?");
      str = messages;
    }
    $("#messagesHolder").append(
      '<div class="ots-message-item  my-chat">' +
      '<div class="ots-user-name-initial"> ' +
      recMessage +
      "</div>" +
      '<div class="ots-item-timestamp">' +
      senderName +
      "," +
      curdateTime +
      " <span>" +
      "</span>" +
      "</div>" +
      '<div class="ots-item-text my-chat">' +
      "<span class='my-chat-span'> " +
      str +
      "</span>" +
      "</div>" +
      "</div>"
    );
    $("#messagesHolder").scrollTop($("#messagesHolder")[0].scrollHeight);

    if (window.localStorage.getItem("role") === "ROLE_USER") {
      recMsg = recMessage.split(/\s*-\s*/);
      this.setState({ question: recMsg[1] });
      this.setState({ questionId: recMsg[0] });
      this.setState({ questionFlag: recMsg[2] });
      //alert(recMsg[1]);
      //this.setState({question:recMsg[1]})
      if (recMsg.length === 2) {
        // recMsg = recMessage.split(/\s*-\s*/);
        if (recMsg[1] === "0") {
          $(".ots-message-item:last-child .ots-item-text").append(
            $("#questionDiv0")
          );
          $(".my-chat-span").next("#questionDiv0").show();
        } else {
          $(".ots-message-item:last-child .ots-item-text").append(
            $("#questionDiv1")
          );
          $(".my-chat-span").next("#questionDiv1").show();
        }
      }
    }
  }
  callDisconnectPush() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "callDisconnectPush",
        siteId: window.localStorage.getItem("siteId"),
        scheduleTimeSlotId: window.localStorage.getItem("vcScheduledTimeSlotId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
    });

  }

  endChatButton() {
    if (this.state.popupvideoClassFlag === "1") {
      const json = {
        method: "POST",
        body: JSON.stringify({
          functionName: "updateStatus",
          scheduleTimeSlotId: window.localStorage.getItem(
            "vcScheduledTimeSlotId"
          ),
          doctorId: window.localStorage.getItem("userId"),
          status: "Missed",
        }),
      };
    }
    $(".loader").show();
    $(".ots-dup-circle").hide();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "disconnectVideoCall",
        patientId: window.localStorage.getItem("vcPatientId"),
        siteId: window.localStorage.getItem("siteId"),
        billingEndTime: moment(new Date()).format("DD MMM YYYY hh:mm A"),
        hospitalId: window.localStorage.getItem("vcHospitalId"),
        doctorId: window.localStorage.getItem("vcDoctorId"),
        type: this.state.popupvideoClassFlag === "1" ? "missed" : "completed",
        doctorVideoScheduleId: window.localStorage.getItem("vcDoctorVideoScheduleId"),
        scheduleTimeSlotId: window.localStorage.getItem("vcScheduledTimeSlotId"),
        isSessionVideoEnded: "false",
        sessionChatNo: window.localStorage.getItem("vcSessionChatNo"),
        chatComments: "",
        bundleId: "",
      }),
    };

    //console.log(apiJson.body);
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        // alert("Your chat session has been ended");
        window.localStorage.setItem(
          "videochatTransactionId",
          data.result.videochatTransactionId
        );
        if (window.localStorage.getItem("role") === "ROLE_DOCTOR") {
          window.location.href = "../doctor/videoSchedule";
        }
        if (window.localStorage.getItem("role") === "ROLE_USER") {
          window.location.href = "../patient/videochatsuccess";
        }
      } else {
        alert(data.errorMessage);
        $(".loader").hide();
      }
    });
  }

  endChat() {

    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "completeChatStatus",
        requestVideoAppointmentId: window.localStorage.getItem(
          "doctorVideoScheduleId"
        ),
        appointmentStatus: "Call Completed",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        alert("Call ended");
        if (window.localStorage.getItem("role") === "ROLE_DOCTOR") {
          window.location.href = "../doctor/videoSchedule";
        }
        if (window.localStorage.getItem("role") === "ROLE_USER") {
          window.location.href = "../patient/videochatsuccess";
        }
      }
      $(".loader").hide();
    });
  }

  startCall() {
    otCore
      .startCall()
      .then(({ publishers, subscribers, meta }) => {
        this.setState({ publishers, subscribers, meta, active: true });

        this.setState({ vcStartTime: moment(new Date()).format("DD-MM-YYYY ") })
        //otCore.toggleLocalAudio(false);
        //this.setState({ localAudioEnabled: !this.state.localAudioEnabled });

        let drcallType = window.localStorage.getItem("drcallType");
        //alert(drcallType)
        if (drcallType === "audio") {
          otCore.toggleLocalVideo(!this.state.localVideoEnabled);
          this.setState({ localVideoEnabled: false });
          $("#vcEnable").hide();
        }

        $(".App-chat-attach,.video-chat-left").show();
      
        $(".messageFileUpload").show();

        //$("#enableTextChat").click();

       $(".text-chat").hide();
       $(".ots-dup-circle-active").show();
       // this.showOldMessages();
      })
      .catch((error) => console.log(error));
  }
  showOldMessages() {

    let oldData = [];
    //$(".loader").show();
    let messages = "";
    let str = "";
    const json = {
      method: "POST",
      body: JSON.stringify({
        functionName: "startTextChat",
        chatGroupId: window.localStorage.getItem("vcchatGroupId"),
        userId: window.localStorage.getItem("userId"),
        hospitalId: window.localStorage.getItem("vcHospitalId"),
      }),
    };
    //console.log(json.body);
    apiCalling(json).then((data) => {
      //$(".loader").hide();
      if (data.success === "1") {
        // alert("...." + data.result[0].oldMessages);
        //$("#startRecord").show();
        // $(".messageFileUpload").show();
        //alert(data.result[0].isExpired);
        // alert(data.result[0].messagingCost);
       // console.log(data.result[0].oldMessages)
        this.setState({ oldMessage: data.result[0].oldMessages });
        oldData.push(data.result[0].oldMessages);
        this.setState({ isExpired: data.result[0].isExpired });
        window.localStorage.setItem("appDocId", data.result[0].doctorId);
        window.localStorage.setItem("appHosId", data.result[0].hospitalId);
        window.localStorage.setItem("chatDoctorPanelId", data.result[0].chatGroupId);
        window.localStorage.setItem("doctorPanelId", data.result[0].chatDoctorPanelId);
        window.localStorage.setItem("messaging", data.result[0].messagingCost);
        window.localStorage.setItem("memberId_text", data.result[0].memberId);
        this.setState({ payAmount: data.result[0].messagingCost });
        if (window.localStorage.getItem("role") === "ROLE_USER") {
          if (data.result[0].isExpired === true) {
            $(".ots-messages-holder").addClass("ots-messages-holder-isExpired");
            $("#messageBox").hide();
            $("#startRecord").hide();
            $("#sendMessage").hide();
            $("#controls").hide();
            $("#boottomDiv").hide();
            $("#paymentReqBlock").show();
            $(".messageFileUpload").hide();
            //alert(data.result[0].messagingCost);

          }
        }
        let flag = 0;
        data.result[0].oldMessages.map((vid, vin) => {
          let showBlock = "";
          flag = flag + 1;
          if (
            vid.role === "ROLE_HOSPITALADMIN" &&
            window.localStorage.getItem("role") === "ROLE_DOCTOR"
          ) {
          } else {
            if (vid.message.includes("https://")) {
              let checkmemp3 = this.checkURLMP3(vid.message);
              if (checkmemp3 === true) {
                str = "<audio src=" + vid.message + " controls='controls' />"
              }
              else {
                let checkme = this.checkURL(vid.message);
                if (checkme === true) {
                  str =
                    "<a href=" +
                    vid.message +
                    " ><img src=" +
                    vid.message +
                    "></a>";
                } else {
                  str =
                    "<a href=" +
                    vid.message +
                    " ><img src='https://purplehealthfiles.s3.amazonaws.com/test/3c8b7d84-d3a1-4773-9ba4-8a2e3174fb3bgoogle-docs.png'></a>";
                }
              }
            } else {
              str = vid.message;
              if (str.includes("?-0")) {
                messages = str
                  .replace("?-0", "?")
                  .replace("?-1", "?")
                  .replace("-1", "?");
                str = messages;
                showBlock = $("#questionDiv0").html();
              }
              if (str.includes("?-1")) {
                messages = str
                  .replace("?-0", "?")
                  .replace("?-1", "?")
                  .replace("-1", "?");
                str = messages;
                showBlock = $("#questionDiv1").html();
              }
            }
            str = str
              .replace("?-0", "?")
              .replace("?-1", "?")
              .replace("-1", "?");
            if (
              parseInt(vid.senderId) ===
              parseInt(window.localStorage.getItem("userId"))
            ) {
              $("#messagesHolder").append(
                '<div class="ots-message-item ots-message-sent my-chat">' +
                '<div class="ots-user-name-initial"> ' +
                vid.sender +
                "</div>" +
                '<div class="ots-item-timestamp"> ' +
                vid.sender +
                ', <span <="" span="">' +
                vid.createdDate +
                "</span></div>" +
                '<div class="ots-item-text">' +
                "<span> " +
                str +
                "</span>" +
                "<div id=abcd" +
                flag +
                "></div>" +
                // showBlock +
                "</div>" +
                "</div>" +
                "</div>"
              );
              //$("#abcd" + flag).append($("#questionDiv1"));
            } else {
              $("#messagesHolder").append(
                '<div class="ots-message-item my-chat">' +
                '<div class="ots-user-name-initial"> ' +
                vid.sender +
                "</div>" +
                '<div class="ots-item-timestamp"> ' +
                vid.sender +
                ', <span <="" span="">' +
                vid.createdDate +
                "</span></div>" +
                '<div class="ots-item-text">' +
                "<span> " +
                str +
                "</span>" +
                showBlock +
                "<div id=abcd" +
                flag +
                "></div>" +
                "</div>" +
                "</div>"
              );
            }

            $("#messagesHolder").scrollTop(
              $("#messagesHolder")[0].scrollHeight
            );
          }
        });

        //$(".loader").hide();
        $("#messagesHolder").scrollTop($("#messagesHolder")[0].scrollHeight);
      }
    });
    // this.readMessage();
  }

  click() {

    $("#chat").addClass("ots-hidden");
    //alert("11")
    $("#chat").addClass("ots-hiddenn");
    $("#enableTextChat").click(function () {
      // alert("22")
      $("#chat").toggleClass("ots-hiddenn");
    });
  }

  endCall() {
    otCore.endCall();
    this.setState({ active: false });

    if (window.localStorage.getItem("role") === "ROLE_DOCTOR" && this.state.screenRecordFileNameFlag === true) {
     //alert("if loop")
        recorder.stop();
        stream.getVideoTracks()[0].stop();
        let file = this.state.screenRecordFile
     // alert("File :"+file)
        

    }
      else{
       // alert()
        this.endChatButton();
      }
    
   
  }

  toggleLocalAudio() {

    otCore.toggleLocalAudio(!this.state.localAudioEnabled);
    this.setState({ localAudioEnabled: !this.state.localAudioEnabled });
  }

  enableVc() {
    //otCore.enableVc(!this.state.localVideoEnabled);
    //this.setState({ localVideoEnabled: !this.state.localVideoEnabled });
    this.toggleLocalVideo();
  }

  toggleLocalVideo() {
    //alert("kk")
    otCore.toggleLocalVideo(!this.state.localVideoEnabled);
    this.setState({ localVideoEnabled: !this.state.localVideoEnabled });
  }
  uploadattach() {
    // $(".App-chat-attach .messageFileUpload").addClass("messageloader");
  }
  fullscreen() {
    $(".video-chat-main").toggleClass("video-chat-fullScreen");
    $(".video-chat-wrapper").toggleClass("video-chatwrapper-fullScreen");
  }
  textChatClk() {
    //alert();
    //$(".video-chat-main").toggleClass("video-chat-fullScreen");
    //$(".video-chat-wrapper").toggleClass("video-chatwrapper-fullScreen");
  }
  dashboardButton() {
    this.endChatButton();
    //window.location.href = "/doctor/videoSchedule";
  }
  appointmentList() {
    this.endChatButton();
    //window.location.href = "/patient/videocalllist";
  }

  render() {
    const { connected, active } = this.state;
    const {
      localAudioClass,
      localVideoClass,
      localCallClass,
      controlClass,
      cameraPublisherClass,
      screenPublisherClass,
      cameraSubscriberClass,
      screenSubscriberClass,
    } = containerClasses(this.state);

    return (
      <>
      <div class="videocall_modal popupvideoClass" hidden id="popupvideo">
          <div class="videocall_div">
            <div class="videocall_header">
              Message
              {/* <button
                type="button"
                class="close text-white"
                aria-label="Close"
                onClick={() => this.videoclose()}
              >
                <span aria-hidden="true">&times;</span>
              </button> */}
            </div>
            <div class="videocall_body">Patient not available ! Do you want to close the chat ?</div>
            <div class="videocall_footer">
              <button
                type="button"
                class="bttn_Refresh"
                onClick={() => this.videoOk()}
              >
                Yes
              </button>
              <button
                type="button"
                class="bttn_ok"
                onClick={() => this.videoclose()}
              >
                No
              </button>
              {/* <button
                type="button"
                class="bttn_close"
                onClick={() => this.videoclose()}
              >
                Close
              </button> */}
            </div>
          </div>
        </div>
        <div
          class="videocall_modal popupvideoClassPatient"
          hidden
          id="popupvideo"
        >
          <div class="videocall_div">
            <div class="videocall_header">
              Message
              {/* <button
                type="button"
                class="close text-white"
                aria-label="Close"
                onClick={() => this.videoclose()}
              >
                <span aria-hidden="true">&times;</span>
              </button> */}
            </div>
            <div class="videocall_body">Doctor not available ! Do you want to close the chat ?</div>
            <div class="videocall_footer">
              
              <button
                type="button"
                class="bttn_Refresh"
                onClick={() => this.videoOk()}
              >
                Yes
              </button>
              <button
                type="button"
                class="bttn_ok"
                onClick={() => this.videoclose()}
              >
                No
              </button>
              {/* <button
                type="button"
                class="bttn_close"
                onClick={() => this.videoclose()}
              >
                Close
              </button> */}
            </div>
          </div>
        </div>
      <div className="App video-chat-wrapper">
        <Suspense fallback={<div></div>}>
          <section>{/* <Header /> */}</section>
        </Suspense>
        <div className="loader2"></div>
        <div className="col-md-12 text-left video-call-btn">
          {window.localStorage.getItem("role") === "ROLE_DOCTOR" && (
            <span>
              {/* <button
                class="formButton formButton-sm mr-1"
                onClick={this.dashboardButton}
              >
                Dashboard
              </button> */}

              <button class="formButton formButton-sm" style={{fontSize:"12px"}} onClick={this.endCall}>
                End chat
              </button>

              <div class="recordContainer" style={{ display: "none" }}>
                <span id="start" title="Start Screen Recording" onClick={this.startArchave} > <img src={screenRecord} width="30"></img> </span>
                <span id="stop" className="screenRecordingMain" disabled onClick={this.stopArchive}>
                  <img src={screenRecording} width="30"></img>
                  <span className="screenRecordingText">Recording...</span>
                </span>
                {/* <button id="uploadRecord" style={{ display: "none" }} disabled>Upload</button>
                <video autoplay controls style={{ width: "300px", display: "none" }} /> */}
              </div>

            </span>
          )}
          {window.localStorage.getItem("role") === "ROLE_USER" && (
            <span>
              {/* <button
                class="formButton formButton-sm mr-1"
                onClick={this.appointmentList}
              >
                Appointment(s)
              </button> */}

              <button class="formButton formButton-sm" style={{fontSize:"12px"}} onClick={this.endCall}>
                End chat
              </button>
            </span>
          )}
        </div>
        <div className="App-main video-chat-main video-chat-fullScreen">
          <div className="App-video-container">
            {/* <div className="video-screen-icon" onClick={this.fullscreen}></div> */}
            {!connected && connectingMask()}
            {connected && !active && startCallMask(this.startCall)}

            <div
              id="cameraPublisherContainer"
              className={cameraPublisherClass}
            />
            <div
              id="screenPublisherContainer"
              className={screenPublisherClass}
            />

            <div
              id="cameraSubscriberContainer"
              className={cameraSubscriberClass}
              style={{ display: window.localStorage.getItem("drcallType") === "audio" ? "none" : "block" }}
            />

            <div
              id="screenSubscriberContainer"
              className={screenSubscriberClass}

            />
          </div>
          <div id="controls" className={controlClass}>
            <div
              className={localAudioClass}
              onClick={this.toggleLocalAudio}
              title="Enable / disable Audio"
            />
            <div
              className={localVideoClass}
              onClick={this.toggleLocalVideo}
              id="vcEnable"
              title="Enable / disable video"
            />

            <div
              className={localCallClass}
              onClick={this.endCall}
              title="End call"
            /></div>
          <div className="App-control-container-dup">
            {window.localStorage.getItem("drcallType") !== "audio" &&
              <div
                className="ots-dup-circle"
              />
            }
            <div
              className="ots-dup-circle"
            />
            <div
              className="ots-dup-circle"
            />
            {/* <div
              className="ots-dup-circle ots-dup-circle-active"
              id="ots-dup-chat"
              
            /> */}
          </div>


          <div
            id="chat"
            className="App-chat-container video-chat-container ots-hiddenn"
            title="Text chat"
            onClick={this.textChatClk}  >
                <div
                  class="video-chat-left text-center"
                  style={{ display: "none" }} >
                    <small class="color-maintext">
                      You are speaking to:{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        {window.localStorage.getItem("role") === "ROLE_USER" ?
                          <strong>{window.localStorage.getItem("vc_showName1")}</strong>
                          :
                          <strong>Dr. {window.localStorage.getItem("vc_showName1")}</strong>
                        }
                      </span>
                    </small>
                    <p className="vChatSpecial">
                      <small>
                        {window.localStorage.getItem("vc_showName2")},{" "}
                        {window.localStorage.getItem("vc_showName3")}
                      </small>
                    </p>
                </div>
                <div class="App-chat-attach">
                  <div
                    class="messageFileUpload"
                    style={{ display: "none" }}
                    onClick={this.uploadattach}
                  >
                    <input
                      id="file1"
                      name="file1"
                      class="uploadattach"
                      type="file"
                      onChange={this.fileChange}
                    />
                  </div>
                
                </div>
          </div>
        </div>
        <div class="loader"></div>
        <Suspense fallback={<div></div>}>
          <section>{/* <Footer /> */}</section>
        </Suspense>
      </div>
      </>
    );
  }
}

export default App;

import React, { Component, Suspense } from "react";
import * as ReactDOM from "react-dom";
import bolgtemp from "../image/icons/blogging.png"
import AccCore from "opentok-accelerator-core";
import "opentok-solutions-css";
import { Link } from "react-router-dom";
import "../doctor/App.css";
import $ from "jquery";
import moment from "moment";
import classNames from "classnames";
import { apiCalling } from "../apiService";
import avatar from "../image/media/avatar.png";
import microphoneImg from "../image/icons/microphone.png";
import recordGif from "../image/icons/audio.gif";
import pauseImg from "../image/icons/pause.png";
import pdfImg from "../image/icons/pdf.png";
import deleteImg from "../image/theme/closered.png";
import avatarImg from "../image/media/profile.png";
import bokenImg from "../image/icons/bokenImg.png";
import AWS from "aws-sdk";
import MicRecorder from 'mic-recorder-to-mp3';
import { S3ImagePath } from "../config";
const imgPath = S3ImagePath();
 
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
let otCore;
var s3;
const otCoreOptions = {
  credentials: {
    apiKey: window.localStorage.getItem("API_KEY"),
    sessionId: window.localStorage.getItem("sessionId"),
    token: window.localStorage.getItem("token"),
  },
  controlsContainer: "#controls",
  packages: ["textChat"],
  communication: {
    callProperties: null, // Using default
  },
  textChat: {
    name:[window.localStorage.getItem("userName")], // eslint-disable-line no-bitwise
    waitingMessage: "",
    container: "#chat",
    alwaysOpen: true,
  },
};

/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
const containerClasses = (state) => {
  const { active } = state;
  return {
    controlClass: classNames("App-control-container", { hidden: !active }),

    localCallClass: classNames("ots-video-control circle end-call", {
      hidden: !active,
    }),
  };
};

export default class Chatmessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      connected: false,
      active: false,
      publishers: null,
      subscribers: null,
      meta: null,
      localAudioEnabled: false,
      localVideoEnabled: false,
      file: "",
      s3bucketAccessKey: "",
      AWSAccessKeyId: "",
      bucket: "",
      fullFilePath: "",
      region: "us-east-1",
      question: "",
      questionAns: "",
      questionId: "",
      questionFlag: "0",
      oldMessage: [],
      isExpired: false,
      headerMessage: "",
      doctorId: "",
      memberId: "",
      chatDoctorPanelId: "",
      messaging: "",
      payAmount: "",
      docImgLink:
        "https://purplehealthfiles.s3.amazonaws.com/test/3c8b7d84-d3a1-4773-9ba4-8a2e3174fb3bgoogle-docs.png",
      errorOccurred: false,
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      voiceRecordFileName:"",
      voiceRecordFile:"",
      memberIdtext:""
    };
    this.startCall = this.startCall.bind(this);
    this.endCall = this.endCall.bind(this);
    this.fileChange = this.fileChange.bind(this);
    this.sendAsMail = this.sendAsMail.bind(this);
    this.endChat = this.endChat.bind(this);
    $(".loader").show();
  }

  fileChange(e) {
    var imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png|pdf)$/)) {
      alert("Only accept PDF,JPG,JPEG,PNG formats")
      return false;
    }
    else{
    $(".loader").show();
    this.setState({ file: e.target.files[0] });
    this.fileUpload(e.target.files[0]);
    //console.log("1111111", e.target.files[0]);
    $("#attachImg").text(e.target.files[0].name);
    $("#aattachImg").attr("href", URL.createObjectURL(e.target.files[0]));
    $(".uploadattach").hide();
    $(".uploadattachBtn").show();
    }
  }
  SendvoiceRecord(){
    $(".recording-delete").hide();
    let file=this.state.voiceRecordFile;
    let fileName=this.state.voiceRecordFileName

    AWS.config.update({
      accessKeyId: this.state.s3bucketAccessKey,
      secretAccessKey: this.state.AWSAccessKeyId,
      region: this.state.region,
    });
    var bucketParams = { 
      Bucket: this.state.bucket,
    };
    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = this.state.bucketFolder+"/practicemanagment";
    //let fileName = this.imagepath1() + file.name;
    let fullFilePath = S3BucketMainFolder + "/" + fileName;
    //alert(fullFilePath);
    this.setState({ fileName: fileName });

    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        $(".loader").hide();
        if (err) {
          alert("There was an error uploading your photo: ", err.message);
        } else if (data) {
          //console.log(data);
          this.setState({ fullFilePath: data.Location });
          //alert("successfully uploaded", data.message);
          $("#messageBox").val(data.Location);
          $("#sendMessage").click();
          $(".App-header").hide();
          $("#voiceSend").hide();
          
          return null;
        }
      }
    );
  }
  fileUpload(file) {
    AWS.config.update({
      accessKeyId: this.state.s3bucketAccessKey,
      secretAccessKey: this.state.AWSAccessKeyId,
      region: this.state.region,
    });
    var bucketParams = {
      Bucket: this.state.bucket,
    };
    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = this.state.bucketFolder+"/practicemanagment";
    let fileName = this.imagepath1() + file.name;
    let fullFilePath = S3BucketMainFolder + "/" + fileName;
    //alert(fullFilePath);
    this.setState({ fileName: fileName });

    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        $(".loader").hide();
        if (err) {
          alert("There was an error uploading your photo: ", err.message);
        } else if (data) {
          //console.log(data);
          this.setState({ fullFilePath: data.Location });
          //alert("successfully uploaded", data.message);
          this.onChangeFileUpload();
          return null;
        }
      }
    );
  }
  imagepath1 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
      c
    ) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };
  doSomething() {
    //alert();
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.doSomething(), false);
  }
  componentDidUpdate() {
    //$(".loader").show();
    this.scrollToBottom();
  }
  messageReceived(fromMobileMessage, createdDate, senderName) {
    let recMsg = "";
    let messages = "";   
    let curdateTime = moment(createdDate).format("DD MMM YYYY hh:mm A");
    //alert(curdateTime);
    let recMessage = fromMobileMessage;
    $(".ots-message-item").hide();
    //$(".ots-message-item.ots-message-sent").show();
    $(".ots-message-item.my-chat").show();
    $(".my-chat-span").next().hide();
    //$("#questionDiv1").show();
var errorImg=bokenImg
    let str = "";
    let checkme = ""; 
    if (recMessage.includes("https://")) {
      let checkmemp3 = this.checkURLMP3(recMessage);
              if(checkmemp3===true){
              str="<audio src="+ recMessage+" controls='controls' />"
              }
              else{
      checkme = this.checkURL(recMessage);
      if (checkme === true) {
        str = "<a href=" + recMessage + " ><img src=" + errorImg + " onError="+errorImg+"></a>";
      } else {
        str =
          "<a href=" +
          recMessage +
          " ><img src="+pdfImg+" onError="+errorImg+"></a>";
      }
    }
    } else {
      str = recMessage;
      messages = str.replace("?-0", "?");
      messages = messages.replace("?-1", "?").replace("-1", "?");
      str = messages;
    }
    $("#messagesHolder").append(
      '<div class="ots-message-item  my-chat">' +
        '<div class="ots-user-name-initial">' +
        "<img src="+avatarImg +" width='20'/>"+
        "</div>" +
        '<div class="ots-item-timestamp">' +
        senderName +
        "," +
        curdateTime +
        " <span>" +
        "</span>" +
        "</div>" +
        '<div class="ots-item-text my-chat">' +
        "<span class='my-chat-span text-right'> " +
        str +
        "</span>" +
        "</div>" +
        "</div>"
    );
    $("#messagesHolder").scrollTop($("#messagesHolder")[0].scrollHeight);

    if (window.localStorage.getItem("role") === "ROLE_USER") {
      recMsg = recMessage.split(/\s*-\s*/);
      this.setState({ question: recMsg[1] });
      this.setState({ questionId: recMsg[0] });
      this.setState({ questionFlag: recMsg[2] });
      //alert(recMsg[1]);
      //this.setState({question:recMsg[1]})
      if (recMsg.length === 2) {
        // recMsg = recMessage.split(/\s*-\s*/);
        if (recMsg[1] === "0") {
          $(".ots-message-item:last-child .ots-item-text").append(
            $("#questionDiv0")
          );
          $(".my-chat-span").next("#questionDiv0").show();
        } else {
          $(".ots-message-item:last-child .ots-item-text").append(
            $("#questionDiv1")
          );
          $(".my-chat-span").next("#questionDiv1").show();
        }
      }
    }
  }
  getData() {
    const userAPI = {
      method: "POST",
      body: JSON.stringify({
        functionName: "userDetails",
        siteId: "",
        userId: this.state.memberIdtext,
      }),
    };
   
    $(".loader").show();
    apiCalling(userAPI).then((data) => {
      // console.log("dataaa", data)
      if (data.success === "1") {
        $(".loader").hide();
                window.localStorage.setItem("patientFirstName", data.userData[0].firstName);
                window.localStorage.setItem("patientLastName", data.userData[0].lastName);
                
      }

    });
  }
  startTextChatmemberId() {
    const json = {
      method: "POST",
      body: JSON.stringify({
        functionName: "startTextChat",
        chatGroupId: window.localStorage.getItem("chatDoctorPanelId"),
        userId: window.localStorage.getItem("userId"),
        hospitalId: window.localStorage.getItem("appHosId"),
      }),
    };
    // console.log(json.body);
    apiCalling(json).then((data, i) => {
      $(".loader").hide();


      if (data.success === "1" && data.result[0].oldMessages.length > 0) {
        this.setState({memberIdtext:data.result[0].memberId})
      }
    
    });
  }
  componentDidMount() {
    $(".loader").show();
    this.startTextChatmemberId()
    setTimeout(()=>{
      this.getData()
     },1000)
    $(".uploadattachBtn").hide(); 
    $("#promoApplySection").hide();
    $("#chat-upload").hide();
    $(".messageFileUpload").hide();
    $("#startRecord").hide();
    window.addEventListener("hashchange", this.doSomething(), false);
    // setInterval(async () => {
    //   this.readMessage();
    // }, 10000);
    this.readMessage();
    if (window.localStorage.getItem("role") === "ROLE_DOCTOR") {
      let defaultMessages = window.localStorage.getItem("defaultMessages");
      let defaultSplit = defaultMessages.split(/\s*,\s*/);
      let subSplit = "";

      defaultSplit.map((data) => {
        subSplit = data.split(/\s*-\s*/);
        $("#defaultQust").append(
          $("<option />").val(subSplit[2]).text(subSplit[1])
        );
      });
    }
    this.awsCredentials();

    otCore = new AccCore(otCoreOptions);
    

    otCore.connect().then(() => {
      $(".loader").show();
      this.setState({ connected: true });
      this.startCall();
      //this.setState({ localVideoEnabled: false });
    });
    otCore.on("signal", (event) => {
      if (event.data.includes("sender")) {
        // alert("if")
        // let text = JSON.parse(event.data);
        //       let  textReceiver = text.text;
        //       alert(textReceiver)
      } else {
        //console.log("else....")
        let fromMobileMessage = event.data;
        let createdDate = event.from.creationTime;
        let who = event.from.data;
        let senderName = who.split(/\s*,\s*/);
        senderName = senderName[0];
        this.messageReceived(fromMobileMessage, createdDate, senderName);
        //***************************************** */
      }
    });

    otCore.on("messageSent", (event) => {
      $(".ots-item-text").find(":contains('purplehealthfiles')").hide();
      let curdateTime = moment().format("DD MMM YYYY hh:mm A");
      var d = new Date();
      let messages = "";
      var milliseconds = d.getTime();
      //alert(milliseconds);
      $(".ots-message-item.ots-message-sent").hide();
      $(".my-chat-span").next().hide();
      let str = "";
      let checkme = "";
      if (event.message.includes("https://")) {
        let checkmemp3 = this.checkURLMP3(event.message);
              if(checkmemp3===true){
              str="<audio src="+ event.message+" controls='controls' />"
              }
              else{
        checkme = this.checkURL(event.message);
        if (checkme === true) {
          str =
            "<a href=" +
            event.message +
            " ><img src=" +
            event.message +
            "></a>";
        } else {
          str =
            "<a href=" +
            event.message +
            " ><img src="+pdfImg+"></a>";
        }
      }
      } else {
        str = event.message;
        messages = str
          .replace("?-0", "?")
          .replace("?-1", "?")
          .replace("-1", "?");
        str = messages;
      }
      $("#messagesHolder").append(
        '<div class="ots-message-item ots-message-sent my-chat">' +
          '<div class="ots-user-name-initial"> ' +
          "<img src="+avatarImg +" width='20'/>"+
          "</div>" +
          '<div class="ots-item-timestamp"> ' +
          window.localStorage.getItem("userName") +
          ", <span> " +
          curdateTime +
          "</span></div>" +
          '<div class="ots-item-text my-chat">' +
          "<span class='my-chat-span text-right'> " +
          str +
          "</span>" +
          "</div>" +
          "</div>"
      );
      $(".ots-message-item.ots-message-sent.my-chat").show();
      $("#messagesHolder").scrollTop($("#messagesHolder")[0].scrollHeight);
      // if (event.message.includes("https://")) {
      //   let checkme = this.checkURL(event.message);
      //   if (checkme === true) {
      //     $(".ots-message-sent:last-child .ots-item-text").append(
      //       "<a href=" + event.message + " ><img src=" + event.message + "></a>"
      //     );
      //   } else {
      //     $(".ots-message-sent:last-child .ots-item-text").append(
      //       "<a href=" +
      //         event.message +
      //         " ><img src='https://purplehealthfiles.s3.amazonaws.com/test/3c8b7d84-d3a1-4773-9ba4-8a2e3174fb3bgoogle-docs.png'/></a>"
      //     );
      //   }
      // }
      //alert("save data start.")
      const saveData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "chat/saveChat",
          siteId: window.localStorage.getItem("siteId"),
          hospitalId: window.localStorage.getItem("appHosId"),
          chatGroupId: window.localStorage.getItem("chatDoctorPanelId"),
          userId: window.localStorage.getItem("userId"),
          message: event.message,
          currentTimeMillsec: milliseconds,
          bundleId: "",
        }),
      };
      //console.log(saveData.body);
      apiCalling(saveData).then((data) => {
        //alert(data.success);
      });
    });

    $("#myClickEvent").click(function (e) {
      alert("myClickEvent");
    });

    otCore.on("messageReceived", (event) => {
     
     // console.log(event);
      let recMsg = "";
      let messages = "";
      //alert("event.data..." + event.data);
      let dataSet = JSON.parse(event.data);
      let sender = JSON.stringify(dataSet.sender);
      //alert("dataSet..." + sender);
      let dataSet2 = JSON.parse(sender);
      let senderName = dataSet2.alias;
      let curdateTime = moment(dataSet2.sentOn).format("DD MMM YYYY hh:mm A");
      //alert(curdateTime);
      let recMessage = dataSet.text;
      this.messageReceived(recMessage, dataSet2.sentOn, senderName);
    });

    // setInterval(async () => {
    $(".cancelPrescription").click(function () {
      $("#prescription").val("");
      $("#callcompltd_text").hide();
    });
    $(".voiceRecord_close").click(function () {
      
      $("#callcompltd_text_r").hide();
    });
    
    $("#savePrescription").click(function () {
      if ($("#prescription").val() !== "") {
        $(".loader").show();
        const apiJson = {
          method: "POST",
          body: JSON.stringify({
            functionName: "prescribedMedicineContent",
            siteId: window.localStorage.getItem("siteId"),
            doctorId: window.localStorage.getItem("userId"),
            userId: $("#pre_memberId").val(),
            hospitalId: $("#pre_hospitalId").val(),
            prescription: $("#prescription").val(),
          }),
        };
       // console.log(apiJson.body);
        apiCalling(apiJson).then((data) => {
          $(".loader").hide();
          if (data.success === "1") {
            alert("Send Successfully");
            $("#prescription").val("");
            $("#callcompltd_text").hide();
          }
        });
      } else {
        alert("Please enter prescription field.");
        $("#prescription").focus();
      }
    });

    navigator.getUserMedia({ audio: true },
      () => {
       // console.log('Permission Granted');
        this.setState({ isBlocked: false });
      },
      () => {
       // console.log('Permission Denied');
        this.setState({ isBlocked: true })
      },
    );
  }
  start = () => {
    $(".App-header").show();  
    $(".recording-delete").show();
    $("#recStop").show();
    
    if (this.state.isBlocked) {
     // console.log('Permission Denied');
    } else {
      Mp3Recorder
        .start()
        .then(() => {
          $(".recording-icon").show();
          $("#recordedAudio").hide();
          this.setState({ isRecording: true });
          $("#voiceSend").hide();
        }).catch((e) => console.error(e));
    }
  };

  stop = () => {
    $("#recStop").hide();
    $(".recording-icon").hide();
    $(".recording-delete").show();
    $("#recordedAudio").show();
    Mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob)
        this.setState({ blobURL, isRecording: false });
        var d = new Date();
        var newDate = d.getTime();
        let fileName=newDate+'.mp3';
        const file = new File(buffer, fileName, {
          type: blob.type,
          lastModified: Date.now()
        });        
        this.setState({voiceRecordFile:file});
        this.setState({voiceRecordFileName:fileName});        
        $("#voiceSend").show();
      }).catch((e) => console.log(e));

  };
  deleteRecord(){
    this.setState({blobURL:""});
    $(".App-header").hide(); 

  }
  endChat() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "completeChatStatus",
        requestVideoAppointmentId: window.localStorage.getItem(
          "doctorVideoScheduleId"
        ),
        appointmentStatus: "Call Completed",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        alert("Call ended");
      }
      $(".loader").hide();
    });
  }

  startCall() {
    let oldData = [];
    $(".loader").show();
    let messages = "";
    let str = "";
    const json = {
      method: "POST",
      body: JSON.stringify({
        functionName: "startTextChat",
        chatGroupId: window.localStorage.getItem("chatDoctorPanelId"),
        userId: window.localStorage.getItem("userId"),
        hospitalId: window.localStorage.getItem("appHosId"),
      }),
    };
   // console.log(json.body);
    apiCalling(json).then((data) => {
      this.readMessage();
      $(".loader").hide();
      if (data.success === "1") {
        // alert("...." + data.result[0].oldMessages);
        $("#startRecord").show();
        $(".messageFileUpload").show();
        //alert(data.result[0].isExpired);
        // alert(data.result[0].messagingCost);
        this.setState({ oldMessage: data.result[0].oldMessages });
        oldData.push(data.result[0].oldMessages);
        this.setState({ isExpired: data.result[0].isExpired });
        window.localStorage.setItem("appDocId", data.result[0].doctorId);
        window.localStorage.setItem("appHosId", data.result[0].hospitalId);
        window.localStorage.setItem("chatDoctorPanelId", data.result[0].chatGroupId);
        window.localStorage.setItem("doctorPanelId", data.result[0].chatDoctorPanelId);
        window.localStorage.setItem("messaging", data.result[0].messagingCost);
        window.localStorage.setItem("memberId_text", data.result[0].memberId);
        this.setState({ payAmount: data.result[0].messagingCost });
        if (window.localStorage.getItem("role") === "ROLE_USER") {
          if (data.result[0].isExpired === true) {
            $(".ots-messages-holder").addClass("ots-messages-holder-isExpired");
            $("#messageBox").hide();
            $("#startRecord").hide();
            $("#sendMessage").hide();
            $("#controls").hide();
            $("#boottomDiv").hide();
            $("#paymentReqBlock").show();
            $(".messageFileUpload").hide();
            //alert(data.result[0].messagingCost);
            if (data.result[0].messagingCost === 0) {
              $("#withoutPayment").show();
              this.setState({
                headerMessage: "To continue chat, Please click continue",
              });
            } else {
              $("#withPayment").show();
              this.setState({
                headerMessage: "To continue chat, Please make payment",
              });
            }
          }
        }
        let flag = 0;
        data.result[0].oldMessages.map((vid, vin) => {
          let showBlock = "";
          flag = flag + 1;
          if (
            vid.role === "ROLE_HOSPITALADMIN" &&
            window.localStorage.getItem("role") === "ROLE_DOCTOR"
          ) {
          } else {
            if (vid.message.includes("https://")) {
              let checkmemp3 = this.checkURLMP3(vid.message);
              if(checkmemp3===true){
              str="<audio src="+ vid.message+" controls='controls' />"
              }
              else{
             let checkme = this.checkURL(vid.message);
              if (checkme === true) {
                str =
                  "<a href=" +
                  vid.message +
                  " ><img src=" +
                  vid.message +
                  "></a>";
              } else {
                str =
                  "<a href=" +
                  vid.message +
                  " ><img src="+pdfImg+"></a>";
              }
            }
            } else {
              str = vid.message;
              if (str.includes("?-0")) {
                messages = str
                  .replace("?-0", "?")
                  .replace("?-1", "?")
                  .replace("-1", "?");
                str = messages;
                showBlock = $("#questionDiv0").html();
              }
              if (str.includes("?-1")) {
                messages = str
                  .replace("?-0", "?")
                  .replace("?-1", "?")
                  .replace("-1", "?");
                str = messages;
                showBlock = $("#questionDiv1").html();
              }
            }
            str = str
              .replace("?-0", "?")
              .replace("?-1", "?")
              .replace("-1", "?");
            if (
              parseInt(vid.senderId) ===
              parseInt(window.localStorage.getItem("userId"))
            ) {
              $("#messagesHolder").append(
                '<div class="ots-message-item ots-message-sent my-chat">' +
                  '<div class="ots-user-name-initial"> ' +
                  "<img src="+avatarImg +" width='20'/>"+
                  "</div>" +
                  '<div class="ots-item-timestamp"> ' +
                  vid.sender +
                  ', <span <="" span="">' +
                  vid.createdDate +
                  "</span></div>" +
                  '<div class="ots-item-text">' +
                  "<span id='1234' class='text-right'> " +
                  str +
                  "</span>" +
                  "<div id=abcd" +
                  flag +
                  "></div>" +
                  // showBlock +
                  "</div>" +
                  "</div>" +
                  "</div>"
              );
              //$("#abcd" + flag).append($("#questionDiv1"));
            } else {
              $("#messagesHolder").append(
                '<div class="ots-message-item my-chat">' +
                  '<div class="ots-user-name-initial"> ' +
                  "<img src="+avatarImg +" width='20'/>"+
                  "</div>" +
                  '<div class="ots-item-timestamp"> ' +
                  vid.sender +
                  ', <span <="" span="">' +
                  vid.createdDate +
                  "</span></div>" +
                  '<div class="ots-item-text">' +
                  "<span id='5678' class='text-left'> " +
                  str +
                  "</span>" +
                  showBlock +
                  "<div id=abcd" +
                  flag +
                  "></div>" +
                  "</div>" +
                  "</div>"
              );
              // if (showBlock !== "") {
              //   $("#abcd" + flag).append($("#questionDiv0"));
              // }
            }

            //     if (event.message.includes("https://")) {
            //   let checkme = this.checkURL(event.message);
            //   if (checkme === true) {
            //     $(".ots-message-sent:last-child .ots-item-text").append(
            //       "<a href=" + event.message + " ><img src=" + event.message + "></a>"
            //     );
            //   } else {
            //     $(".ots-message-sent:last-child .ots-item-text").append(
            //       "<a href=" +
            //         event.message +
            //         " ><img src='https://purplehealthfiles.s3.amazonaws.com/test/3c8b7d84-d3a1-4773-9ba4-8a2e3174fb3bgoogle-docs.png'/></a>"
            //     );
            //   }
            // }
            // }, 5000);
            $("#messagesHolder").scrollTop(
              $("#messagesHolder")[0].scrollHeight
            );
          }
        });

        $(".loader").hide();
      }
    });
    this.readMessage();
    //this.showDivBlock(oldData);
  }

  endCall() {}

  uploadattach() {
    $(".App-chat-attach .messageFileUpload").addClass("messageloader");
  }
  upload() {
    $("#chat-upload").show();
  }
  havePromoCode() {
    $("#promoApplySection").show();
    $("#havePromoCode").hide();
  }
  sendAsMail() {
   
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "chat/sendChatAsMail",
        siteId: window.localStorage.getItem("siteId"),
        chatGroupId: window.localStorage.getItem("chatDoctorPanelId"),
        userId: window.localStorage.getItem("userId"),
        bundleId: "",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
      }
      alert("Your chat messages have been send to your mail successfully.");
    });
    
  }
  endChatButton() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "endChat",
        siteId: window.localStorage.getItem("siteId"),
        chatGroupId: window.localStorage.getItem("chatDoctorPanelId"),
        userId: window.localStorage.getItem("userId"), //"19906",
        bundleId: "",
      }),
    };
    //alert(apiJson.body);
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        window.location.href = "../chat/chatmessagegroup";
      }
    });
  }
  oldchat() {
    // $("#oldchat").show();
    //alert();
  }
  awsCredentials() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        siteId: window.localStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          s3bucketAccessKey: data.configPathDetails[0].s3bucketAccessKey,
        });
        this.setState({
          AWSAccessKeyId: data.configPathDetails[0].s3bucketSecretKey,
        });

        this.setState({
          bucket: data.configPathDetails[0].s3BucketReliance,
        });
        this.setState({
          bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
      });
      }
    });
  }
  onChangeFileUpload() {
    let fullPAth = this.state.fullFilePath;
    //$("#messageBox").val("sss");
    //$("#sendMessage").click();
    $("#messageBox").val(fullPAth);
    $("#sendMessage").click();
    $(".uploadattachBtn").hide();
    $(".uploadattach").show();
  }
  checkURL(url) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }
  checkURLMP3(url) {
    return url.match(/\.(mp3)$/) != null;
  }
  onChangeDefaultQuestion() {
    let defaultQustVal = $("#defaultQust").val();
    let defaultQustText =
      $("#defaultQust option:selected").text() + "-" + defaultQustVal;
    $("#messageBox").val(defaultQustText);
    $("#sendMessage").click();
  }
  checkMyStatus(status) {
    $("#messageBox").val(status);
    $("#sendMessage").click();
  }
  textChatPayNow() {
    window.localStorage.setItem(
      "chatDoctorPanelId",
      window.localStorage.getItem("doctorPanelId")
    );
    window.localStorage.setItem("messaging_cost", this.state.payAmount);
    window.location.href = "/patient/textchatpayment";
  }
  chatmessageClickValidate() {
    let doctorId = window.localStorage.getItem("appDocId");
    let hospitalId = window.localStorage.getItem("appHosId");
    let chatDoctorPanelId = window.localStorage.getItem("doctorPanelId");

    $(".loader").show();
    const jsonData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "messageChatFree",
        chatDoctorPanelId: chatDoctorPanelId,
        memberId: window.localStorage.getItem("userId"),
        doctorId: doctorId,
        hospitalId: hospitalId,
      }),
    };
   // console.log(jsonData.body);
    apiCalling(jsonData).then((data) => {
      if (data.success === "1") {
        const json = {
          method: "POST",
          body: JSON.stringify({
            functionName: "startTextChat",
            chatGroupId: data.chatGroupId,
            userId: window.localStorage.getItem("userId"),
            hospitalId: hospitalId,
          }),
        };
        let chatGroupId = data.chatGroupId;
       // console.log(json.body);
        apiCalling(json).then((data) => {
          $(".loader").hide();
          if (data.success === "1") {
            //console.log("Data: ", data);
            window.localStorage.setItem("chatStore", data.result[0]);
            window.localStorage.setItem("API_KEY", data.result[0].API_KEY);
            window.localStorage.setItem("sessionId", data.result[0].sessionId);
            window.localStorage.setItem("token", data.result[0].token);
            window.localStorage.setItem("memberId_text", data.result[0].memberId);
            window.localStorage.setItem("appDocId", doctorId);
            window.localStorage.setItem("appHosId", hospitalId);
            //window.localStorage.setItem("chatDoctorPanelId", chatDoctorPanelId);
            window.localStorage.setItem("chatDoctorPanelId", chatGroupId);
            window.location.href = "/chat/chatmessage";
          }
        });
      }
    });
  }
  onRefresh() {
    this.startCall();
  }

  readMessage() {
    const jsontest = {
      method: "POST",
      body: JSON.stringify({
        functionName: "chat/markChatIsRead",
        chatGroupId: window.localStorage.getItem("chatDoctorPanelId"),
        userId: "",
        bundleId: "",
        siteId: window.localStorage.getItem("siteId"),
      }),
    };
    apiCalling(jsontest).then((data) => {});
  }
  callcompltd() {
    $("#pre_memberId").val(window.localStorage.getItem("memberId_text"));
    $("#pre_hospitalId").val(window.localStorage.getItem("appHosId"));
    $("#pre_doctorId").val(window.localStorage.getItem("userId"));
    $("#prescription").val("");
    $("#callcompltd_text").show();
  }
  cancelPrescription() {
   
    $("#prescription").val("");
    $("#callcompltd_text").hide();
    
  }
  voiceRecord(){
   
    this.start();
  }
  srcTypeImage(ev){
    ev.target.src =bolgtemp;
  }
  render() {
    // const { connected, active } = this.state;
    //$(".loader").show();
    const { controlClass } = containerClasses(this.state);
    return (
      <div class="purpleWrap">
        <Suspense
          fallback={
            <div>
              <h1>Loading profile...</h1>
            </div>
          }
        >
          <section>
            <Header />

            <div class="container">
              <div style={{ display: "none" }}>
                <div id="questionDiv0" class="unni123">
                  <p class="text-center">
                    <input
                      type="button"
                      class="chatQstnBtn questionDiv0Yes"
                      value="Yes"
                      onClick={() => this.checkMyStatus("Yes")}
                    />
                    <input
                      type="button"
                      class="chatQstnBtn"
                      value="No"
                      onClick={() => this.checkMyStatus("No")}
                    />
                  </p>
                </div>
                <div id="questionDiv1">
                  <p class="text-center">
                    <input
                      type="button"
                      class="chatQstnBtn questionDiv1"
                      value="Yes"
                      onClick={() => this.checkMyStatus("Yes")}
                    />
                    <input
                      type="button"
                      class="chatQstnBtn"
                      value="No"
                      onClick={() => this.checkMyStatus("No")}
                    />

                    <input
                      type="button"
                      class="chatQstnBtn"
                      value="May Be"
                      onClick={() => this.checkMyStatus("May Be")}
                    />
                  </p>
                </div>
              </div>
              <div class="main-wrapper-smwidth1">
                <div class="chat-outer">
                  <div
                    ref={(el) => {
                      this.messagesEnd = el;
                    }}
                  ></div>
                  <div class="chat-header">
                    <span class="form-head">Chats</span>
                    <span class="chat-span">
                      <button
                        type="button"
                        class="btn-default"
                        onClick={() => this.onRefresh()}
                      >
                        Refresh
                      </button>
                    </span>

                    <span class="chat-span">
                      <button
                        type="button"
                        class="btn-default"
                        headerMessage
                        onClick={() => this.endChatButton()}
                      >
                        End Chat
                      </button>
                    </span>
                    <span class="chat-span">
                      <Link to="/chat/archivedMessageChats">
                        <button type="button" class="btn-default">
                          Old Chat(s)
                        </button>
                      </Link>
                    </span>
                    <span class="chat-span">
                      <a href="/chat/chatmessagegroup">
                        <button type="button" class="btn-default">
                          Inbox
                        </button>
                      </a>
                    </span>

                    <span class="chat-span">
                      <button
                        type="button"
                        onClick={() => this.sendAsMail()}
                        class="btn-default"
                      >
                        Send As Mail
                      </button>
                    </span>
                    {window.localStorage.getItem("role") === "ROLE_USER" ? (
                      <span class="chat-span">
                        <Link to="/home/doctor-list-main/2">
                          <button type="button" class="btn-default">
                            New Chat
                          </button>
                        </Link>
                      </span>
                    ) : (
                      <span class="chat-span">
                        <Link to="/doctor/dashboard">
                          <button type="button" class="btn-default">
                            Dashboard
                          </button>
                        </Link>
                      </span>
                    )}
                  </div>
                  <div class="chat-body">
                    <div class="messages" id="messageOld">
                      {/* <div
                    className="col-md-12 text-center cursor-pointer"
                    style={{ fontWeight: "600" }}
                    onClick={() => this.oldchat()}
                  >
                    <i
                      class="fa fa-chevron-down mr-2"
                      style={{ fontSize: "10px" }}
                    ></i>{" "}
                    Load earlier messages
                  </div> */}

                      <div
                        id="oldchat"
                        className="text-chat-container"
                        style={{ display: "none" }}
                      >
                        {this.state.oldMessage.map((vid, vin) => {
                          let fileImgLink = false;
                          let fileLink = false;
                          let text = "";
                          if (vid.message.includes("https://")) {

                            let checkme = this.checkURL(vid.message);
                            if (checkme === true) {
                              fileImgLink = true;
                            } else {
                              fileLink = true;
                            }
                          } else {
                            fileImgLink = false;
                            fileLink = false;
                            text = vid.message;
                          }
                          return (
                            <ul>
                              {parseInt(vid.senderId) !==
                              parseInt(window.localStorage.getItem("userId")) ? (
                                <li class="message left">
                                  <div class="avatar">
                                    <img
                                      src={avatar}
                                      class="avatarimg"
                                      alt=""
                                    ></img>
                                  </div>
                                  <div class="text_wrapper">
                                    <div class="text-header">
                                      <h6 className="textchat-name">
                                        {vid.doctorName}
                                      </h6>
                                      <time class="message-time">
                                        {" "}
                                        {vid.createdDate}
                                      </time>
                                      {/* <time class="message-time">15 May 2020 06:05 PM</time> */}
                                    </div>
                                    <p class="text"></p>
                                    {fileImgLink === true && (
                                      <p class="text">
                                        <a href={vid.message}>
                                          <img src={vid.message} alt="" />
                                        </a>
                                      </p>
                                    )}
                                    {fileLink === true && (
                                      <p class="text">
                                        <a href={vid.message}>
                                          <img
                                            src={this.state.docImgLink}
                                            alt=""
                                          />
                                        </a>
                                      </p>
                                    )}
                                    {text !== "" && (
                                      <p class="text">{vid.message}</p>
                                    )}
                                  </div>
                                </li>
                              ) : (
                                <li class="message right">
                                  <div class="avatar">
                                    <img
                                      src={avatar}
                                      class="avatarimg"
                                      alt=""
                                    />
                                  </div>
                                  <div class="text_wrapper">
                                    <div class="text-header">
                                      <h6 className="textchat-name">
                                        {" "}
                                        {vid.memberName}
                                      </h6>
                                      <time class="message-time">
                                        {" "}
                                        {vid.createdDate}
                                      </time>
                                    </div>
                                    {fileImgLink === true && (
                                      <p class="text">
                                        <a href={vid.message}>
                                          <img src={vid.message} alt="" />
                                        </a>
                                      </p>
                                    )}
                                    {fileLink === true && (
                                      <p class="text">
                                        <a href={vid.message}>
                                          <img
                                            src={this.state.docImgLink}
                                            alt=""
                                          />
                                        </a>
                                      </p>
                                    )}
                                    {text !== "" && (
                                      <p class="text">{vid.message}</p>
                                    )}
                                  </div>
                                </li>
                              )}
                            </ul>
                          );
                        })}
                      </div>
                    </div>

                    <div className="App">
                      <div className="App-main text-chat-main">
                        <div id="controls" className={controlClass}></div>

                        <div
                          id="chat"
                          className="App-chat-container text-chat-container"
                        >
                          <div class="App-chat-attach">
                            {/* <div
                          class="messageFileUpload"
                          onClick={this.uploadattach}
                        ></div> */}
                            <div
                              class="messageFileUpload"
                              // onClick={this.uploadattach}
                              // style={{ display: "none" }}
                            >
                              <input
                                id="file1"
                                name="file1"
                                class="uploadattach"
                                type="file"
                                onChange={this.fileChange}
                              />
                              <div
                                class="uploadattachBtn"
                                onClick={(e) => {
                                  this.onChangeFileUpload();
                                }}
                              >
                                Send
                              </div>
                            </div>
                          </div>
                    
                          <div className="App-header" style={{display:"none"}}>
                            <button className="recording-delete"  disabled={this.state.isRecording} style={{display:'none'}}>
                              <img src={deleteImg} onClick={() => this.deleteRecord()} className="recdeleteImg-icon-img" title="Delete"/>
                            </button>
                           
                           <button className="recording-icon"  disabled={this.state.isRecording}>
                             <img src={recordGif} className="recording-icon-img" title="record"/>
                           </button>
                            <audio src={this.state.blobURL} controls="controls" id="recordedAudio"/>
                            <button className="record-stop" id="recStop" onClick={this.stop} disabled={!this.state.isRecording}>
                              <img src={pauseImg} className="record-stop-img" title="stop"/>
                            </button>
                            
                            <button onClick={() => this.SendvoiceRecord()} className="voice-sndIcon" id="voiceSend" style={{display:'none'}}>Send</button>
                          </div>  
                          <div className="voice-recordBtn">
                              <img src={microphoneImg}
                              className="voice-recordImg"
                                id="startRecord"                         
                                onClick={this.start} alt="" title="Start Recording"></img>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    {window.localStorage.getItem("role") === "ROLE_DOCTOR" && (
                      <div className="row col-md-12 m-0">
                        <div className="col-md-4 col-8 p-0 mb-2">
                          <select
                            className="input-design chat-dr-question"
                            id="defaultQust"
                            onChange={(e) => {
                              this.onChangeDefaultQuestion();
                            }}
                          >
                            <option value="">
                              -- Select default question - -
                            </option>
                          </select>
                        </div>

                        <div className="col-md-4 col-4">
                          {/* <button
                            type="button"
                            className="formButtonBg formButton-size chat-bgbtn pt-1"
                            style={{paddingTop:"5px"}}
                            onClick={() => this.callcompltd()}
                          > */}
 <Link to={`/prescriptionAdd-${window.localStorage.getItem("appHosId")}-${window.localStorage.getItem("memberId_text")}-0-${window.localStorage.getItem("patientFirstName")}%20${window.localStorage.getItem("patientLastName")}`} className="formButtonBg formButton-size chat-bgbtn pt-1">Prescription</Link>
                          {/* </button> */}
                        </div>
                      </div>
                    )}
                    <div className="col-md-12 pr-4" id="boottomDiv">
                      {/* <p class="textarea-count">10000 characters left</p> */}
                      {/* <input
                        id="file1"
                        name="file1"
                        tabindex="33"
                        class="uploadattach"
                        type="file"
                        onChange={this.fileChange}
                      />
                      <input
                        type="button"
                        value="Upload"
                        onClick={(e) => {
                          this.onChangeFileUpload();
                        }}
                      /> */}
                    </div>

                    <div
                      class="doctor-pay-box"
                      id="paymentReqBlock"
                      style={{ display: "none" }}
                    >
                      <div>
                        <p className="text-center">
                          {this.state.headerMessage}
                        </p>
                      </div>
                      {/* <div class="row">
                      <label class="col-5 label-text label-text-cln">
                        Doctor Name
                      </label>
                      <label class="col-5" style={{ fontWeight: "600" }}>
                        {this.state.doctorName}
                      </label>
                    </div> */}
                      <div class="row">
                        <label class="col-5 label-text label-text-cln">
                          Amount
                        </label>
                        <label class="col-7" style={{ fontWeight: "600" }}>
                          <span style={{ fontFamily: "none" }}>₹</span>
                          {this.state.payAmount}
                        </label>
                      </div>
                      <div className="col-lg-12 text-center">
                        <input
                          type="button"
                          class="formButton"
                          value="Pay Now"
                          id="withPayment"
                          style={{ display: "none" }}
                          onClick={() => this.textChatPayNow()}
                        />
                        <input
                          type="button"
                          class="formButton"
                          id="withoutPayment"
                          value="Continue Chat"
                          style={{ display: "none" }}
                          onClick={() => this.chatmessageClickValidate()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*
-----------------------------------------------------------
        */}
            {/* <div className="App">
          <div className="App-main text-chat-main">
            <div id="controls" className={controlClass}></div>
            <div id="oldchat" className="text-chat-container"></div>
            <div id="chat" className="App-chat-container text-chat-container">
              <div class="App-chat-attach">
                <div class="messageFileUpload" onClick={this.uploadattach}>
                  <input
                    id="file1"
                    name="file1"
                    tabindex="33"
                    class="uploadattach"
                    type="file"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
            <div
              class="popup-overlay"
              id="callcompltd_text"
              style={{ display: "none" }}>
            
              <div className="popup-content">
                <div className="modal-popup">
                  <a
                    className="close-popup popup-hide cancelPrescription"
                    onClick={() => this.cancelPrescription()}
                    id=""
                  >
                    &times;
                  </a>
                  <div className="header border-0"> Prescription Details</div>
                  <div className="content">
                    <div className="col-md-12">
                      <input type="hidden" id="pre_memberId" />
                      <input type="hidden" id="pre_hospitalId" />
                      <input type="hidden" id="pre_doctorId" />
                      <textarea
                        class="input-design"
                        rows="10"
                        id="prescription"
                      ></textarea>
                    </div>
                    <div class="text-center">
                      <input
                        type="button"
                        class="formButtonBg mx-1"
                        value="Save"
                        id="savePrescription"
                      />
                      <input
                        type="button"
                        class="formButtonBg cancelButtonBg mx-1 popup-hide cancelPrescription"
                        value="Cancel"
                        onClick={() => this.cancelPrescription()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

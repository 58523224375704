import React, { Component, Suspense } from "react";

import $ from "jquery";
import tick from "../image/media/tick.png";
import avatar from "../image/media/avatar.png";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class ChatmessageGroup extends Component {
  componentDidMount() {
    $("#promoApplySection").hide();
  }
  havePromoCode() {
    $("#promoApplySection").show();
    $("#havePromoCode").hide();
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper-smwidth1">
            <div class="col-md-12 p-0">
              <ul class="breadcrumb">
                <li>
                  <a href="/doctor/dashboard">Dashboard</a>
                  <i class="fa fa-chevron-right"></i>
                </li>
                <li>
                  <a>Old Chat Questions</a>
                </li>
              </ul>
            </div>
            <div class="chat-outer">
              <div class="chat-header">
                <span class="form-head">Old Chat Questions</span>
              </div>
              <div class="chat-bodygroup">
                <ul class="messages">
                  <li class="message message-brdr">
                    <a href="/doctor/chatmessage" class="text-decoration-none">
                      <div class="avatar">
                        <img src={avatar} class="avatarimg"></img>
                      </div>
                      <div class="textwrappergroup">
                        <div class="text-header">
                          <div className="textchat-namehead">
                            Dr. Paid Ortho Doc
                          </div>
                          <time class="message-time">
                            {" "}
                            <i class="fa fa-clock-o"></i> 15 May 2020 06:05 PM
                          </time>
                        </div>
                        <p class="text">chat with you soon.</p>
                      </div>
                    </a>
                  </li>
                  <div class="divider"></div>
                  <li class="message mb-0">
                    <a href="/doctor/chatmessage" class="text-decoration-none">
                      <div class="avatar">
                        <img src={avatar} class="avatarimg"></img>
                      </div>
                      <div class="textwrappergroup">
                        <div class="text-header">
                          <div className="textchat-namehead">
                            Dr. Paid Ortho Doc
                          </div>
                          <time class="message-time">
                            {" "}
                            <i class="fa fa-clock-o"></i> 15 May 2020 06:05 PM
                          </time>
                        </div>
                        <p class="text">chat with you soon.</p>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

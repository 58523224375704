import React, { Component, Suspense } from "react";
import { apiCalling, validateEmail } from "../apiService";
import $ from "jquery";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
const siteId= window.localStorage.getItem("siteId")
export default class JoinAsPatient extends Component {
  constructor(props){
    super(props);
    this.state = {
      validateform:false,
      name:"",
      email:"",
      password:"",
      confirmpassword:"",
      mobileno:"",
      otp:"",
      generateOTP:""

    }
    this.handleChange=this.handleChange.bind(this)
  }
  resendOtp() {
		this.setState({errorMessage: ""})
		this.sendOtp();
	}
  sendOtp = () => {
		const apiJson = {
			method: "POST",
			body: JSON.stringify({
				functionName: "sendOtp",
				mobileNumber: this.state.mobileno,
				name: this.state.name,
				siteId: siteId
			}),
			};	
			apiCalling(apiJson).then((data) => {
			$(".loader").hide();
			if (data.success === "1") {
        alert(data.successMessage)
			}
			else{
			}
		});
	}
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value 
    })
  };

  validateMobile() {
    var mobileNo=this.state.mobileno
    let validate = /^[0-9]{0,10}$/;
    if (!validate.test(mobileNo)) {
      return false;
    } else {
      // $("#Mobile").val(a)
      return true;
    }
  }

  registerFunction(){
    if(this.state.name===""){
      alert("Please enter your Name")
      $("#name").focus()
      return false
    }
    else if(this.state.email===""){
      alert("Please enter your Email Address")
      $("#email").focus()
      return false
    }
    else if(!validateEmail(this.state.email)){
      alert("Please Enter valid Email Address")
      $("#email").focus()
      return false
    }
    else if(this.state.password===""){
      alert("Please enter your Password")
      $("#password").focus()
      return false
    }
    else if(this.state.confirmpassword===""){
      alert("Please enter your Confirm Password")
      $("#confirmpassword").focus()
      return false
    }
    else if(this.state.password!==this.state.confirmpassword){
      alert("Password and Current Password do not match")
      $("#password").focus()
      return false
    }
    else if(this.state.mobileno===""){
      alert("Please enter your Mobile Number")
      $("#mobileno").focus()
      return false
    }
    else if(!this.validateMobile(this.state.mobileno)){
      alert("Please enter valid Mobile Number")
      $("#mobileno").focus()
      return false
    }
    else{
      const otpAPI={
        method: "POST",
        body: JSON.stringify({
        "functionName": "sendOtp",
        "mobileNumber": this.state.mobileno,
        "name": this.state.name,
        "siteId": siteId
        })
        }
        apiCalling(otpAPI).then((data) => {
          if(data.success === "1"){
            alert(data.successMessage)
            this.setState({
              validateform:true
            })
            
          }else{
            alert(data.errorMessage)
          }
        })

    }
    
  }
  validateOTPFunction(){
    if(this.state.otp===""){
      alert("Please enter your OTP")
      $("#name").focus()
      return false
    }else{

      const validateotpAPI={
        method: "POST",
        body: JSON.stringify({
          
            "functionName": "checkOtp",
            "mobileNumber": this.state.mobileno,
            "userOtpValue": this.state.otp
            
          })
        }
        apiCalling(validateotpAPI).then((data) => {
          if(data.success === "1"){
              if(data.isCorrectOtp==="1"){
                const validateotpAPI={
                  method: "POST",
                  body: JSON.stringify({
                    
                    "functionName": "userFeedback",
                    "mobileNumber": this.state.mobileno,
                    "emailId": this.state.email,
                    "confirmPassword" : this.state.confirmpassword,
                    "name": this.state.name,
                    "userQuery": ""                      
                    })
                  }
                  apiCalling(validateotpAPI).then((data2) => {
                    if(data2.success === "1"){

                      const validateotpAPI={
                        method: "POST",
                        body: JSON.stringify({
                          
                          
                            "functionName": "joinUsSendMail",
                            "memberId": data2.memberId,
                            "siteId": siteId
                                                
                          })
                        }
                        apiCalling(validateotpAPI).then((data3) => {
                          if(data3.success === "1"){
                            alert("Joined successfully")
                            window.location.reload()
                          }else{
                            alert("Something went wrong")
                          }

                        })

                    }
                    else{
                      alert("Something went wrong")
                    }

                  })

              }else{
                alert("Invalid OTP")
              }
          }
          else{
            alert("Invalid OTP")
          }
        })

    }
  }
  render() {
    return (
      <div className="main">
       <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
      <main role="main">
      <div className='subPage_container'>
        <div className='container'>
            <div className='content_help'>
                <div className='help_border'>
                <h1 className='main_header'>YOU MAY JOIN AS A PATIENT TO THE NETWORK AND AVAIL HUGE BENEFITS</h1>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-4 px-0'>
                                <div className='join_head invisible'>invisible</div>
                                <ul className="nav flex-column list_number">
                                  <li className="nav-item">
                                    <div className='list_div'>1</div>
                                    <span className="nav-link">Please fill in your details in the e form , with a valid e mail id and mobile number.</span>
                                  </li>
                                  <li className="nav-item">
                                  <div className='list_div'>2</div>
                                  <span className="nav-link">You will receive an OTP</span>
                                  </li> <div className='list_div'>1</div>
                                  <li className="nav-item">
                                  <div className='list_div'>3</div>
                                  <span className="nav-link">When you enter the OTP, a message with your temporary registration number will be sent to your mobile.</span>
                                  </li>
                                  <li className="nav-item">
                                  <div className='list_div'>4</div>
                                  <span className="nav-link">Once you complete the registration process, you may visit any of the member hospitals in the network within three months and get your membership card with permanent registration number to avail huge benefits.</span>
                                  </li>
                                </ul>
                            </div>
                            <div className='col-md-4 px-0'>
                               <div className='join_head'>JOIN NOW</div>
                                <div className='joint_borderwrapper'>
                                   
                                      <input type="text" id="name" placeholder='Name' onChange={this.handleChange} defaultValue={this.state.name}/>
                                      <input type="email" id="email"  defaultValue={this.state.email} placeholder='Email Address' onChange={this.handleChange}/>
                                      <input type="password" id="password" defaultValue={this.state.password} placeholder='Password' onChange={this.handleChange}/>
                                      <input type="password" id="confirmpassword" defaultValue={this.state.confirmpassword} placeholder='Confirm Password' onChange={this.handleChange}/>
                                      <input type="number" id="mobileno" defaultValue={this.state.mobileno} placeholder='Mobile Number' onChange={this.handleChange}/>
                                      {this.state.validateform?(
                                        <>
                                        <label>OTP</label>
                                      <input type="text" id="otp" placeholder='OTP'  onChange={this.handleChange}/>
                                      <span className="resendOtp" onclick="resendJoinOtp()">Resend OTP</span>
                                      
                                        </>
                                      ):""}
                                       {this.state.validateform?(
                                        <button type='button' className='btn_submit' onClick={()=>this.validateOTPFunction()}>Validate OTP</button>
                                      ):(<button type='button' className='btn_submit' onClick={()=>this.registerFunction()}>Register</button>)
 
                                       }
                                    
                                </div>
                                <div className="helpLinesupport join_support">
                                  <div className="supportHelp">Helpline :</div>
                                  <div className="supportNumber">8078478278</div>
                                  <div className="supportHospital">support@imahospitals.com</div>
                                </div>
                            </div>
                            <div className='col-md-4 px-0'>
                            <div className='join_head'>BENEFITS</div>
                            <ol className='benifits_body'>
                              <li>Sharing of Important medical information between network doctors enabling better care.</li>
                              <li>Priority Consultation in the Referred Institution.</li>
                              <li>Waiver on hospital registration fee to referred hospitals.</li>
                              <li>Registration Fee to be paid only in the first institute in the network.</li>
                              <li>10% Discount on all investigations in the network institutions.</li>
                              <li>24 hours Ambulance on call.</li>
                              <li>Pick up & Drop facility to the Network Hospital.</li>
                              <li>Doctors Home Visit.</li>
                              <li>Home Nursing and home blood collection on request.</li>
                              <li>Can attend common health Programmes & Camps.</li>
                              <li>Special discounts and offers by Member Institutions.</li>
                              <li>24 hours HELP LINE : 8078478278 , support@imahospitals.com.</li>
                            </ol>
                            </div>
                        </div>
                    </div>
            </div>
            </div>
        </div>
      </div>
      </main>
      <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
     
    )
  }
}

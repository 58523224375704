import React, { Component, Suspense, useState } from "react";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class map extends Component {

  componentDidMount()
  {

   alert(window.localStorage.getItem("map_adress1"));
   alert(window.localStorage.getItem("map_adress2"));
    
  }
 
    render() {
      const urlPath = window.location.origin.toString();
        return (
          <div className="pamba-wrapper">
            <Suspense fallback={<div class="loader"></div>}>
            <section>
            <Header />
            <div>
            <iframe src={"https://maps.google.com/maps?q="+window.localStorage.getItem("map_address1")+","+window.localStorage.getItem("map_address2")+" &z=3&output=embed"} width="100%" height="235" frameborder="0" allowfullscreen="" ></iframe>
            </div>
            <Footer />
            </section>
        </Suspense>
          </div>
        );
    }
}
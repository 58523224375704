import React, { Component, Suspense } from "react";

import edit from "../image/media/edit.png";
import publish from "../image/media/draft.png";
import draft from "../image/media/publish.png";
import search from "../image/theme/search.png";
import $ from "jquery";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class Healtharticle extends Component {
  componentDidMount() {
    $(".popup-hide").click(function () {
      $(".popup-overlay").hide();
    });
    // $("html").click(function () {
    //   $(".popup-overlay").hide();
    // });

    // $(".popup-content").click(function (event) {
    //   event.stopPropagation();
    // });
  }

  healthEmbedCode() {
    $("#healthEmbedCode-main").show();
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />{" "}
          </section>
        </Suspense>
        <div class="container container-auto">
          <div class="main-wrapper1">
            <div class="col-md-12 p-0">
              <ul class="breadcrumb">
                <li>
                  <a href="/doctor/dashboard">Dashboard</a>
                  <i class="fa fa-chevron-right"></i>
                </li>
                <li>Health Article List</li>
              </ul>
            </div>
            <div class="form-head mt-4">Health Article List</div>
            <div class="col-sm-12 p-0 mb-3">
              <a href="/doctor/healthblog">
                <input
                  type="button"
                  value="Add Health Article"
                  class="formButton-grey"
                ></input>
              </a>
              
            </div>
            <div
              class="custom-search form-section-brdr row"
              style={{ borderRadius: "40px" }}
            >
              <div class="col-md-2 col-lg-2 my-2">
                <select class="input-design">
                  <option value="0" selected="">
                    Search In
                  </option>
                  <option value="level1">Id</option>
                  <option value="level2">Title</option>
                  <option value="level3">Category</option>
                </select>
              </div>
              <div class="col-md-3 col-lg-3 my-2">
                <input type="text" class="input-design" disabled="" />
              </div>
              <div class="col-md-2 col-lg-2 my-2">
                <select class="input-design">
                  <option value="0" selected="" class="input-design">
                    Status
                  </option>
                  <option value="false">Draft</option>
                  <option value="true">Published</option>
                </select>
              </div>
              <div class="col-md-3 col-lg-3 my-2">
                <select class="input-design">
                  <option value="0" selected="">
                    Approval Status
                  </option>
                  <option value="false">Not approved</option>
                  <option value="true">Approved</option>
                </select>
              </div>
              <div class="col-md-2 col-lg-2 my-2">
                <button type="submit" class="formButtonsearch mb-0">
                  <img src={search} width="20"></img> Search
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead class="form-section-bgcolor">
                  <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Category</th>
                    <th>Status</th>
                    <th>Approval Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>201 </td>
                    <td>Sanjay Deo </td>
                    <td>Video </td>
                    <td>Draft</td>
                    <td>Not approved </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <a href="#">
                        <img src={draft} width="25" />
                      </a>
                      <a href="#">
                        <img src={publish} width="25" />
                      </a>
                      <a href="/doctor/healthblog" class="ml-3">
                        <img src={edit} width="25" />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />{" "}
          </section>
        </Suspense>

      </div>
    );
  }
}

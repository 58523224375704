import React, { Component, Suspense } from "react";
import { Link } from 'react-router-dom'

import { S3ImagePath } from "../config";
const imgPath = S3ImagePath();
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class DoctorList extends Component {
  render() {
    return (
      <div className="main">
       <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <main role="main">
        <div className='subpage_banner subpage-sub-item-banner'>
          <h1 className='subpage_subtitle'>Doctor Catalogue</h1>
          <div className="row container dr-catalogue">
            <div className="col-md-4">
              <div className="form-group">
                <select className="form-control select-tags" id="exampleFormControlSelect1">
                  <option>Select Speciality</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <select className="form-control select-tags" id="exampleFormControlSelect1">
                  <option>Select Hospital</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <select className="form-control select-tags" id="exampleFormControlSelect1">
                  <option>Select District</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className='subpage'>
          <div className='container'>
            <div className='row doctorMainList'>
              <div className="col-md-6">
                <div className="doctorListLeft">
                  <div className="doctorListItem">
                    <div className="imageAvatar">
                      <img src={imgPath+"/doctor_286341.jpg"} alt="doctor" width="70" height="70" />
                    </div>
                    <div className="doctorDetailsDiv">
                      <div className="doctorItemName">
                        <div className='doctor_name'>Dr.Sooraj </div>
                        <div className='doctor_name'>MBBS</div>
                      </div>
                      <div className="doctorItemAddress">
                        <p  className="doctorItem">Addictive Medicine</p>
                        <p  className="doctorItem">Test IMA Hospital</p>
                        <p   className="doctorItem"title="Test Address">Test Address</p>
                        <p  className="doctorItem">Pin - 695582</p>
                      </div>
                    </div>
                    <div className="appointmentButtonDiv">
                      <Link to="/" className="mainLink">View Profile</Link>
                      <Link to="/" className="bgButton">Book Appointment</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="doctorListLeft">
                  <div className="doctorListItem">
                    <div className="imageAvatar">
                      <img src={imgPath+"/doctor_286341.jpg"} alt="doctor" width="70" height="70" />
                    </div>
                    <div className="doctorDetailsDiv">
                      <div className="doctorItemName">
                        <div className='doctor_name'>Dr.Sooraj </div>
                        <div className='doctor_name'>MBBS</div>
                      </div>
                      <div className="doctorItemAddress">
                        <p  className="doctorItem">Addictive Medicine</p>
                        <p  className="doctorItem">Test IMA Hospital</p>
                        <p  className="doctorItem" title="Test Address">Test Address</p>
                        <p  className="doctorItem">Pin - 695582</p>
                      </div>
                    </div>
                    <div className="appointmentButtonDiv">
                      <Link to="/" className="mainLink">View Profile</Link>
                      <Link to="/" className="bgButton">Book Appointment</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="doctorListLeft">
                  <div className="doctorListItem">
                    <div className="imageAvatar">
                      <img src={imgPath+"/doctor_286341.jpg"} alt="doctor" width="70" height="70" />
                    </div>
                    <div className="doctorDetailsDiv">
                      <div className="doctorItemName">
                        <div className='doctor_name'>Dr.Sooraj</div>
                        <div className='doctor_name'>MBBS</div>
                      </div>
                      <div className="doctorItemAddress">
                        <p  className="doctorItem">Addictive Medicine</p>
                        <p  className="doctorItem">Test IMA Hospital</p>
                        <p  className="doctorItem" title="Test Address">Test Address</p>
                        <p  className="doctorItem">Pin - 695582</p>
                      </div>
                    </div>
                    <div className="appointmentButtonDiv">
                      <Link to="/" className="mainLink">View Profile</Link>
                      <Link to="/" className="bgButton">Book Appointment</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="doctorListLeft">
                  <div className="doctorListItem">
                    <div className="imageAvatar">
                      <img src={imgPath+"/doctor_286341.jpg"} alt="doctor" width="70" height="70" />
                    </div>
                    <div className="doctorDetailsDiv">
                      <div className="doctorItemName">
                        <div className='doctor_name'>Dr.Sooraj</div>
                        <div className='doctor_name'>MBBS</div>
                      </div>
                      <div className="doctorItemAddress">
                        <p  className="doctorItem">Addictive Medicine</p>
                        <p  className="doctorItem">Test IMA Hospital</p>
                        <p  className="doctorItem" title="Test Address">Test Address</p>
                        <p  className="doctorItem">Pin - 695582</p>
                      </div>
                    </div>
                    <div className="appointmentButtonDiv">
                      <Link to="/" className="mainLink">View Profile</Link>
                      <Link to="/" className="bgButton">Book Appointment</Link>
                    </div>
                  </div>
                </div></div>
            </div>
          </div>
        </div>
        </main>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    )
  }
}

import React, { Component, Suspense } from "react";
//import calendar from "../image/media/calendar.png";
//import vitalsign from "../image/media/vitalsign.png";
//import casesheet from "../image/media/case-sheet.png";
//import treatmentplan from "../image/media/treatment-plan.png";
import invoice from "../image/media/invoice.png";
//import compplans from "../image/media/comp-plans.png";
//import rx from "../image/media/rx.png";
//import cash from "../image/media/cash.png";
import { apiCalling } from "../apiService";
import $ from "jquery";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class History extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.addHandle = this.addHandle.bind(this);
  }
  needbreak() {
    $("#needbreak-text").hide();
    $("#Resume-text").show();
  }
  resumeChat() {
    $("#needbreak-text").show();
    $("#Resume-text").hide();
  }

  componentDidMount() {
    this.getDataHistory();
    $("#Appointments").hide();

  }

  getDataHistory() {

    const historyList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "patientHistory",
        userId: window.localStorage.getItem("userId"),
        siteId: window.localStorage.getItem("siteId")
      }),
    }
    apiCalling(historyList).then((data) => {
     // console.log("---///////8888---", data)
      if (data.success == "1") {
        this.setState({ historyDetails: data.historyData });
       // console.log("**********5656**", this.state.historyDetails)
      }
    });
  }

  addHandle = () => {

    $("#Appointments").toggle();
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper history-main">
            <div class="col-sm-12 form-head">History</div>
            <div class="form-section-brdr px-3">
              <div class="row">
                {/* <div className="col-12">
                  <div className="link-color mb-2 text-right">
                    <span id="needbreak-text" onClick={this.needbreak}>
                      Select All
                    </span>
                    <span
                      id="Resume-text"
                      style={{ display: "none" }}
                      onClick={this.resumeChat}
                    >
                      Deselect All
                    </span>
                  </div>
                </div> */}
                <div class="col-lg-3 col-md-4 mb-2">
                  <label className="radio-main">
                    <input type="radio" onClick={() => this.addHandle()} />
                    <span class="radio"
                    ></span>
                  </label>{" "}
                  Appointments
                </div>
                <div class="col-lg-3 col-md-4 mb-2">
                  <label className="radio-main" onClick={() => this.addHandle()}>
                    <input type="radio" />
                    <span class="radio"></span>
                  </label>
                  Case Sheet
                </div>
                <div class="col-lg-3 col-md-4 mb-2">
                  <label className="radio-main">
                    <input type="radio" />
                    <span class="radio"></span>
                  </label>{" "}
                  Vital Signs
                </div>
                <div class="col-lg-3 col-md-4 mb-2">
                  <label className="radio-main">
                    <input type="radio" />
                    <span class="radio"></span>
                  </label>{" "}
                  Treatment Plans
                </div>
                <div class="col-lg-3 col-md-4 mb-2">
                  <label className="radio-main">
                    <input type="radio" />
                    <span class="radio"></span>
                  </label>
                  Completed Plans
                </div>
                <div class="col-lg-3 col-md-4 mb-2">
                  <label className="radio-main">
                    <input type="radio" />
                    <span class="radio"></span>
                  </label>{" "}
                  Files
                </div>
                <div class="col-lg-3 col-md-4 mb-2">
                  <label className="radio-main">
                    <input type="radio" />
                    <span class="radio"></span>
                  </label>
                  Prescriptions
                </div>
                <div class="col-lg-3 col-md-4 mb-2">
                  <label className="radio-main">
                    <input type="radio" />
                    <span class="radio"></span>
                  </label>{" "}
                  Lab Order
                </div>
                <div class="col-lg-3 col-md-4 mb-2">
                  <label className="radio-main">
                    <input type="radio" />
                    <span class="radio"></span>
                  </label>{" "}
                  Invoices
                </div>
                <div class="col-lg-3 col-md-4 mb-2">
                  <label className="radio-main">
                    <input type="radio" />
                    <span class="radio"></span>
                  </label>{" "}
                  Payments
                </div>
                <div class="col-lg-3 col-md-4 mb-2">
                  <label className="radio-main">
                    <input type="radio" />
                    <span class="radio"></span>
                  </label>
                  Test Result
                </div>
                <div class="col-lg-3 col-md-4 mb-2">
                  <label className="radio-main">
                    <input type="radio" />
                    <span class="radio"></span>
                  </label>
                  Refer A Doctor
                </div>
                <div class="col-lg-3 col-md-4 mb-2">
                  <label className="radio-main">
                    <input type="radio" />
                    <span class="radio"></span>
                  </label>
                  Admit
                </div>
                <div class="col-lg-3 col-md-4 mb-2">
                  <label className="radio-main">
                    <input type="radio" />
                    <span class="radio"></span>
                  </label>
                  Discharge Note
                </div>
              </div>
            </div>
            <div class="form-section-brdr text-center p-3 py-5">
              History Not Available
            </div>

            <div class="treatmentTimelineBlockMain" id="Appointments">
              <div class="col-sm-12 form-head mb-2">Appointments</div>
              <div class="practiceTimeline-panel row">
                <div className="col-lg-2 col-md-4 history-date-time history-brdr">
                  <div className="history-month">July</div>
                  <div className="history-date">13</div>
                  <div className="history-day">Mon</div>
                </div>
                <div className="col-lg-4 col-md-8 history-center">
                  <span class="history-drName">Dr.Ortho Free Doc </span>
                  <br></br>
                  <span class="elipsis history-hospitalName">(Ortho)</span>
                </div>
                <div className="col-lg-2 col-md-4 history-reason ">
                  Reason : <span className="history-type-text">Test</span>
                </div>
                <div className="col-lg-2 col-md-4 history-type history-brdr">
                  Type :<span className="history-type-text">In Person</span>
                </div>
                <div className="col-lg-2 col-md-4 history-status">
                  Status :<span class="history-status-text"> Open</span>
                </div>
              </div>
            </div>

            <div class="treatmentTimelineBlockMain" id="vitalSigns">
              <div class="col-sm-12 form-head mb-2">Vital Signs</div>
              <div class="practiceTimeline-panel row">
                <div className="col-lg-2 col-md-4 history-date-time history-brdr">
                  <div className="history-month">July</div>
                  <div className="history-date">13</div>
                  <div className="history-day">Mon</div>
                </div>
                <div className="col-lg-3 col-md-8 history-center">
                  <span class="history-drName">Dr.Ortho Paid Doc (Ortho)</span>
                  <br></br>
                  <span class="elipsis history-hospitalName">Noted by</span>
                </div>
                <div className="col-lg-3 col-md-6 history-type-div">
                  Blood Pressure SYS/DIA (mmHg)
                  <span class="history-text"> 110/90 Sitting</span>
                </div>
                <div className="col-lg-2 col-md-3 history-type history-brdr">
                  Weight (Kg) :<span className="history-type-text">74</span>
                </div>
                <div className="col-lg-2 col-md-3 history-type">
                  Time : <span className="history-type-text">15:58</span>
                </div>
              </div>
            </div>

            <div class="treatmentTimelineBlockMain">
              <div class="col-sm-12 form-head mb-2">Case Sheet</div>
              <div class="practiceTimeline-panel row">
                <div className="col-lg-2 col-md-4 history-date-time history-brdr">
                  <div className="history-month">July</div>
                  <div className="history-date">13</div>
                  <div className="history-day">Mon</div>
                </div>
                <div className="col-lg-3 col-md-8 history-center history-brdr">
                  <span class="history-drName">Dr.Ortho Paid Doc (Ortho)</span>
                  <br></br>
                  <span class="elipsis history-hospitalName">Noted by</span>
                </div>
                <div className="col-lg-2 col-md-4 history-type-div history-brdr">
                  Chief presenting complaint
                  <span class="history-text"> Chief Complaint13, Jul 2020</span>
                </div>
                <div className="col-lg-2 col-md-4 history-type-div history-brdr">
                  Associated complaints
                  <span className="history-text">
                    Associated Complaint Known Case of13, Jul 2020
                  </span>
                </div>
                <div className="col-lg-3 col-md-4 history-type-div">
                  Observations Description
                  <span className="history-text">Observations</span>
                </div>
              </div>
              <div class="practiceTimeline-panel row">
                <div className="col-lg-2 col-md-4 history-date-time history-brdr">
                  <div className="history-month">July</div>
                  <div className="history-date">13</div>
                  <div className="history-day">Mon</div>
                </div>
                <div className="col-lg-3 col-md-8 history-center history-brdr">
                  <span class="history-drName">Dr.Ortho Paid Doc (Ortho)</span>
                  <br></br>
                  <span class="elipsis history-hospitalName">Noted by</span>
                </div>
                <div className="col-lg-2 col-md-4 history-type-div history-brdr">
                  Chief presenting complaint
                  <span class="history-text"> Chief Complaint13, Jul 2020</span>
                </div>
                <div className="col-lg-2 col-md-4 history-type-div history-brdr">
                  Associated complaints
                  <span className="history-text">
                    Associated Complaint Known Case of13, Jul 2020
                  </span>
                </div>
                <div className="col-lg-3 col-md-4 history-type-div">
                  Observations Description
                  <span className="history-text">Observations</span>
                </div>
              </div>
            </div>

            <div class="treatmentTimelineBlockMain">
              <div class="col-sm-12 form-head mb-2">Treatment plan</div>
              <div class="practiceTimeline-panel row">
                <div className="col-lg-2 col-md-4 history-date-time">
                  <div className="history-month">July</div>
                  <div className="history-date">13</div>
                  <div className="history-day">Mon</div>
                </div>
                <div className="col-lg-12 col-md-12 history-center">
                  <div class="table-responsive">
                    <table className="table-bordered">
                      <thead>
                        <tr>
                          <th class="col-md-3" style={{ minWidth: "200px" }}>
                            <div class="dentalProName">PROCEDURE</div>
                          </th>
                          <th class="col-md-2">
                            <div class="dentalProCost">COST</div>
                          </th>
                          <th class="col-md-2">
                            <div class="dentalProDis">TAX</div>
                          </th>
                          <th class="col-md-2">
                            <div class="dentalProTotal">TOTAL</div>
                          </th>
                          <th class="col-md-3">
                            <div class="dentalProNote">NOTES</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="groupBrdr">
                          <td class="col-md-3">
                            <div class="dentalProName">
                              <div class="optionWrapper">
                                <img
                                  src={invoice}
                                  title="Invoice Generated"
                                ></img>
                                <label for="cool011"></label>
                              </div>
                              <span class="elipsis">t102</span>
                            </div>
                          </td>
                          <td class="col-md-2">
                            <div class="dentalProCost">100.00</div>
                          </td>
                          <td class="col-md-2">
                            <div class="dentalProDis">0.00</div>
                          </td>
                          <td class="col-md-2">
                            <div class="dentalProTotal">100.00</div>
                          </td>
                          <td class="col-md-3">
                            <div class="dentalProNote">
                              <span class="elipsis" title="">
                                &nbsp;
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr class="dentalProPlaned">
                          <input
                            type="hidden"
                            name="hidTreatmentIds00"
                            id="hidTreatmentIds00"
                            value=""
                          />
                          <td>
                            <div class="plannedBy">
                              Planned by <span>Dr. Ortho Paid Doc (Ortho)</span>
                            </div>
                          </td>
                          <td colspan="4">
                            <div class="estimatedPrice txtRight">
                              Estimated amount : 100.00
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="treatmentTimelineBlockMain">
              <div class="col-sm-12 form-head mb-2">COMPLETED PLANS</div>
              <div class="practiceTimeline-panel row">
                <div className="col-lg-2 col-md-4 history-date-time">
                  <div className="history-month">July</div>
                  <div className="history-date">13</div>
                  <div className="history-day">Mon</div>
                </div>
                <div className="col-lg-12 col-md-12 history-center">
                  <div class="table-responsive">
                    <table className="table-bordered">
                      <thead>
                        <tr>
                          <th class="col-md-3" style={{ minWidth: "200px" }}>
                            <div class="dentalProName">PROCEDURE</div>
                          </th>
                          <th class="col-md-2">
                            <div class="dentalProCost">COST</div>
                          </th>
                          <th class="col-md-2">
                            <div class="dentalProDis">DISCOUNT</div>
                          </th>
                          <th class="col-md-2">
                            <div class="dentalProDis">TAX</div>
                          </th>
                          <th class="col-md-2">
                            <div class="dentalProTotal">TOTAL</div>
                          </th>
                          <th class="col-md-3">
                            <div class="dentalProNote">NOTES</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="groupBrdr">
                          <td class="col-md-3">
                            <div class="dentalProName">
                              <div class="optionWrapper">
                                <img
                                  src={invoice}
                                  title="Invoice Generated"
                                ></img>
                                <label for="cool011"></label>
                              </div>
                              <span class="elipsis">t102</span>
                            </div>
                          </td>
                          <td class="col-md-2">
                            <div class="dentalProCost">100.00</div>
                          </td>
                          <td class="col-md-2">
                            <div class="dentalProDis">0.00</div>
                          </td>
                          <td class="col-md-2">
                            <div class="dentalProDis">0.00</div>
                          </td>
                          <td class="col-md-2">
                            <div class="dentalProTotal">100.00</div>
                          </td>
                          <td class="col-md-3">
                            <div class="dentalProNote">
                              <span class="elipsis" title="">
                                &nbsp;
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr class="dentalProPlaned">
                          <input
                            type="hidden"
                            name="hidTreatmentIds00"
                            id="hidTreatmentIds00"
                            value=""
                          />
                          <td colspan="6">
                            <div class="plannedBy">
                              Planned by <span>Dr. Ortho Paid Doc (Ortho)</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="treatmentTimelineBlockMain">
              <div class="col-sm-12 form-head mb-2">Prescriptions</div>
              <div class="practiceTimeline-panel row">
                <div className="col-lg-2 col-md-4 history-date-time history-brdr">
                  <div className="history-month">July</div>
                  <div className="history-date">13</div>
                  <div className="history-day">Mon</div>
                </div>
                <div className="col-lg-3 col-md-8 history-center">
                  <span class="history-drName">Dr.Ortho Paid Doc (Ortho)</span>
                  <br></br>
                  <span class="elipsis history-hospitalName">Noted by</span>
                </div>
                <div className="col-lg-12 col-md-12 history-center">
                  <div class="table-responsive">
                    <table className="table-bordered">
                      <thead>
                        <tr>
                          <th class="col-md-3" style={{ minWidth: "200px" }}>
                            <div class="dentalProName">Medicine</div>
                          </th>
                          <th class="col-md-2">
                            <div class="dentalProCost">Frequency & Dosage </div>
                          </th>
                          <th class="col-md-2">
                            <div class="dentalProCost">Intake</div>
                          </th>
                          <th class="col-md-2">
                            <div class="dentalProCost">Qty</div>
                          </th>
                          <th class="col-md-2">
                            <div class="dentalProCost">Days</div>
                          </th>
                          <th class="col-md-3">
                            <div class="dentalProCost">Start Date</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="groupBrdr">
                          <td class="col-md-3">
                            <div class="dentalProName">
                              {" "}
                              CINCLOX-S inj vial{" "}
                            </div>
                          </td>
                          <td class="col-md-2">
                            <div
                              class="dentalProCost"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Three Times A Day - 0 - 0 - 0
                            </div>
                          </td>
                          <td class="col-md-2">
                            <div
                              class="dentalProCost"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Before Food
                            </div>
                          </td>
                          <td class="col-md-2">
                            <div class="dentalProCost"></div>
                          </td>
                          <td class="col-md-2">
                            <div class="dentalProCost"></div>
                          </td>
                          <td class="col-md-2">
                            <div
                              class="dentalProCost"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              13-Jul-2020
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="treatmentTimelineBlockMain">
              <div class="col-sm-12 form-head mb-2">Lab Order</div>
              <div class="practiceTimeline-panel row">
                <div className="col-lg-2 col-md-4 history-date-time history-brdr">
                  <div className="history-month">July</div>
                  <div className="history-date">13</div>
                  <div className="history-day">Mon</div>
                </div>
                <div className="col-lg-3 col-md-8 history-center">
                  <span class="history-drName">Dr.Ortho Paid Doc (Ortho)</span>
                  <br></br>
                  <span class="elipsis history-hospitalName">Noted by</span>
                </div>
                <div className="col-lg-12 col-md-12 history-center">
                  <div class="table-responsive">
                    <table className="table-bordered">
                      <thead>
                        <tr>
                          <th class="col-md-3" style={{ minWidth: "200px" }}>
                            <div class="dentalProName">TEST NAME </div>
                          </th>
                          <th class="col-md-2">
                            <div class="dentalProCost">SPECIMEN</div>
                          </th>
                          <th class="col-md-2">
                            <div class="dentalProDis">TEST TYPE</div>
                          </th>
                          <th class="col-md-2">
                            <div class="dentalProDis">AMOUNT</div>
                          </th>
                          <th class="col-md-2">
                            <div class="dentalProTotal">NOTES</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="groupBrdr">
                          <td class="col-md-3">
                            <div class="dentalProName">ECG </div>
                          </td>
                          <td class="col-md-2">
                            <div
                              class="dentalProCost"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              blood
                            </div>
                          </td>
                          <td class="col-md-2">
                            <div
                              class="dentalProDis"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Hepatitis
                            </div>
                          </td>
                          <td class="col-md-2">
                            <div class="dentalProDis">1500.00</div>
                          </td>
                          <td class="col-md-2">
                            <div class="dentalProTotal"></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="treatmentTimelineBlockMain">
              <div class="col-sm-12 form-head mb-2">Payment</div>
              <div class="practiceTimeline-panel row">
                <div className="col-lg-2 col-md-4 history-date-time history-brdr">
                  <div className="history-month">July</div>
                  <div className="history-date">13</div>
                  <div className="history-day">Mon</div>
                </div>
                <div className="col-lg-12 col-md-12 history-center">
                  <div class="table-responsive">
                    <table className="table-bordered">
                      <thead>
                        <tr>
                          <th class="col-md-3" style={{ minWidth: "200px" }}>
                            <div class="dentalProName">RECEIPT NUMBER </div>
                          </th>
                          <th class="col-md-2">
                            <div class="dentalProCost">AMOUNT PAID</div>
                          </th>
                          <th class="col-md-2">
                            <div class="dentalProDis">INVOICES NUMBER</div>
                          </th>
                          <th class="col-md-2">
                            <div class="dentalProDis">TOWARDS</div>
                          </th>
                          <th class="col-md-2">
                            <div class="dentalProTotal">MODE OF PAYMENT</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="groupBrdr">
                          <td class="col-md-3">
                            <div class="dentalProName">RCP5 </div>
                          </td>
                          <td class="col-md-2">
                            <div
                              class="dentalProCost"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              1,600.00
                            </div>
                          </td>
                          <td class="col-md-2">
                            <div
                              class="dentalProDis"
                              style={{ whiteSpace: "nowrap" }}
                            ></div>
                          </td>
                          <td class="col-md-2">
                            <div class="dentalProDis">INV7</div>
                          </td>
                          <td class="col-md-2">
                            <div class="dentalProTotal">new proc (t102)ECG</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

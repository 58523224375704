import React, { Component, Suspense } from "react";
import * as ReactDOM from "react-dom";
const folderName = window.localStorage.getItem("homeName");
const Home = React.lazy(() => import("../" + folderName + "/home"));
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class Common extends Component {
  componentDidMount() {
    let name = this.props.match.params.pageName;   
    if(folderName===null){    
      window.localStorage.setItem("pathname",window.location.pathname)
      window.location.href ="/"
    }
    this.loadPage(name);
  }
  loadPage(name) {
    const LazyPage = React.lazy(() => import("../" + folderName + "/" + name));
    ReactDOM.render(
      <div>
        <Suspense fallback={<div></div>}>
          <section>
            <LazyPage pageName={name}/>
          </section>
        </Suspense>
      </div>,
      document.getElementById("commonLoader")
    );
  }
  render() {
    return (
      // <div className="purpleWrap home bg-clr-wthHead">
      <div>
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div id="commonLoader"></div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

import React, { Component, Suspense } from "react";
import $ from "jquery";
import bolgtemp from "../image/icons/blogging.png"
import { apiCalling } from "../apiService";
import InnerHTML from "dangerously-set-html-content";
import { Helmet } from "react-helmet";
const folderName = window.localStorage.getItem("homeName");

const Home = React.lazy(() => import("../" + folderName + "/home"));
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class HealthblogPost extends Component {
  constructor(props) {
    super(props);
    this.state = {    
      selectCategotyList: [],
      resultData:[],
      showMessage:[],

     
    }
  }
  

componentDidMount(){
  if(folderName===null){    
    window.localStorage.setItem("pathname",window.location.pathname)
    window.location.href ="/"
  }

  this.categorySelectList();
  this.showBlogDetails();
}
showBlogDetails(){
  const dataList = {
    method: 'POST',
    body: JSON.stringify({
      "functionName": "getDoctorHealthArticles",
      "siteId": window.localStorage.getItem("siteId"),
       "healthInfoId": this.props.match.params.blogId
    })
  } 
  $(".loader").show();
  apiCalling(dataList).then((data) => {   
    $(".loader").hide();
    if (data.success == "1" && data.result.length > 0) {
      this.setState({ resultData: data.result[0] });
    } 
  });

}
  categorySelectList() {
    $(".loader").show();
    const saveField = {
      method: 'POST',
      body: JSON.stringify({
        "functionName": "getHealthInfoCommonData",
        "siteId":window.localStorage.getItem("siteId")
      })
    } 
    apiCalling(saveField).then((data) => {   
      $(".loader").hide(); 
      if (data.success == "1") {
        this.setState({
          selectCategotyList: data.result,
        })

      }
    })
  }

  categorySearch(obj){
    const dataList = {
      method: 'POST',
      body: JSON.stringify({
        "functionName": "getDoctorHealthArticles",
        "siteId": window.localStorage.getItem("siteId"),
        "categoryId": obj.categoryId
      })
    }   
    $(".loader").show();
    apiCalling(dataList).then((data) => {    
      $(".loader").hide();
      if (data.success == "1" && data.result.length > 0) {
        this.setState({ resultData: data.result });
      } else if (data.success == "0") {
        this.setState({ showMessage: data.errorMessage });
      }
    });
    $("html,body").animate({"scrollTop": $("#blog-detailsId").offset().top})
  }

  srcTypeImage(ev){
    ev.target.src =bolgtemp;
  }


  render() {
    return (
      <div className="pamba-wrapper">
           <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <Helmet>
          <title>{this.props.match.params.shortDescri}</title>
          <meta
            name="description"
            content={this.props.match.params.shortDescri}
          />        
        </Helmet>
           <div className="container blog-wrapper">

            <div className="row mt-2">

            {/* <div className="col-lg-3 col-md-4 mb-3">
                  <div className="category-main">                    
                    <div className="category-list">
                    {this.state.selectCategotyList.map((obj) => (
                      // <a href={"blog-"+obj.categoryId}><div className="category-sub">{obj.categoryName}</div></a>
                      <Link to={"/blog-"+obj.categoryId}><div className="category-sub">{obj.categoryName}</div></Link>
                      ))}
                    </div>
                  </div>
                </div>                */}

              <div className="col-lg-12 col-md-12 mb-5"  id="blog-detailsId">
                <div className="col-md-12 p-0">
                 <div className="LocalsearchImgdiv">
               <img  onError={this.srcTypeImage}  src={this.state.resultData.imageName} alt="Blog Image" className="img-fluid mb-3 LocalsearchImg"></img>
                </div><br></br>
                  <h1 className="bloguser-text">{this.state.resultData.healthInfoTitle} </h1>
                  <div className="bloguser-det my-1">
                    {/* <div className="bloguser-img"><img src={bloguser} alt="Blog user"></img></div> */}
                    <div className="blog-detls">{this.state.resultData.healthInfoPostDate}</div>
                    {/* <div className="blog-detls">/</div> 
                    <div className="blog-detls">Zoran Dobrijevic</div>
                    {/* <div className="blog-detls">/</div> */}
                    <a href="#" className="blog-detls">{this.state.resultData.healthInfoCategory}</a>
                  </div>
                  <p className="Blogsubtext"><InnerHTML html={this.state.resultData.shortDescription}/></p>
                  {/* <ul className="BlogsubtextUl">
                      <li className="BlogsubtextLi">The calendar may say 2021, but local search is still adapting</li>
                      <li className="BlogsubtextLi">The calendar may say 2021, but local search is still adapting</li>
                  </ul> */}
                  {/* <h1 className="BlogsubHead">Google My Business (GMB) service menus get an update</h1> */}
                  <p className="Blogsubtext">
                    <InnerHTML html={this.state.resultData.description === "null" ? "" : this.state.resultData.description}  />
                    </p>
                </div>  

                
                </div>

              </div>
              </div>
              <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    )
  }
}

import React, { Component } from "react";
import $ from "jquery";
import { apiCalling } from "../apiService";

export default class subheaderPopup extends Component {

  constructor(props){
    super(props);
    this.state={
      subName:"",
      subLink:"",
      htmlCont1:"",
      sortOrder1:"",
      subList:[],
      headerLinkSubId:'',
      headerId:this.props.headerDeatils.headerLinksId,
      menuName:this.props.headerDeatils.headerlinkName,
      menuLink:this.props.headerDeatils.url,
      htmlCont:this.props.headerDeatils.headerlinkContent,
      sortOrder:this.props.headerDeatils.sortOrder,
      isActive:this.props.headerDeatils.isActive,
    }
this.changeHandleInput=this.changeHandleInput.bind(this);
  }

  changeHandleInput = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  }

  saveSubMenuData(){
    const saveApi = {
      method: "POST",
      body: JSON.stringify({
          functionName: "saveHeaderLinksDetails",
          siteId:window.localStorage.getItem("siteId"),
          headerSectionObj: {
                headerLinksId:this.state.headerId,
                headerlinkName:this.state.menuName,
                headerlinkContent:this.state.htmlCont,
                groupHospitalAdminId:window.localStorage.getItem("groupHospitalAdminId"),
                hospitalId: "",
                headerUrl: this.state.menuLink,
                menu: "",
                subMenu: "",
                menuType: "",
                isActive: this.state.isActive,
                headerSortOrder:this.state.sortOrder,
                headerSubList: [
                               
                                {
                                    subContent:$("#htmlCont1").val(),
                                    subName: this.state.subName,
                                    headerLinksId:this.state.headerId,
                                    subIsActive: "1",
                                    headerLinkSubId: this.state.headerLinkSubId,
                                    subUrl:this.state.subLink,
                                    subSortOrder:this.state.sortOrder1
                                }
                            ]
            }
      })
    }
    apiCalling(saveApi).then((data) => {
      if (data.success === "1") {
        alert("Saved successfully");
      } else {
        alert("update failed...")
      }
      this.props.headerSubVisible(false)
      window.location.reload(false);
    });
  }

  componentDidMount() {
    $(".popup-hide").click(function () {
      $(".popup-overlay").hide();
    });

    const headerList = {
      method: "POST",
      body: JSON.stringify({
      functionName: "headerLinksDetails",
      siteId:window.localStorage.getItem("siteId"),
    }),
  }

  apiCalling(headerList).then((data) => {
    if (data.success === "1") {
     this.setState({subList:data.headerLinkContent[0].headerSubList});
    }
  });
  }

  cancelPopup() {
    this.props.headerSubVisible(false)
  }

  render() {

    return (
      <div class="popup-overlay"  style={{display:'block'}}>
        <div className="popup-contentDiv">
          <div className="modal-popup">
            <a className="close-popup popup-hide" onClick={() => this.cancelPopup()}>&times;</a>
            <div className="header border-0">Sub Menu Header</div>
            <div className="content">
              <div class="row">
                <div class="row-margin col-md-6">
                  <label class="col-md-12 label-text">Submenu Name</label>
                  <div class="col-md-12"><input type="text" class="input-design" id="subName" name="subName" value={this.state.subName} onChange={this.changeHandleInput} /></div>
                </div>
                <div class="row-margin col-md-6">
                  <label class="col-md-12 label-text">Submenu Link</label>
                  <div class="col-md-12"><input type="text" class="input-design" id="subLink" name="subLink" value={this.state.subLink} onChange={this.changeHandleInput} /></div>
                </div>
                <div class="row-margin col-md-12">
                  <label class="col-md-12 label-text">HTML Content</label>
                  <div class="col-md-12"><textarea class="input-design" style={{height:"100px"}} id="htmlCont1" name="htmlCont1" onChange={this.changeHandleInput} >{this.state.htmlCont1}</textarea></div>
                </div>
                <div class="row-margin col-md-6">
                  <label class="col-md-12 label-text">Sort Order</label>
                  <div class="col-md-12">
                    <select class="input-design"
                     id="sortOrder1"
                     name="sortOrder1"
                     value={this.state.sortOrder1}
                     onChange={this.changeHandleInput}
                    >
                      <option>Select</option>
                      <option>0</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <a href="#">
                  {/* <input
                    type="submit"
                    value="Save"
                    className="formButtonBg mx-1"
                    onClick={() => this.saveSubMenuData()}
                  ></input> */}
                </a>
                <div className="divBtn" onClick={() => this.saveSubMenuData()}>Save</div>
                <input
                  type="button"
                  value="cancel"
                  className="formButtonBg cancelButtonBg mx-1 popup-hide"
                  onClick={() => this.cancelPopup()}
                ></input>
              </div>
              {/* <div class="table-responsive tableBorderOnly mt-5">
                <table class="table table-border">
                  <thead>
                    <th>Sl No.</th>
                    <th>Name</th>
                  </thead>
                  <tbody>
                  {this.state.subList.map((subDeatils) => (
                    <tr>
                      <td>{subDeatils.headerLinksId}</td>
                       <td>{subDeatils.subName}</td>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div> */}
            </div>
          </div>
        </div>
        <div >
        </div>
      </div>
    );
  }
}

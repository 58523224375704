import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
let groupHospitalId=""
export default class reportDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupHospitalId:this.props.match.params.groupHospitalId
    }
  }

  dashboardclick = () => {
    window.location.href = "/admin/dashboard";
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  //  alert(this.state.groupHospitalId)
    groupHospitalId=this.state.groupHospitalId;
  }

render() {
  return (
    <div className="purpleWrap bg-clr-wthHead">
      <Suspense fallback={<div></div>}>
        <section>
          {folderName!==null &&
           <Header />
          }
          <div className="container">
          {folderName!==null &&
          <div className="divBtn" onClick={this.dashboardclick}>Dash Board</div>
}
            <div className="sectionItemDiv sectionItemDivBorder">
                <div className="sectionItemContentBorder text-center">
                <Link  className="sectionTag" to={"/dashboard/hospitalReports/"+this.props.match.params.groupHospitalId}>
                  <a>Hospital Report</a>
                </Link>
                <Link  className="sectionTag" to={"/dashboard/videoaudioReports/"+this.props.match.params.groupHospitalId}>
                Video /Audio Chat Payment Report
                </Link>
                <Link  className="sectionTag" to={"/dashboard/textChatReports/"+this.props.match.params.groupHospitalId}>
                Text Chat Payment Report
                </Link>
                <Link  className="sectionTag" to={"/dashboard/inPersonReports/"+this.props.match.params.groupHospitalId}>
                  <a>IN Person appointment Report</a>
                </Link>
                <Link  className="sectionTag" to={"/dashboard/doctorRegistrationReport/"+this.props.match.params.groupHospitalId}>
                  <a>Doctor Registration Report</a>
                </Link>
                <Link  className="sectionTag" to={"/dashboard/pharmaNetworkReport/"+this.props.match.params.groupHospitalId}>
                  <a>Pharma Network Report</a>
                </Link>
                <Link  className="sectionTag" to={"/dashboard/privilegeCardReport/"+this.props.match.params.groupHospitalId}>
                  <a>Samridhi Connect Privilege Card Details</a>
                </Link>
                <Link  className="sectionTag" to={"/dashboard/doctorNetworkReport/"+this.props.match.params.groupHospitalId}>
                  <a>Doctor Network Report</a>
                </Link>
                <Link  className="sectionTag" to={"/dashboard/patientTransactionReport/"+this.props.match.params.groupHospitalId}>
                  <a>Patient Transaction Report</a>
                </Link>
                </div>
            </div>
          </div>
        </section>
      </Suspense>
      <Suspense fallback={<div></div>}>
        <section>
        {folderName!==null&&
         <Footer />
        }
        </section>
      </Suspense>
    </div>
  );
}
}
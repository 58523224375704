import React, { Component } from "react";
import $ from "jquery";
import { apiCalling } from "../apiService";
export default class footerPopup extends Component {
  constructor(props){
    super(props);
    this.state={
      footerLinkId:this.props.details.footerLinkId,
      footerName:this.props.details.linkName,
      footerLink:this.props.details.url,
      sortOrder2:''
    }
   this.handleInputChange=this.handleInputChange.bind(this);
   this.saveFooterData=this.saveFooterData.bind(this);
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
   
   
    // $(".popup-hide").click(function () {
    //   $(".popup-overlay").hide();
    // });
  }

  saveFooterData = () =>{
    const saveApi = {
      method: "POST",
      body: JSON.stringify({
          functionName: "saveFooterLinks",
          groupHospitalAdminId:window.localStorage.getItem("groupHospitalAdminId"),
          siteId:window.localStorage.getItem("siteId"),
          footerLinkId:this.state.footerLinkId,
          content:"",
          isMedia:"1",
          isActive:"1",
          hospitalId:"",
          linkName:this.state.footerName,
          shareUrl:"",
          url:this.state.footerLink
  })
    }
    apiCalling(saveApi).then((data) => {
      if (data.success == "1") {
        alert("Saved successfully");
      } else {
        alert("update failed...")
      }
      this.props.footerDetailsVisible(false)
      window.location.reload(false);
    });
  }
  cancelPopup() {
    this.props.footerDetailsVisible(false)
  }

  render() {
    return (
      <div class="popup-overlay time-date-popup" id="footerPopup" style={{display:'block'}}>
        <div className="popup-contentDiv">
          <div className="modal-popup">
            <a className="close-popup popup-hide" onClick={() => this.cancelPopup()}>&times;</a>
            <div className="header border-0"> Footer </div>
            <div className="content">
              <div class="row row-margin">
                <label class="col-md-5 label-text">Footer Name</label>
                <div class="col-md-7"><input type="text" class="input-design" id="footerName" name="footerName" value={this.state.footerName}  onChange={this.handleInputChange}/></div>
              </div>
              <div class="row row-margin">
                <label class="col-md-5 label-text">Footer Link</label>
                <div class="col-md-7"><input type="text" class="input-design" id="footerLink" name="footerLink" value={this.state.footerLink}  onChange={this.handleInputChange}/></div>
              </div>
              {/* <div class="row row-margin">
                <label class="col-md-5 label-text">Sort Order</label>
                <div class="col-md-7">
                <select class="input-design"
                        id="sortOrder2"
                        name="sortOrder2"
                        value={this.state.sortOrder2}
                        onChange={this.handleInputChange}
                       >
                      <option>Select</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                </div>
              </div> */}

              <div className="text-center">
        <div className="divBtn" onClick={() => this.saveFooterData()}>Save</div>
                <input
                  type="button"
                  value="cancel"
                  className="formButtonBg cancelButtonBg mx-1 popup-hide"
                  onClick={() => this.cancelPopup()}></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component, Suspense } from "react";
import $ from "jquery";
import ReactTooltip from 'react-tooltip';
//const folderName = window.localStorage.getItem("homeName");
//const Header = React.lazy(() => import("../" + folderName + "/header"));
//const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class DrTermsConditions extends Component {

    
  render() {
    return (
        <div class="purpleWrap">
        {/* <Suspense fallback={<div class="loader"></div>}>
            <section>
                <Header /> */}
                <div class="container">
                    <div class="main-wrapper1 terms-wrapper">
                        <div class="terms-head">TERMS AND CONDITIONS</div>
                        <p class="terms-p-bold">General</p>
                        <p>Please read this Terms of Service policy and in order to use our products and services. By use of our products and services you acknowledge that you have read, understood and have irrevocably agreed to our following Terms of Service conditions and agree to be bound to our <span class="terms-p-bold">Terms of Service</span> by contractual terms, as well as to our <span class="terms-p-bold">Privacy Policy</span>.</p>
                        <p class="terms-p-bold">Definitions</p>
                        <p>“We”, “Our”, “Us” - refers to the technology services provider of this mobile app or website being, namely DoctorsCabin Health Technologies Pvt. Ltd. (“DoctorsCabin” or “Purple Health”) </p>
                        <p>“You”, “Your”, “User”, “Client” - refer to you, the Healthcare Practitioner and the User of the mobile app, website or services.</p>
                        <p>“Health Care Provider” or “HCP” “Provider” - refers to Doctors, Clinicians, Therapists or any other health and medical professional, expert, consultant or practitioner that may be using the service.</p>
                        <p>“Health Care Partner”, “Affiliate”, “Partner”, “Service Provider” - refer to associated partners that we may engage with or whose services that we use such as health care providers, clinics, hospitals, labs, pharmacies, pharmaceutical companies, insurance companies, associations, medical device & equipment providers, third party technology providers or any third party service providers that we may engage with in order to provide services for the User of the apps & website.</p>
                        <p>“Customer”, “End-User”, “Patient” - refers to people that connect with you through the service for the purpose of an appointment booking or telehealth consultation.</p>
                        <p>“Personal Information”, “Personally Identifiable Information”, “PII” - refer to information that you provide us that identifies you like: </p>
                        <ul>
                            <li> <p>Name, Phone Number, Home Address, Email address.</p> </li>
                            <li><p>Financial Information like Bank Accounts</p></li>
                        </ul>
                        <p>“Digital Health Platform”, “Platform” - refer to the mobile app or website and the underlying technology being used by users in order to access or engage with Health Care Partners and Health Care Providers who provide Digital Health Services.</p>
                        <p>“Digital Health Services”, “Health Services”, “Services” - Refers to the key functions offered by the Digital Health Platform eg. Booking an appointment with a doctor, Telehealth consultations, Sending of Prescriptions, Creation of an Electronic Health Records and any other medical or health related service enabled by the Platform</p>
                        <p>“Telehealth”, “Telemedicine” - refers to remote consultation between a patient and a Health Care Provider (eg. a doctor) via phone-call, video-call, email, or text/message chat.</p>
                        <p class="terms-p-bold">What this Service is For</p>
                        <p>This service is designed to help end-users connect to healthcare providers, medical professionals & practitioners (eg.like doctors) in order to connect, book in-clinic appointments, engage in remote telehealth consultations (eg. video-consult, audio/phone consult or message-chat).  In addition, the platform and services will be used to facilitate engagement between healthcare practitioners, healthcare providers, our partners and affiliates. </p>
                        <p class="terms-p-bold">We encourage you to let your patients know that this service is not a substitute for an in-person appointment.</p>
                        <p class="terms-p-bold">Please note that this service should not be used for medical emergencies. If there is an immediate medical issue, patients and end-users should be encouraged by you to go in-person to a proper and accredited health care provider.</p>
                        <p class="terms-p-bold">In addition, in order to use any of our services or to engage with a medical professional, users must be 18+ Years of age and Citizens of India.</p>
                        <p class="terms-p-bold">We DO NOT Endorse any advice, consultation or treatment suggestion</p>
                        <p>We are a technology provider who provides and facilitates the engagement between end-users and healthcare providers and healthcare practitioners. Any advice or recommendation given by you is not endorsed by us in any capacity. You acknowledge understanding and agreeing to this fact and you agree that we or our partners or affiliates have no liability to you in any manner or capacity for your use of our services, regardless of outcomes to end-users or patients. We disclaim all liability in this regard.</p>
                        <p class="terms-p-bold">We Provide Information Without Recommendation Or Endorsement</p>
                        <p>Our services and platform may provide information in the form of articles, videos, graphics or links to other websites and content. Some of the content may be provided by healthcare professionals. We do not endorse or recommend any information, tests, procedures, as viewed on our platforms, services, websites or apps. </p>
                        <ul>
                            <li><p>The Information and Services that we provide or that of our partners, affiliates, associated service providers or licensors are provided on an "as is" and “as available’ basis, and without any warranties or conditions (express or implied, including the implied warranties of merchantability, accuracy, or fitness for a particular purpose). To the fullest extent of the law possible, we shall in no event be liable to you or any party or end-user in regards to any actions taken by you based on any information found on our platform or from services provided us, other users or third parties or partners. As well, the use of any information found on this platform or service to verify credentials of other healthcare providers or practitioners is not approved by us and is prohibited. </p></li>
                            <li><p>We do not endorse or recommend any information on third party websites or apps that may be linked to from our service. </p></li>
                        </ul>
                        <p class="terms-p-bold">Unapproved Uses of Our Service</p>
                        <p>You agree to not copy our information, content, details of our partners, healthcare providers, details of healthcare professionals whether logged in or logged out of our service, through manual or automated means.</p>
                        <p>In general you agree to not use our services in a manner other than what it is intended for - namely for you as a health care professionals to connect with patients, end-users, our partners and affiliates .</p>
                        <p class="terms-p-bold">Limitations of Liability & Indemnification</p>
                        <p>We are not liable for any negative consequence or outcome through the use of our services or products. As mentioned, we are not medical providers or healthcare experts, we provide technology that facilitates your own efforts to engage with patients and end-users. We disclaim any liabilities as to the consequences of your use of the platform or services in terms of the advice given.</p>
                        <p>You agree to not include us or our partners & affiliates in any legal action in relation to your actions on our services or for any advice that you have given or for any health related action or medical procedure that you have suggested to end-users or patients.</p>
                        <p>You agree that we are not liable in any manner for any monetary or other damages for the following occurrences and any related consequences:</p>
                        <ul>
                            <li><p>Any issues that you may have interacting with end-users or patients.</p></li>
                            <li><p>Any medical or health outcomes for which end-users or patients may be unhappy with</p></li>
                            <li><p>Any perceived ability or inability of you by end-users or patients.</p></li>
                            <li><p>Any wrong prescription, medication or treatment that you give to end-users or patients.</p></li>
                            <li><p>Inappropriate comments or engagement by you, the healthcare practitioner or healthcare provider, to end-users or patients.</p></li>
                            <li><p>Failure of you as a healthcare provider or healthcare practitioner to provide treatments, prescriptions or healthcare services</p></li>
                            <li><p>Inappropriate behaviour by you as a healthcare provider or healthcare practitioner or your related staff.</p></li>
                            <li><p>Cancellations, re-schedules or no-shows by end-users or patients.</p></li>
                            <li><p>User generated content by end-users or patients such as ratings or reviews.</p></li>
                        </ul>
                        <p>You agree to indemnify DoctorsCabin Health Technologies Pvt Ltd for any legal liability that results from a patient or customer who is unsatisfied as an outcome of their consultation with you or your organization who use the software and services due to dishonesty, fraud, illegal actions, misrepresentations or criminality.</p>
                        <p>You acknowledge and agree that DoctorsCabin Health Technologies Pvt Ltd and its affiliates, employees and partners are only providing software and are not responsible or liable in any way for any negative outcomes to patients or clients. Furthermore, you agree to follow all legalities as well as medical or professional standards, protocols or recommendations when performing any consultations. You agree that DoctorsCabin Health Technologies Private Limited is not in any way liable for any breach of these professional or legal standards by you or your organization.</p>
                        <p>As a health and/or medical services provider or practitioner, you acknowledge that any credentials and information provided to DoctorsCabin or to end-users or patients is accurate and that you or your clinic or organization are qualified legally to perform the duties that you represent in terms of medicine, wellness, health care or other health services that you are offering. You attest that you are in good standing with all relevant credentialing bodies and that you are legally licensed to practice and offer your health and or medical services. As a healthcare practitioner or provider, should you not be legally qualified or not credentialed or in good standing with the appropriate medical or health body, you agree to not use our technologies or services to connect with patients.</p>
                        <p>You agree that the maximum liability that we have is the amount that an end-user or patient may have paid for an appointment directly through our platform for services or Rs. 1000 at a maximum.</p>
                        <p class="terms-p-bold">You Are Responsible For Your Account</p>
                        <ul>
                            <li><p>You agree to keep your login, password and account details private at all times. You agree that we are not responsible for any hacking or breach of your account due to any sharing of your account or password information. You must keep your information private at all times. </p></li>
                            <li><p>Furthermore, you must not use someone else’s credentials and access their account.</p></li>
                            <li><p>If you suspect that someone else has your credentials, account information and is using your account, you must contact us immediately and inform us. Our email is support@purplehealth.com.</p></li>
                        </ul>
                        <p class="terms-p-bold">Your Data and Information that You Provide</p>
                        <p class="terms-p-bold">Please read our Privacy Policy for more details on how we handle your data.</p>
                        <ul>
                            <li><p>Note that we do not sell your personal data or personal identifying data of you or end-users to anyone.</p></li>
                            <li><p>You do agree to let us use your data in order to facilitate booking an appointment, or for telehealth consultations or for appointment rescheduling or cancellation by end-users or patients or any other affiliate, partner or service provider that you engage with on the platform or service.</p></li>
                            <li><p>You understand and acknowledge that we have no control over or have any liability as it relates to personal information or personal health information that you, as a healthcare provider or healthcare practitioner, receive from end-users or patients using our platform and services.</p></li>
                            <li><p>End-users or patients may voluntarily choose to use services such as uploading a personal health record to the platform or wish to receive health records such as prescriptions from you, the healthcare provider. This information can be sent through email, SMS and similar means whose details you provide us. Should you provide a wrong email or phone number to which this information is sent, we are not liable in any way for the wrong party receiving this information. It is up to you to ensure that the information that you provide is 100% accurate.</p></li>
                            <li><p>In addition, should you change your number or give it up, you must update your information with us. You agree that we are not responsible for any issues or problems arising with you not informing us of any changes in your contact details and updating your information on the platform.</p></li>
                        </ul>
                        <p class="terms-p-bold">Suspension or Termination of Account</p>
                        <p>We reserve the right to suspend or cancel an account for any reason, including:</p>
                        <ul>
                            <li><p>Harassment of others including end-users or patients other healthcare providers and healthcare practitioners</p></li>
                            <li><p>Accepting appointments and not attending the appointment after booking</p></li>
                            <li><p>Sending purposely misleading information to other partner healthcare providers or practitioners</p></li>
                            <li><p>Using the system to surveil our partners</p></li>
                            <li><p>Copying our Intellectual Property</p></li>
                            <li><p>Scraping data from our services</p></li>
                            <li><p>Using an account that does not belong to you.</p></li>
                            <li><p>Trying to get credentials of accounts that do not belong to you.</p></li>
                            <li><p>Any other reason that we see fit.</p></li>
                        </ul>
                        <p>Should you wish to delete your account please email us at <a href="mailTo:support@purplehealth.com">support@purplehealth.com</a>.</p>
                        <p class="terms-p-bold">Notifications and Communications</p>
                        <ul>
                            <li><p>In order to remind you of appointments, booking confirmations, telehealth consultations, cancelled appointments or for any reason related to our services, you agree to allow us to send you notices and information by SMS, in-app mobile push notifications, browser notifications, messages, emails or phone calls. You may manage these notifications as appropriate in any available settings in our services related to notifications or in your mobile phone or computer device & browser settings.</p></li>
                            <li><p>Should you opt out of receiving notifications, you acknowledge that you may miss important notifications such as reminders for events. In addition, should you opt out of notifications and communications, the services may not work properly or may not work at all.</p></li>
                            <li><p>You also agree that we may send you information via email, sms, in-app push-notification as it relates to end-users or patients information or for relevant services which you have voluntarily expressly opted into and consented to allow. Should these communications be intercepted or viewed by someone else, while unlikely, you agree to release us from any liability arising from any interception or unauthorized access.</p></li>
                        </ul>
                        <p class="terms-p-bold">Ownership, Trademarks and Copyrights</p>
                        <ul>
                            <li><p>All of the software, content and intellectual property is owned by us or has been licensed by us. We give users such as yourself a limited license to use our services and access our content. This does not give any permanent access or re-sale or commercial rights to our content or services. You agree to not try to copy, sell or license our content or services or any of our intellectual property.</p></li>
                        </ul>
                        <p class="terms-p-bold">User Generated Content</p>
                        <p>There may be parts of our service or platform that allows for you or an end-user to generate content. In those instances:</p>
                        <ul>
                            <li><p>You agree that any generated content by you or end-users shall automatically be licensed to us free of charge and free of any legal, trademark or intellectual property encumbrances and free for us to use, should we wish to use it, at any time without any required payment of any sort and in any manner that we choose. This is a perpetual, worldwide license.</p></li>
                            <li><p>You also agree to not upload or save any trademarked content on our services or platforms and that we are not liable for any such misuse by you and that you indemnify us for any IP or trademarked content that is uploaded by you.</p></li>
                            <li><p>You agree that we are not liable for any of the content uploaded by other users. We express no warranties or recommend any such content, which has been stated in other parts of this Terms of Service.</p></li>
                            <li><p>You agree to not upload pornography, illegal content or any other content that may harm others, damage the service or platform in any way such as malicious code or anything similar.</p></li>
                            <li><p>You also agree that we are free to delete or modify any such content that is posted or uploaded by you.</p></li>
                        </ul>
                        <p class="terms-p-bold">Severability</p>
                        <p>If any part of the Agreement is held by a legal court, or legal arbitrator, to be unenforceable then that provision will be excluded but this shall not invalidate the rest of the agreement which shall remain and considered to be valid.</p>
                        <p class="terms-p-bold">We May Update This Agreement</p>
                        <p>You acknowledge, understand and agree that we may update these Terms of Service from time to time and that the latest Terms of Service will be in effect. We may require you to re-accept the Terms of Use from time to time if we feel that the changes have been substantial enough to warrant that effort.</p>
                        <p class="terms-p-bold">Jurisdiction and Disputes</p>
                        <p>This Agreement is governed by the Laws of India and any legal dispute shall be resolved in the State of Kerala in the City of Thiruvananthapuram.</p>
                        <p>The dispute resolution method shall be by arbitration for which we (or based on its approval a partner or affiliate) will appoint an arbitrator and the arbitration shall be conducted in English in accordance with the Arbitration and Conciliation Act, 1996.</p>
                    </div>
                </div>
                {/* <Footer />
            </section>
        </Suspense> */}
        </div>
    )
    }
}
import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
import { apiCalling, validateEmail } from "../apiService";
import $ from "jquery";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
const siteId= window.localStorage.getItem("siteId")
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPhone,faEnvelope,faMapMarkerAlt,faShareAlt } from '@fortawesome/free-solid-svg-icons'

export default class Grievance extends Component {
  submitGrievance(e) {
    e.preventDefault();
    if ($("#grievanceName").val() === "") {
      alert("Please enter your name");
      $("#grievanceName").focus();
      return false;
    } else if ($("#grievanceEmail").val() === "") {
      alert("Please enter your Email-Id");
      $("#grievanceEmail").focus();
      return false;
    } else if ($("#grievanceMobile").val() === "") {
      alert("Please enter Mobile Number");
      $("#grievanceMobile").focus();
      return false;
    }
    var emailValidate = validateEmail($("#grievanceEmail").val());
    // alert(emailValidate)
    if (emailValidate === false) {
      alert("Please enter valid Email-Id");
      $("#grievanceEmail").focus();
      return false;
    } else {
      const userFeedbackJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "userFeedback",
          mobileNumber: $("#grievanceMobile").val(),
          emailId: $("#grievanceEmail").val(),
          name: $("#grievanceName").val(),
          userQuery: $("#grievanceText").val(),
        }),
      };
      apiCalling(userFeedbackJson).then((data) => {
        if(data.success === "1"){
          const contactUsJson = {
            method: "POST",
            body: JSON.stringify({
              functionName: "contactUsSendMail",
              mobileNumber: $("#grievanceMobile").val(),
              emailId: $("#grievanceEmail").val(),
              name: $("#grievanceName").val(),
              siteId: siteId,
            }),
          };
          apiCalling(contactUsJson).then((data2) => {
            if (data2.success === "1") {
              alert(data.successMessage);
              window.location.reload();
            }
          });
        }else{
          alert("Something went wrong");
              window.location.reload();
        }
        
      });
    }
  }
  validateMobile() {
    let validate = /^[0-9]{0,10}$/;
    let a = $("#grievanceMobile").val().toString().slice(0, -1);
    if (!validate.test($("#grievanceMobile").val())) {
      $("#grievanceMobile").val(a);
      return false;
    } else {
      // $("#Mobile").val(a)
      return true;
    }
  }
  render() {
    return (
      <div className="main">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <main role="main">
          <div className="subpage_banner">
            <h1 className="subpage_bannerhead">Grievance</h1>
          </div>
          <div className="subPage_container">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 px-0">
                  <div className="quick_contact">
                    <div className="quick_container">
                      <div className="quick_left">
                        <i className="fa fa-phone"></i>
                      </div>
                      <div className="quick_right">
                        <h2 className="quick_heading">QUICK CONTACT</h2>
                        <p className="quick_paragraph">8078478278</p>
                      </div>
                    </div>
                    <div className="quick_container">
                      <div className="quick_left">
                        <i className="fa fa-envelope"></i>
                      </div>
                      <div className="quick_right">
                        <h2 className="quick_heading">SUPPORT</h2>
                        <p className="quick_paragraph">
                          support@imahospitals.com
                        </p>
                      </div>
                    </div>
                    <div className="quick_container">
                      <div className="quick_left">
                        <i className="fa fa-map-marker"></i>
                      </div>
                      <div className="quick_right">
                        <h2 className="quick_heading">ADDRESS</h2>
                        <p className="quick_paragraph">
                          IMA Kerala Headquarters
                          <br />
                          Anayara P.O.
                          <br />
                          Thiruvananthapuram - 695029
                        </p>
                      </div>
                    </div>
                    <div className="quick_container">
                      <div className="quick_left">
                        <i className="fa fa-share-alt"></i>
                      </div>
                      <div className="quick_right">
                        <h2 className="quick_heading">SOCIAL</h2>
                        <i className="fa fa-facebook-square"></i>
                        <i className="fa fa-twitter-square"></i>
                        <i className="fa fa-youtube-play"></i>
                        <i className="fa fa-instagram"></i>
                        <i className="fa fa-pinterest"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-0">
                  <div className="quick_form">
                    <input
                      id="grievanceName"
                      type="text"
                      className="form-control"
                      placeholder="Name"
                    />
                    <input
                      id="grievanceEmail"
                      type="email"
                      className="form-control"
                      placeholder="Email Id"
                    />
                    <input
                      id="grievanceMobile"
                      type="text"
                      className="form-control"
                      placeholder="Mobile Number"
                      onChange={() => this.validateMobile()}
                    />
                    <textarea
                      id="grievanceText"
                      className="form-control"
                      placeholder="Grievance"
                      rows="4"
                    ></textarea>
                    <div className="button_class text-right">
                      <button
                        type="button"
                        className="btn_common"
                        onClick={(e) => this.submitGrievance(e)}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

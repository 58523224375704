import React, { Component, Suspense } from "react";
import closered from "../image/theme/closered.png";
import $ from "jquery";
import { apiCalling } from "../apiService";
import "react-datepicker/dist/react-datepicker.css";
import htbaby from "../image/icons/ht-baby.png";
import { Link } from "react-router-dom";
import moment from "moment";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class HealthTrackbabymilestnsdwd extends Component {
  constructor(props) {
    super(props);

    this.state = {

      babyName: window.localStorage.getItem('babyName'),
      babyDOB: window.localStorage.getItem('dob'),
      healthScheduleData: [],

    };
  }

  componentDidMount() {

    this.babyDetails();

  }

  babyDetails() {
    const Listing = {
      method: 'POST',
      body: JSON.stringify({
        functionName: "getHealthSchedule",
        healthTrackerTypeId: "2",
        userId: "", //window.localStorage.getItem("userId"),
        dateOfBirth: this.state.babyDOB
      })
    }
    $(".loader").show();
    apiCalling(Listing).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        this.setState({
          healthScheduleData: data.healthScheduleData,
        });
      }
    })
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div ></div>}>
          <section>
            <div className='loader'></div>
            <Header />
            <div class="container container-auto">
              <div class="main-wrapper1">
                <div class="col-sm-12 form-head text-center">Baby Milestones</div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="health-track-card">
                      <div className="health-trck-img">
                        <img src={htbaby} />
                      </div>
                      <p>Baby Milestones</p>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="health-track-card-right">

                      <div class="form-section mt-2">
                        <div class="row">
                          <div class="col-lg-12 row pl-3 d-center">
                            <label class="col-12"> <div className="row pl-1">Name : {this.state.babyName} <br /> Date of Birth : {moment(this.state.babyDOB, 'DD/MM/YYYY').format("DD MMM YYYY")}</div></label>
                            <table>
                              <thead >
                                <tr>
                                  <th scope="col">Period</th>
                                  <th scope="col">Activity</th>
                                </tr>
                              </thead>
                              <tbody >
                                <div className="tbody babymilestne">
                                  {this.state.healthScheduleData.map((data) => {
                                    let fromPeriod = moment(data.fromPeriod, "MM").format("MMM");
                                    let toPeriod = moment(data.toPeriod, "MM").format("MMM");
                                    return (
                                      <tr>
                                        <td scope="row" data-label="Period">{fromPeriod=== 'Invalid date' ? 'Jan' : fromPeriod} - {toPeriod === 'Invalid date' ? 'Dec' : toPeriod} {data.yearData}</td>
                                        <td data-label="Activity">{data.vaccineName}</td>
                                      </tr>
                                    )
                                  })}
                                </div>
                              </tbody>
                            </table>
                            <div className="ht-dwnldbutton">
                              <Link to={{
                                pathname: "/health-trackers-babymilestone-download-pdf",
                              }}><input type="submit" class="formButton formButtonBold" value="Preview"></input>
                              </Link>
                            </div>
                            <div className="ht-terms">
                              <p>Source: To be mentioned.</p>
                              <p> Milestones may vary for each child.</p>
                              <p> This is only for guidance.</p>
                              <p> Please check with Doctor for suitable advice for your child</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

import React, { Component } from "react";
import $ from 'jquery'
export default class Centre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bouquetData: [],
      bouquetDataTitle: "",
    };
  }
  componentDidMount() {
    const Bouquet = JSON.parse(window.localStorage.getItem("Bouquet"));
    if (Bouquet) {
      //this.setState({ bouquetDataTitle: Bouquet[0].parentName });
      //this.setState({ bouquetData: Bouquet[0].sectionList });
    } else {
      // window.location.reload(false);
    }
  }
  editClick() {
    $("#excellenceItem").addClass('editActive');
  }
  cancelClick() {
    $("#excellenceItem").removeClass('editActive');
  }
  render() {
    return (
      <div className="excellenceSection" id="excellenceSection">
        <div className="mainHeading">Centers of Excellence</div>
        <div className="excellenceList">
          <div className="excellenceItem dashItemList redItem" id="excellenceItem">
            <span className="excellenceItemName">
              Reproductive <br />
              Medicine
            </span>
            <span className="excellenceCircle"></span>
          </div>
          <div className="excellenceItem blueItem">
            <span className="excellenceItemName">
              Obstetrics and <br />
              Gynaecology
            </span>
            <span className="excellenceCircle"></span>
          </div>
          <div className="excellenceItem greenUtem">
            <span className="excellenceItemName">
              Andrology <br />
              and Urology
            </span>
            <span className="excellenceCircle"></span>
          </div>
          <div className="excellenceItem purpleItem">
            <span className="excellenceItemName">Foetal Medicine</span>
            <span className="excellenceCircle"></span>
          </div>
          <div className="excellenceItem cyanItem">
            <span className="excellenceItemName">
              PCOS and <br />
              Obesity Clinic
            </span>
            <span className="excellenceCircle"></span>
          </div>
          <div className="excellenceItem pinkItem">
            <span className="excellenceItemName">
              Psychology and <br />
              Counselling
            </span>
            <span className="excellenceCircle"></span>
          </div>
          <div className="excellenceItem lavenderItem">
            <span className="excellenceItemName">
              Paediatrics and <br />
              Adolescent clinic
            </span>
            <span className="excellenceCircle"></span>
          </div>
          <div className="excellenceItem oliveItem">
            <span className="excellenceItemName">
              Fertility Enhancing
              <br /> Surgery <br />
              (Laparoscopy & Hysteroscopy)
            </span>
            <span className="excellenceCircle"></span>
          </div>
        </div>
        {/* <div className="excellenceSection" id="excellenceSection">
        <div className="mainHeading">{this.state.bouquetDataTitle}</div>
        <div className="excellenceList">
          {this.state.bouquetData.map((obj, index) => {
            return (
              <div className="excellenceItem redItem">
                <span>{obj.childName}</span>
                <span className="excellenceCircle"></span>
              </div>
            );
          })}
        </div>
        </div>*/}
      </div>
    );
  }
}

import React, { Component } from 'react';
import { apiCalling } from "../apiService";
import $ from "jquery";
const minOffset = 0;
const maxOffset = 60;
const thisYear = (new Date()).getFullYear();

export default class ProfessionalDetail extends Component {

  constructor(props){
      super(props);

    this.state={

      year:'',
      descriptionProff:'',
      thisYear: thisYear,
      selectedYear: [],
    }
    this.saveHandle=this.saveHandle.bind(this);
    
  }

  componentDidMount(){

    this.getProfessionalDetails()

}

getProfessionalDetails(){
  const getDetails = {
    method: "POST",
    body: JSON.stringify({
      functionName: "getDoctorDetails",
      siteId: window.localStorage.getItem("siteId"),
      doctorId: window.localStorage.getItem("userId"),
    }),
  }
  apiCalling(getDetails).then((data) => {
    if (data.success === "1") {        
      this.setState({ 
        selectedYear: data.doctorData[0].personalDetails[0].practiceStartYear,
        descriptionProff:data.doctorData[0].personalDetails[0].professionalStatement
      }); 
    }
  });

}

saveHandle(){
  let errormessage="";
  if($("#selectedYear").val() === "Year"){
    errormessage="Select Required Year"
    $("#selectedYear").focus();
  }else if($("#descriptionProff").val() === ""){
    errormessage="Please Enter Description"
    $("#descriptionProff").focus();
  }else{  
}
if(errormessage==""){
  const saveData = {
   method:'POST',
   body:JSON.stringify({
   functionName:"updateDoctorDetails",
   emailId:window.localStorage.getItem("emailId"),
   subFunctionName:"updateProfessionalDetails",
   siteId: window.localStorage.getItem("siteId"),
   doctorId: window.localStorage.getItem("userId"),
   practiceStartYear:$("#selectedYear").val(),
   ProfessionalStatement:this.state.descriptionProff
 })
}
//console.log("iiiiii",saveData)
$(".loader").show();
apiCalling(saveData).then((data) => {
//console.log("t=====",data)
$(".loader").hide();
if(data.success=="1"){
alert("Details updated successfully")
window.location.reload(false);
}
})
}
else{
  $("#messageProfessionalEdit").text(errormessage);

  $("#messageProfessionalEdit").css("color", "red");
    }
}

  changeHandler = (event) => {

    const{value, name} = event.target;
    this.setState({
      [name]:value,
    })
    }

   onHandleChange = (event) => {
    this.setState({ selectedYear: event.target.value });
    };
    
  render() {

    const {thisYear} = this.state;
    const Years = [];
    for (let i = minOffset; i <= maxOffset; i++) {
     const year = thisYear - i;
      Years.push(<option value={year}>{year}</option>);
      }
    return ( 
        <div class="row px-2">
        <div class="col-sm-12">
        <div class="col-sm-12 my-2">
            <p id="messageProfessionalEdit"></p>
            </div>
      </div>
          <div class="col-sm-6 px-0  px-sm-3">
              <label class="col-sm-12 label-text">Practice Start Year <span class="star-red">*</span></label>
              <div class="col-sm-12 mb-3  px-0  px-sm-3">
            <select class="input-design"
                  id="selectedYear"
                   name="selectedYear"
               value={this.state.selectedYear}
              onChange={this.onHandleChange}
               >
            <option>Year</option>
               {Years}
              </select>
              </div>
          </div>
          <div class="col-sm-12  px-0  px-sm-3">
              <label class="col-sm-12 label-text">Professional Statement</label>
              <div class="col-sm-12  px-0  px-sm-3">
                <textarea class="input-design textarea" style={{fontSize: "14px"}}id="descriptionProff" name="descriptionProff"   value={this.state.descriptionProff} onChange={this.changeHandler}></textarea>                                 
              </div>
          </div>
          
          <div className="col-sm-12">
                <input type="submit" value="Save" className="formButton" onClick={() => this.saveHandle()}></input>
            </div>
        </div>
    )
  }
}

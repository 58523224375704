import React, { Component, Suspense } from "react";
import Dashboardleftmenu from "./dashboardleftmenu";
import Home from "./analytics";
import $ from "jquery"
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class home extends Component {
  componentDidMount() {
    $(".analytics-menu").click(function(){
      alert();
    });
  }
  render() {

    return (
      <div className="purpleWrap analytics_dash">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>

          <div class="main-wrapper1">
            <div class="analytics-wrapper">
              <div class="analytics-sideMenu">
                <span className="analytics-menu" id="analytics-menu">
                  <span></span>
                </span>
                {/* <Dashboardleftmenu></Dashboardleftmenu> */}
              </div>
              <div class="analytics-right">
                  <Home/>
              </div>
            </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

import React, { Component, Suspense } from "react";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { apiCalling, apiUrlRelianceFunction } from "../apiService";
import moment from "moment";
import Pagination from "react-js-pagination";
import csv from "../image/icons/csv.png";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class textChatReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentData: [],
      toDate: new Date(),
      fromDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      totalCount: 0,
      activePage: 1,
      offset: "0",
      fuctionName: "TextChatPayment Report",
      csvDetails: [],
      showMessage: "Waiting for data... ",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.textChatReport("0");
  }

  handleChange = (date) => {
    this.setState({
      fromDate: date,
    });
  };
  handleChangeTo = (date) => {
    this.setState({
      toDate: date,
    });
  };

  changeHandler = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handlePageChange(pageNumber) {
    window.scrollTo(0, 0);
    this.setState({ activePage: pageNumber });
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
        offset: offset,
      });
    } else {
      offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
        offset: offset,
      });
    }
    this.textChatReport(offset.toString());
    // const apiJson = {
    //   method: "POST",
    //   body: JSON.stringify({
    //     functionName: "paymentReport",
    //     subFunctionName: "textChatPayment",
    //     userId: window.localStorage.getItem("userId"), //window.localStorage.getItem("groupHospitalAdminId"), //"19238",
    //     role: window.localStorage.getItem("role"),
    //     max: max,
    //     offset: JSON.stringify(offset),
    //     cmbCategory1:
    //       $("#textChatSelect").val() === "0" ? "" : $("#textChatSelect").val(),
    //     searchKeyWord: $("#txtTextChat").val(),
    //     exportFromDate: $("#fromDate").val(),
    //     exportToDate: $("#toDate").val(),
    //     fromDate: $("#fromDate").val(),
    //     toDate: $("#toDate").val(),
    //   }),
    // };

    // $(".loader").show();
    // let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction;
    // apiFunction(apiJson).then((data) => {
    //   if (data.success === "1") {
    //     this.setState({
    //       paymentData: data.result.paymentData,
    //       totalCount: data.result.count,
    //     });
    //     $(".loader").hide();
    //   }
    // });
  }

  textChatReport(offset) {
    // var today = new Date();
    // var firstDayOfPreviousMonth = new Date(
    //   today.getFullYear(),
    //   today.getMonth() - 1,
    //   1
    // );
    // var toDate = moment().toDate(); // convert to Date object
    // var startDateMilliseconde = firstDayOfPreviousMonth.getTime();
    // var toDateMilliseconde = toDate.getTime();

    // this.setState({
    //   fromDate: startDateMilliseconde,
    //   toDate: toDateMilliseconde,
    // });
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "paymentReport",
        subFunctionName: "textChatPayment",
        userId: window.localStorage.getItem("userId"), //window.localStorage.getItem("groupHospitalAdminId"), //"19238",
        role: window.localStorage.getItem("role"),
        max: "10",
        offset: offset,
        exportFromDate: this.state.fromDate,
        exportToDate: this.state.toDate,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        cmbCategory1:
          $("#textChatSelect").val() === "0" ? "" : $("#textChatSelect").val(),
        searchKeyWord: $("#txtTextChat").val(),
      }),
    };
    let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction;
    apiFunction(apiJson).then((data) => {
      if (data.success === "1") {
        // console.log(
        //   "🚀 ~ file: textChatReports.jsx:149 ~ textChatReports ~ apiFunction ~ data.result.paymentData:",
        //   data.result.paymentData
        // );
        this.setState(
          {
            paymentData: data.result.paymentData,
            csvDetails: data.result.paymentExportData,
            totalCount: data.result.count,
          },
          () => $(".loader").hide()
        );
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          paymentData: [],
          csvDetails: [],
          showMessage: data.errorMessage,
        });
      }
    });
  }

  changeText = () => {
    if ($("#textChatSelect").val() !== "0") {
      document.getElementById("txtTextChat").disabled = false;
      $("input[name=txtTextChat]").focus();
    } else {
      document.getElementById("txtTextChat").disabled = true;
    }
    document.getElementById("txtTextChat").value = "";
  };

  searchHandler() {
    var startDate = new Date($("#fromDate").val());
    var endDate = new Date($("#toDate").val());
    if ($("#textChatSelect").val() !== "0" && $("#txtTextChat").val() === "") {
      var t = document.getElementById("textChatSelect");
      var selectedText = t.options[t.selectedIndex].text;
      alert(selectedText + " Required");
      $("#txtTextChat").focus();
      return false;
    }
    if (startDate > endDate) {
      alert("To date should be greater than or equal to from date");
      return false;
    } else {
      this.textChatReport("0");
      // const searchData = {
      //   method: "POST",
      //   body: JSON.stringify({
      //     functionName: "paymentReport",
      //     subFunctionName: "textChatPayment",
      //     userId: window.localStorage.getItem("userId"), //window.localStorage.getItem("groupHospitalAdminId"),  //"19238",
      //     role: window.localStorage.getItem("role"),
      //     max: "10",
      //     offset: "0",
      //     cmbCategory1:
      //       $("#textChatSelect").val() === "0"
      //         ? ""
      //         : $("#textChatSelect").val(),
      //     searchKeyWord: $("#txtTextChat").val(),
      //     exportFromDate: $("#fromDate").val(),
      //     exportToDate: $("#toDate").val(),
      //     fromDate: $("#fromDate").val(),
      //     toDate: $("#toDate").val(),
      //   }),
      // };
      // console.log(
      //   "🚀 ~ file: textChatReports.jsx:203 ~ textChatReports ~ searchHandler ~ searchData:",
      //   searchData.body
      // );
      // $(".loader").show();
      // let apiFunction =
      //   folderName !== null ? apiCalling : apiUrlRelianceFunction;
      // apiFunction(searchData).then((data) => {
      //   console.log(
      //     "🚀 ~ file: textChatReports.jsx:221 ~ textChatReports ~ apiFunction ~ data:",
      //     data
      //   );
      //   if (data.success == "1") {
      //     $(".loader").hide();
      //     this.setState({
      //       paymentData: data.result.paymentData,
      //       csvDetails: data.result.paymentExportData,
      //       totalCount: data.result.count,
      //       activePage: 1,
      //     });
      //   }
      //   if (data.success === "0") {
      //     $(".loader").hide();
      //     this.setState({
      //       paymentData: [],
      //       csvDetails: [],
      //       showMessage: data.errorMessage,
      //     });
      //   }
      // });
    }
  }

  csvFileDownload() {
    const csvRow = [];
    var columnDetails = [
      [
        "Patient%20Name",
        "Patient%20Email%20Id",
        "Patient%20Mobile%20Number",
        "Hospital%20Name",
        "Doctor%20Name",
        "Chat%20Requested Date",
        "Transaction%20Id",
        "Appointment%20Status  ",
        "Coupon%20Amount",
        "Doctor%20Charge",
        "Amount%20Received",
      ],
    ];
    var results = this.state.csvDetails;
    for (var item = 0; item < results.length; item++) {
      let testStatus = "";
      if (
        (results[item].doctorReply === true ||
          results[item].doctorReply == 1) &&
        results[item].isComplete === 1
      ) {
        testStatus = "Completed%20-%20Doctor%20Replied";
      } else if (
        (results[item].doctorReply === false ||
          results[item].doctorReply == 0) &&
        results[item].isComplete === 0
      ) {
        testStatus = "Active%20-%20Doctor%20Didn't%20Reply";
      } else if (
        (results[item].doctorReply === false ||
          results[item].doctorReply == 0) &&
        results[item].isComplete === 1
      ) {
        testStatus = "Expired%20-%20Doctor%20Didn't%20Reply";
      } else if (
        (results[item].doctorReply === true ||
          results[item].doctorReply == 1) &&
        results[item].isComplete === 0
      ) {
        testStatus = "Active%20-%20Doctor%20Replied";
      }

      columnDetails.push([
        results[item].memName.replaceAll(" ", "%20"),
        results[item].emailId.replaceAll(" ", "%20"),
        results[item].mobNo.replaceAll(" ", "%20"),
        results[item].hospitalName.replaceAll(" ", "%20"),
        results[item].docName.replaceAll(" ", "%20"),
        results[item].requestedDate,
        results[item].transactionId,
        testStatus,
        results[item].promoAmt,
        results[item].actualAmount,
        results[item].amount,
      ]);
      // console.log("columnDetails", columnDetails);
    }

    for (var i = 0; i < columnDetails.length; ++i) {
      csvRow.push(columnDetails[i].join(","));
    }

    var csvString = csvRow.join("%0A");

    var dataType = document.createElement("a");

    dataType.href = "data:attachment/csv," + csvString;
    dataType.target = "_Blank";
    dataType.download = this.state.fuctionName + ".csv";
    document.body.appendChild(dataType);
    dataType.click();
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            {folderName !== null && <Header />}
            <div className="loader"></div>
            <div class="container">
              <div class="main-wrapper1">
                <div class="col-md-12 p-0">
                  <ul class="breadcrumb">
                    <li>
                      <a
                        href={
                          "/dashboard/reportDashboard/" +
                          this.props.match.params.groupHospitalId
                        }
                      >
                        Reports
                      </a>
                      <i class="fa fa-chevron-right"></i>
                    </li>
                    <li> Text Chat Payment Report</li>
                  </ul>
                </div>
                <div class="form-head"> Text Chat Payment Report </div>
                <div class="custom-search row">
                  <div class="col-md-4 col-6 mb-3 custom-search-sub">
                    <select
                      class="input-design"
                      id="textChatSelect"
                      name="textChatSelect"
                      onChange={() => this.changeText()}
                    >
                      <option value="0">- - Select - -</option>
                      <option value="Patient Name">Patient Name</option>
                      <option value="Patient Mobile Number">
                        Patient Mobile Number
                      </option>
                      <option value="Patient EmailId">Patient Email Id</option>
                      <option value="Doctor Name"> Doctor Name</option>
                      <option value="Hospital Name">Hospital Name</option>
                      <option value="Transaction Id"> Transaction ID</option>
                      {/* <option value="Status">Appointment Status</option> */}
                    </select>
                  </div>
                  <div class="col-md-4 col-6 mb-3 custom-search-sub">
                    <input
                      type="text"
                      class="input-design"
                      id="txtTextChat"
                      name="txtTextChat"
                      disabled
                    />
                  </div>
                  <div class="col-md-4 col-6 mb-3 custom-search-sub">
                    <DatePicker
                      placeholderText="From Date"
                      id="fromDate"
                      name="fromDate"
                      selected={this.state.fromDate}
                      onChange={this.handleChange}
                      dateFormat="yyyy/MM/dd"
                      calendarClassName="react-date-picker"
                    />
                  </div>
                  <div class="col-md-4 col-6 mb-3 custom-search-sub">
                    <DatePicker
                      placeholderText="To Date"
                      id="toDate"
                      name="toDate"
                      selected={this.state.toDate}
                      onChange={this.handleChangeTo}
                      dateFormat="yyyy/MM/dd"
                      calendarClassName="react-date-picker"
                    />
                  </div>
                  <div class="col-md-3 col-10 mb-3 custom-search-sub">
                    <input
                      type="submit"
                      value="Search"
                      class="formButton m-0"
                      onClick={() => this.searchHandler()}
                    />
                  </div>
                  <div className="col-md-1 col-2 mb-3 custom-search-sub p-0">
                    <a
                      onClick={() => this.csvFileDownload()}
                      className="cursor-pointer"
                    >
                      <img
                        src={csv}
                        alt="csv"
                        width="25"
                        title="Export CSV"
                        className="mt-1"
                      />
                    </a>
                  </div>
                </div>
                {this.state.paymentData.length > 0 ? (
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Patient Name</th>
                          <th>Patient Email Id</th>
                          <th>Patient Mobile No.</th>
                          <th>Hospital Name</th>
                          <th>Doctor Name</th>
                          <th>Text Chat Requested Date</th>
                          <th>Transaction Id </th>
                          <th>Appointment Status</th>
                          <th>Coupon Amount</th>
                          <th>Doctor Charge</th>
                          <th>Amount Received</th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "12px" }}>
                        {this.state.paymentData.map((data) => {
                          let testStatus = "";
                          if (
                            (data.doctorReply === true ||
                              data.doctorReply == 1) &&
                            data.isComplete === 1
                          ) {
                            testStatus = "Completed - Doctor Replied";
                          } else if (
                            (data.doctorReply === false ||
                              data.doctorReply == 0) &&
                            data.isComplete === 0
                          ) {
                            testStatus = "Active - Doctor Didn't Reply";
                          } else if (
                            (data.doctorReply === false ||
                              data.doctorReply == 0) &&
                            data.isComplete === 1
                          ) {
                            testStatus = "Expired - Doctor Didn't Reply";
                          } else if (
                            (data.doctorReply === true ||
                              data.doctorReply == 1) &&
                            data.isComplete === 0
                          ) {
                            testStatus = "Active - Doctor Replied";
                          }
                          //   let requestedDate = moment(data.requestedDate).format("DD-MMM-YYYY");
                          return (
                            <tr>
                              <td>{data.memName}</td>
                              <td>{data.emailId}</td>
                              <td>{data.mobNo}</td>
                              <td>{data.hospitalName}</td>
                              <td>Dr.{data.docName}</td>
                              <td>{data.requestedDate}</td>
                              <td>{data.transactionId}</td>
                              <td>{testStatus}</td>
                              <td>₹ {data.promoAmt}</td>
                              <td>₹ {data.actualAmount}</td>
                              <td>₹ {data.amount}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {this.state.totalCount > 5 ? (
                      <div className="paginationSection orderm-paginationSection">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalCount}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <p className="error-msg text-center mb-0">
                    {" "}
                    {this.state.showMessage}{" "}
                  </p>
                )}
              </div>
            </div>
            {folderName !== null && <Footer />}
          </section>
        </Suspense>
      </div>
    );
  }
}

import React, { Component, Suspense } from "react";

const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
const siteId = window.localStorage.getItem("siteId");

export default class About extends Component {
  render() {
    return (
      <div className="main">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <main role="main">
          <div className="subpage_banner">
            <h1 className="subpage_bannerhead">About</h1>
          </div>
          <div className="subpage">
            <div className="container">
              <div className="row">
                <div className="column text-column col-md-12 col-sm-12 col-xs-12">
                  <div className="sec-title">
                    <h2 className="welcome-ima">Welcome to IMA Kerala</h2>
                  </div>
                  <div className="inner-box">
                    <div className="text">
                      <p className="welcome-ima-text">
                        <strong>
                          IMAHospitals.com is brought to you by the Indian
                          Medical Association, IMAhospitals.com is a network of
                          hospitals that provides high-quality and affordable
                          care for patients across multiple specialities and
                          medical services. Patients can now access a trusted
                          IMA hospital in the network and know that their care
                          will be referenced to their parent doctor. A first of
                          its kind in the world, IMAhospitals.com is proud to be
                          a leader in personalised digital healthcare in India.
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

import React, { Component } from "react";
import { apiCalling, signupValidation } from "../apiService";
import $ from "jquery";
import jwt from "jsonwebtoken";
import { jwtSecret } from "../config";
import { isWindows } from "react-device-detect";
export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    //this.handleNumberChange = this.handleNumberChange.bind(this);
  }

  handleNumberChange(event) {
    var mobiNo = $("#mobileNo").val();
    if (/\D/g.test(mobiNo)) {
      // this.value = this.value.replace(/\D/g, '')
      $("#mobileNo").val(mobiNo.replace(/\D/g, ""));
    }
  }
  chatmessageClickValidate() {
    $(".loader").show();
    const jsonData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "messageChatFree",
        chatDoctorPanelId: window.localStorage.getItem("chatDoctorPanelId"),
        memberId: window.localStorage.getItem("userId"),
        doctorId: window.localStorage.getItem("appDocId"),
        hospitalId: window.localStorage.getItem("appHosId"),
      }),
    };
   // console.log(jsonData.body);
    apiCalling(jsonData).then((data) => {
      if (data.success === "1") {
        window.localStorage.setItem("API_KEY", data.API_KEY);
        window.localStorage.setItem("sessionId", data.sessionId);
        window.localStorage.setItem("token", data.token);
        window.localStorage.setItem("appDocId", window.localStorage.getItem("appDocId"));
        window.localStorage.setItem("appHosId", window.localStorage.getItem("appHosId"));
        window.localStorage.setItem("chatDoctorPanelId", data.chatGroupId); //chatDoctorPanelId);
        window.location.href = "/chat/chatmessage";
      } else {
        this.chatmessageClickValidate();
       // console.log("Error: API : messageChatFree");
      }
    });
  }
  componentDidMount() {
    $("#firstName").focus();
    $("#signupOTP").hide();
    let self = this;
    $("#signIn").click(function () {
      $("#ForgotPasswordWrap").hide();
      $("#SignupWrap").hide();
      $("#signInWrapDiv").show();
    });

    $("#registerId").click(function () {
      // var textCaptcha = $("#textCaptcha").val();
      // var hiddenCaptcha = $("#hiddenCaptcha").val();
      var firstName = $("#firstName").val();
      // var lastName = $("#lastName").val();
      var emailId = $("#emailId").val();
      var mobileNo = $("#mobileNo").val();
      var password = $("#password").val();
      var cpassword = $("#cpassword").val();

      let validate = signupValidation(
        firstName,
        // lastName,
        emailId,
        mobileNo,
        password,
        cpassword
      );
      // alert(validate);
      $("#" + validate[1]).focus();
      $("#errorMessage").text(validate[0]);
      if (!validate[0]) {
        const token = jwt.sign(
          {
            firstName: firstName,
            lastName: "",
            mobileNumber: mobileNo,
            emailId: emailId,
            password: password,
          },
          jwtSecret()
        );
        const requestData = {
          method: "POST",
          body: JSON.stringify({
            functionName: "signUp",
            token: token,
            siteId: window.localStorage.getItem("siteId"),
            otp: "",
          }),
        };

        apiCalling(requestData).then((data) => {
          if (data.success === "1") {
            $("#signupform").hide();
            $("#signupOTP").show();
            $('input[name=signupOTPTxt]').focus();
            $("#errorMessageOtp").text(data.successMessage);
          } else {
            $("#signupform").show();
            $('input[name=signupOTPTxt]').focus();
            $("#signupOTP").hide();
            $("#errorMessage").text(data.errorMessage);
            
          }
        });
      } //if validate
    });

    $("#signUpId").click(function () {
      $(".loader").show();
      var firstName = $("#firstName").val();
      // var lastName = $("#lastName").val();
      var emailId = $("#emailId").val();
      var mobileNo = $("#mobileNo").val();
      var password = $("#password").val();
      var signupOTP = $("#signupOTPTxt").val();
      if (!signupOTP) {
        $(".loader").hide();
        alert("OTP Required");
      } else {
        // alert("else");
        const token = jwt.sign(
          {
            firstName: firstName,
            lastName: "",
            mobileNumber: mobileNo,
            emailId: emailId,
            password: password,
          },
          jwtSecret()
        );
        const requestData = {
          method: "POST",
          body: JSON.stringify({
            functionName: "signUp",
            token: token,
            siteId: window.localStorage.getItem("siteId"),
            otp: signupOTP,
          }),
        };
       // console.log(">>>>>>>>>>>>>>>>>>>" + requestData.body);
        //$("#errorMessageOtp").text(requestData.body);
        apiCalling(requestData).then((data) => {
          $(".loader").hide();
         // console.log("Res" + data);
          if (data.success === "1") {
            $("#errorMessageOtp").text(data.successMessage);
            alert(data.successMessage);
            $("#SignupWrap").hide();
            if (data.userData) {
              window.localStorage.setItem("userId", data.userData[0].userId);
              window.localStorage.setItem("emailId", data.userData[0].emailId);
              window.localStorage.setItem("userName", data.userData[0].userName);
              window.localStorage.setItem("isLogin", "true");
              $("#showMyName").text(data.userData[0].userName);
              window.localStorage.setItem(
                "mobileNumber",
                data.userData[0].mobileNumber
              );
              window.localStorage.setItem("role", data.userData[0].role);

              if (data.userData[0].role === "ROLE_DOCTOR") {
                $(".doctorMenuTop").show();
                window.location.href = "/doctor/dashboard";
              }
              if (data.userData[0].role === "ROLE_USER") {
                $(".patientMenuTop").show();
              }
              setTimeout(() => {
                $(".signInWrap").hide();
                $(".commonButton ").hide();
                $("#signInWrap").hide();
                $("#loginErrorMsg").text("");
              }, 2000);
              if (window.localStorage.getItem("loginValidate" === "book")) {
                window.localStorage.setItem("loginValidate", "");
                window.location.href = "/patient/bookappointment";
              }
              if (window.localStorage.getItem("loginValidate") === "TextChatPay") {
                window.localStorage.setItem("loginValidate", "");
                window.location.href = "/patient/textchatpayment";
              }
              if (window.localStorage.getItem("loginValidate") === "TextChatFree") {
                window.localStorage.setItem("loginValidate", "");
                self.chatmessageClickValidate();
              }
            }
          } else {
            $("#errorMessageOtp").text(data.successMessage);
          }
        });
      }
    });
    $(".popup-login-hide").click(function () {
      $(".signInWrap").hide();
      $("#firstName").val("");
      $("#mobileNo").val("");
      $("#emailId").val("");
      $("#password").val("");
      $("#cpassword").val("");
      $("#errorMessage").empty();
      // window.location.reload(false);
    });
    // $(".popup-overlay").click(function () {
    //   $(".signInWrap").hide();
    // });

    $(".popup-content").click(function (event) {
      event.stopPropagation();
    });
  }
  render() {
    return (
      <div className="popup-overlay ">
        <div className="popup-content">
          <div className="modal-popup">
            <a className="close-popup popup-login-hide">&times;</a>
            <form id="signupform">
              <div className="signInHead">
                <h2>Sign Up</h2>
                {/* <p>Create your USER ID!</p> */}
                <p id="errorMessage" className="error-msg"></p>
              </div>
              <div className="signInBody">
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  className="input-design my-2"
                  placeholder="Name"
                  required
                  tabIndex="1"
                />
                {/* <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  className="input-design my-2"
                  placeholder="Last Name"
                  required
                /> */}
                <input
                  type="email"
                  id="emailId"
                  className="input-design my-2"
                  placeholder="Email Id"
                  required
                  tabIndex="2"
                />
                <input
                  type="text"
                  className="input-design my-2"
                  placeholder="Mobile No."
                  name="mobileNo"
                  id="mobileNo"
                  // onChange={this.handleNumberChange}
                  onChange={(e) => {
                    this.handleNumberChange(this.value);
                  }}
                  required
                  tabIndex="3"
                />
                <input
                  type="password"
                  className="input-design my-2"
                  placeholder="Password"
                  name="password"
                  id="password"
                  required
                  tabIndex="4"
                />
                <input
                  type="password"
                  className="input-design my-2"
                  placeholder="Confirm password"
                  name="cpassword"
                  id="cpassword"
                  required
                  tabIndex="5"
                />
                {/* <div className="row my-2">
                  <div className="col-md-7">
                    <input
                      type="text"
                      placeholder="Type Captcha"
                      className="input-design"
                      id="textCaptcha"
                      name="textCaptcha"
                    />
                  </div>
                  <div className="col-md-5 text-center text-md-left mt-md-1 mt-2 pl-0">
                    <span className="captchaOverText" id="mainCaptcha"></span>
                    <input type="hidden" id="hiddenCaptcha" value="" />
                    <i className="fa fa-refresh ml-3" id="refreshCapcha"></i>
                  </div>
                </div> */}
                <div className="signInButton mt-2">
                  <input
                    type="button"
                    value="create an account"
                    className="formButtonBg"
                    id="registerId"
                    tabIndex="6"
                  />
                </div>
                <div className="signUp">
                  <h3>
                    Already have an account? <a id="signIn">Sign in!</a>
                  </h3>
                </div>
              </div>
            </form>
            <form id="signupOTP">
              <div className="signInHead">
                <h2>Get Started!</h2>
                <p>Create your USER ID!</p>
                <p id="errorMessageOtp"></p>
              </div>
              <div className="signInBody">
                <input
                  type="text"
                  className="input-design my-2"
                  placeholder="Enter Your OTP "
                  id="signupOTPTxt"
                  name="signupOTPTxt"
                  required
                />
                <div className="signInButton mt-2">
                  <input
                    type="button"
                    value="SignUp"
                    className="formButtonBg"
                    id="signUpId"
                  />
                </div>
                {/*<div className="signUp">
                  <h3>
                    Already have an account? <a className="loginMenu">Sign in!</a>
                  </h3>
                </div>*/}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component, Suspense } from "react";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { apiCalling, apiUrlRelianceFunction } from "../apiService";
import Pagination from "react-js-pagination";
import moment from "moment";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class patientTransactionReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientTransactionData: [],
            toDate: "",
            fromDate: "",
            totalCount: 0,
            activePage: 1,
            showMessage: "Waiting for data... ",
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeTo = this.handleChangeTo.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0); 
        this.patientTransactionReport();
    }
    patientTransactionReport() {
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "paymentReport",
                subFunctionName:"patientTransactionReports",
                userId: window.localStorage.getItem("userId"), 
                role:window.localStorage.getItem("role"),
                fromDate:"",
                endDate: "",
                txtInput:"",
                cmbSelect:"",
                cmbSerSelect:"",
                offset: "0",
                max: "10",
            }),
        };
        let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction
        apiFunction(apiJson).then((data) => {
            // console.log(data.result)
            $(".loader").hide();
            if (data.success === "1") {
                this.setState({
                    patientTransactionData: data.result.data,
                    totalCount: data.result.totalCount,
                    showMessage: data.errorMessage === "" ? "" : "No data found"
                });
            }
            if (data.success === "0") {
                $(".loader").hide();
                this.setState({
                    patientTransactionData: [],
                    showMessage: data.errorMessage
                });
            }
        });
    }
    handleChange = (date) => {
        this.setState({
            fromDate: date,
        });
    };
    handleChangeTo = (date) => {
        this.setState({
            toDate: date,
        });
    };  
    changeText = () => {
        if ($("#nameSelect").val() !== '0') {
            document.getElementById("txtProductName").disabled = false
            $('input[name=txtProductName]').focus();
        }
        else {
            document.getElementById("txtProductName").disabled = true
        }
        document.getElementById("txtProductName").value = ""
        
        if ($("#statusType").val() !== '0') {
            document.getElementById("txtSearchName").disabled = false
            $('input[name=txtSearchName]').focus();
        }
        else {
            document.getElementById("txtSearchName").disabled = true
        }
        document.getElementById("txtSearchName").value = ""
    }
    handlePageChange(pageNumber) {

        let txtProductName = $('#txtProductName').val();
        let statusType = $('#statusType').val();
        let txtInput = $('#txtSearchName').val();
        let fromDate = $('#fromDate').val();
        let toDate = $('#toDate').val();
        window.scrollTo(0, 0);
        var newDate = moment(fromDate, "DD/MM/YYYY").format("YYYY-MM-DD")
        var endDate = moment(toDate, "DD/MM/YYYY").format("YYYY-MM-DD")
        this.setState({ activePage: pageNumber });
        var offset;
        var max;
        if (pageNumber === "1") {
            offset = "0";
            max = "10";
            this.setState({
                activePage: pageNumber,
                isLoading: '0'
            })
        }
        else {
            offset = parseInt(pageNumber - 1) * 10;
            max = "10";
            this.setState({
                activePage: pageNumber,
                isLoading: '0'
            })
        }
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "paymentReport",
                subFunctionName:"patientTransactionReports",
                userId: window.localStorage.getItem("userId"), 
                role:window.localStorage.getItem("role"),
                fromDate:newDate,
                endDate: endDate,
                txtInput:txtInput,
                cmbSelect:statusType,
                cmbSerSelect:txtProductName,
                offset: offset,
                max: max,
            }),
        };
        $(".loader").show();
        let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction
        apiFunction(apiJson).then((data) => {
            if (data.success === "1") {
                this.setState({
                    patientTransactionData: data.result.data,
                    totalCount: data.result.totalCount,
                });
                $(".loader").hide();
            }
            if(data.success === "0"){
                this.setState({
                    patientTransactionData: [],
                    totalCount: 0,
                    showMessage: data.errorMessage
                });
                $(".loader").hide();
            }
        });
    }
    searchHandler() {
        let txtProductName = $('#txtProductName').val();
        let statusType = $('#statusType').val();
        let txtInput = $('#txtSearchName').val();
        let fromDate = $('#fromDate').val();
        let toDate = $('#toDate').val();
        var newDate = moment(fromDate, "DD/MM/YYYY").format("YYYY-MM-DD")
        var endDate = moment(toDate, "DD/MM/YYYY").format("YYYY-MM-DD")
        if ($("#nameSelect").val() !== "0" && $("#txtProductName").val() === "") {
            var t = document.getElementById("nameSelect");
            var selectedText = t.options[t.selectedIndex].text;
            alert(selectedText + " Required");
            $("#txtProductName").focus();
            return false;
        }
        if (fromDate > toDate) {
            alert("To date should be greater than or equal to from date")
            return false
        }
        else {
            const searchData = {
                method: "POST",
                body: JSON.stringify({
                    functionName: "paymentReport",
                    subFunctionName:"patientTransactionReports",
                    userId: window.localStorage.getItem("userId"), 
                    role:window.localStorage.getItem("role"),
                    fromDate:newDate,
                    endDate: endDate,
                    txtInput:txtInput,
                    cmbSelect:statusType,
                    cmbSerSelect:txtProductName,
                    offset: "0",
                    max: "10",
                }),
            };
            $(".loader").show();
            let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction
            apiFunction(searchData).then((data) => {
                if (data.success === "1") {
                    $(".loader").hide();
                    this.setState({
                        patientTransactionData: data.result.data,
                        totalCount: data.result.totalCount,
                        showMessage: data.result.data.length > 0 ? "" : "No data found",
                        activePage: 1,
                    });
                }
                if (data.success === "0") {
                    $(".loader").hide();
                    this.setState({
                        patientTransactionData: [],
                        showMessage: data.result.errorMessage
                    });
                }
            })
        }
    }


    render() {
        return (
            <div class="purpleWrap">
                <Suspense fallback={<div >Waiting...</div>}>
                    <section>
                        {folderName !== null &&
                            <Header />
                        }
                        <div className='loader'></div>
                        <div class="container">
                            <div class="main-wrapper1">
                                <div class="col-md-12 p-0">
                                    <ul class="breadcrumb">
                                        <li>
                                            <a href={"/dashboard/reportDashboard/" + this.props.match.params.groupHospitalId}>Reports</a>
                                            <i class="fa fa-chevron-right"></i>
                                        </li>
                                        <li>Patient Transaction Reports</li>
                                    </ul>
                                </div>
                                <div class="form-head">Patient Transaction Reports</div>
                                <div class="custom-search row">
                                    <div class="col-md-4 col-6 mb-3 custom-search-sub">
                                        <select class="input-design"
                                            id="nameSelect"
                                            name="nameSelect"
                                            onChange={() => this.changeText()}
                                        >
                                            <option value="0">- - Select - -</option>
                                            <option value="1">Products</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4 col-6 mb-3 custom-search-sub">
                                        <input
                                            type="text"
                                            class="input-design"
                                            id="txtProductName"
                                            name="txtProductName"
                                            disabled
                                        />
                                    </div>
                                    <div class="col-md-4 col-6 mb-3 custom-search-sub">
                                        <select class="input-design"
                                            id="statusType"
                                            name="statusType"
                                            onChange={() => this.changeText()}
                                        >
                                            <option value="0">- - Status - -</option>
                                            <option value="1">Transaction ID</option>
                                            <option value="2">Patient Name</option>
                                            <option value="3">Doctor Name</option>
                                            <option value="4">Hospital Name</option>
                                            <option value="5">Policy Number</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4 col-6 mb-3 custom-search-sub">
                                        <input
                                            type="text"
                                            class="input-design"
                                            id="txtSearchName"
                                            name="txtSearchName"
                                            disabled
                                        />
                                    </div>
                                    <div class="col-md-4 col-6 mb-3 custom-search-sub">
                                        <DatePicker
                                            placeholderText="From Date"
                                            id="fromDate"
                                            name="fromDate"
                                            selected={this.state.fromDate}
                                            onChange={this.handleChange}
                                            dateFormat="dd/MM/yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={100}
                                            dropdownMode="scroll"
                                            disabledKeyboardNavigation
                                            autoComplete="off"
                                            tabIndex="8"
                                            calendarClassName="react-date-picker"
                                        />
                                    </div>
                                    <div class="col-md-4 col-6 mb-3 custom-search-sub">
                                        <DatePicker
                                            placeholderText="To Date"
                                            id="toDate"
                                            name="toDate"
                                            selected={this.state.toDate}
                                            onChange={this.handleChangeTo}
                                            dateFormat="dd/MM/yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={100}
                                            dropdownMode="scroll"
                                            disabledKeyboardNavigation
                                            autoComplete="off"
                                            tabIndex="8"
                                            calendarClassName="react-date-picker"
                                        />
                                    </div>
                                    <div class="col-md-3 col-10 mb-3 custom-search-sub">
                                        <input
                                            type="submit"
                                            value="Search"
                                            class="formButton m-0"
                                            onClick={() => this.searchHandler()}
                                        />
                                    </div>
                                </div>
                                {this.state.patientTransactionData.length > 0 ?
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style={{ minWidth: "150px" }}>Date</th>
                                                    <th style={{ minWidth: "150px" }}>Policy Number</th>
                                                    <th style={{ minWidth: "150px" }}>Employee ID</th>
                                                    <th style={{ minWidth: "150px" }}>Transaction ID</th>
                                                    <th style={{ minWidth: "150px" }}>Patient Details</th>
                                                    <th style={{ minWidth: "150px" }}>Doctor Details</th>
                                                    <th style={{ minWidth: "150px" }}>Hospital Name</th>
                                                    <th style={{ minWidth: "125px" }}>Service</th>
                                                    <th style={{ minWidth: "200px" }}>Amount </th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ fontSize: "12px" }}>
                                                {this.state.patientTransactionData.map((data) => {
                                                    return (
                                                        <tr>
                                                            <td>{data.createdon}</td>
                                                            <td>{data.policyNumber}</td>
                                                            <td>{data.employeeId}</td>
                                                            <td>{data.memTrId}</td>
                                                            <td>{data.patient}&nbsp;{data.memMobile}</td>
                                                            <td>{data.doctorName}&nbsp;{data.doctorMob}</td>
                                                            <td>{data.hospitalName}</td>
                                                            <td>{data.productNames}</td>
                                                            <td>{data.rpAmt}</td>
                                                            

                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        {this.state.totalCount > 5 ?
                                            <div className="paginationSection orderm-paginationSection">
                                                <Pagination
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={10}
                                                    totalItemsCount={this.state.totalCount}
                                                    pageRangeDisplayed={10}
                                                    onChange={this.handlePageChange}
                                                />
                                            </div>
                                            : ""}
                                    </div>
                                    :
                                    <p className="error-msg text-center mb-0"> {this.state.showMessage} </p>
                                }
                            </div>
                        </div>
                        {folderName !== null &&
                            <Footer />
                        }
                    </section>
                </Suspense>
            </div>
        );
    }
}

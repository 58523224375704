import React, { Component, Suspense } from "react";
import Edit from "../../image/icons/edit.png";
import tick from "../../image/icons/tick.png";
import Deactivate from "../../image/icons/deactvate.png";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Logoimg from "../../image/icons/image-preview.png";
import $ from "jquery";
import { apiCalling } from "../../apiService";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../../" + folderName + "/header"));
const Footer = React.lazy(() => import("../../" + folderName + "/footer"));

export default class Addapproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      totalCount: 0,
      adApprovalData: [],
      showMessage: "Waiting for data... ",
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.getAdApprovalList();
  }
  getAdApprovalList() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "adApprovalListing",
        cmbSelect: "",
        status: "",
        txtInput: "",
        Offset: "0",
        max: "10",
        siteId: window.localStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({
          adApprovalData: data.AdApprovalSearchData,
          totalCount: data.totalCount,
        });
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          adApprovalData: [],
          showMessage: data.errorMessage,
        });
      }
    });
  }
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    } else {
      offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    }
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "adApprovalListing",
        cmbSelect: $("#searchType").val() === "0" ? "" : $("#searchType").val(),
        txtInput: $("#resultTxt").val(),
        status: "",
        Offset: JSON.stringify(offset),
        max: max,
      }),
    };
    // console.log("apiData", apiJson);
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      // console.log("data", data);
      if (data.success === "1") {
        this.setState({
          adApprovalData: data.AdApprovalSearchData,
          totalCount: data.totalCount,
        });
        $(".loader").hide();
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          adApprovalData: [],
          showMessage: data.errorMessage,
        });
      }
    });
  }
  changeText = () => {
    if ($("#searchType").val() !== "0") {
      document.getElementById("resultTxt").disabled = false;
      $("input[name=resultTxt]").focus();
    } else {
      document.getElementById("resultTxt").disabled = true;
    }
    document.getElementById("resultTxt").value = "";
  };
  searchHandler() {
    if ($("#searchType").val() !== "0" && $("#resultTxt").val() === "") {
      var t = document.getElementById("searchType");
      var selectedText = t.options[t.selectedIndex].text;
      alert(selectedText + " Required");
      $("#resultTxt").focus();
      return false;
    } else {
      const searchData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "adApprovalListing",
          cmbSelect:
            $("#searchType").val() === "0" ? "" : $("#searchType").val(),
          status:
            $("#searchStatusType").val() === "0"
              ? ""
              : $("#searchStatusType").val(),
          txtInput: $("#resultTxt").val(),
          Offset: "0",
          max: "10",
        }),
      };
      // console.log("apireturn....", searchData);
      $(".loader").show();
      apiCalling(searchData).then((data) => {
        if (data.success === "1") {
          $("#paginationType").show();
          $(".loader").hide();
          this.setState({
            adApprovalData: data.AdApprovalSearchData,
            totalCount: data.totalCount,
            activePage: 1,
          });
        }
        if (data.success === "0") {
          $("#paginationType").hide();
          $(".loader").hide();
          this.setState({
            adApprovalData: [],
            showMessage: data.errorMessage,
          });
        }
      });
    }
  }
  statusChangeHandler(id, status) {
    let x = false;
    if (status === true) {
      x = window.confirm("Do you want to Disable this?");
    } else {
      x = window.confirm("Do you want to Enable this?");
    }
    if (x === true) {
      const StatusData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "deactivatePharmaRepAds",
          pharmaRepAdsId: id,
        }),
      };

      $(".loader").show();
      apiCalling(StatusData).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          alert("Status Changed Successfully");
          this.getAdApprovalList();
        }
        if (data.success === "0") {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      });
    }
  }
  approveStatusChange = (e, pid) => {
    if (e.target.id === "Approved") {
      const approveData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "approveOrRejectPharmaRepAds",
          pharmaRepAdsId: pid,
          statusName: "Approve",
        }),
      };
      // console.log(approveData);
      $(".loader").show();
      apiCalling(approveData).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          this.getAdApprovalList();
        }
        if (data.success === "0") {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      });
    } else if (e.target.id === "Rejected") {
      const rejectData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "approveOrRejectPharmaRepAds",
          pharmaRepAdsId: pid,
          statusName: "Reject",
        }),
      };
      // console.log(rejectData);
      $(".loader").show();
      apiCalling(rejectData).then((data) => {
        // console.log(data);
        if (data.success === "1") {
          $(".loader").hide();
          alert("Status Changed Successfully");
          this.getAdApprovalList();
        }
        if (data.success === "0") {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      });
    }
  };

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            {folderName !== null && <Header />}
            <div className="loader"></div>
            <div className="container mb-5">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  <li>
                    <a href="/admin/dashboard">Dashboard</a>
                  </li>
                  {/* <li><a href="/adminDashboard/pharmaDashboard">Pharma Ad Approval</a></li> */}
                  <li>
                    <a href="" className="current">
                      Ad Approval List
                    </a>
                  </li>
                </ul>
              </div>
              <div className="head-hospMangmntList">Ad Approval List</div>

              <div className="HosptlMangemntTable">
                <div className="tableHeader-HspMangemnt">
                  <div className="selectField-HspMangmnt">
                    <select
                      className="slecthsp slecthspMob"
                      id="searchType"
                      name="searchType"
                      onChange={() => this.changeText()}
                    >
                      <option value="0">Search In</option>
                      <option value="1">ID</option>
                      <option value="2">Ad Name</option>
                    </select>
                  </div>
                  <div className="selectField-HspMangmnt">
                    <input
                      type="text"
                      className="hspmngInput hspmngInputmob"
                      name="resultTxt"
                      id="resultTxt"
                      disabled
                    />
                  </div>
                  <div className="selectField-HspMangmnt">
                    <select
                      className="slecthsp slecthspMob"
                      id="searchStatusType"
                      name="searchStatusType"
                    >
                      <option value="0">Status</option>
                      <option value="Approved">Approved</option>
                      <option value="Rejected">Rejected</option>
                    </select>
                  </div>
                  <div
                    className="selectField-HspMangmnt"
                    onClick={() => this.searchHandler()}
                  >
                    <button className="searchbtn-hspMng">Search</button>
                  </div>
                </div>
                {this.state.adApprovalData.length > 0 ? (
                  <div class="table-responsive">
                    <table className="table table-bordered-hsp">
                      <thead>
                        <tr>
                          <th className="tablehead-hspmng">ID</th>
                          <th className="tablehead-hspmng">Ad Name</th>
                          <th className="tablehead-hspmng">Rep Name</th>
                          <th className="tablehead-hspmng">Image</th>
                          <th className="tablehead-hspmng">Broucher Link</th>
                          <th className="tablehead-hspmng">Actions</th>
                          <th className="tablehead-hspmng">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.adApprovalData.map((data) => (
                          <tr>
                            <td>{data.pharmaRepAdsId}</td>
                            <td>{data.adName}</td>
                            <td>{data.repName}</td>
                            <td>
                              <div className="td_img">
                                <div className="td_img_div">
                                  <img
                                    src={data.imageName}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="link_overflow">
                                {data.brochureLink}
                              </div>
                            </td>
                            <td class="text-nowrap">
                              <div className="action_tdtr">
                                <Link
                                  to={
                                    "/adminManagement/adApprovalEdit/" +
                                    data.pharmaRepAdsId
                                  }
                                >
                                  <img src={Edit} className="mr-2 img-fluid" />
                                </Link>
                                {data.isActive === true ? (
                                  <img
                                    src={Deactivate}
                                    width="20"
                                    title="Click to Deactivate "
                                    className="mr-1 conf-cursorpointer"
                                    alt=""
                                    onClick={() => {
                                      this.statusChangeHandler(
                                        data.pharmaRepAdsId,
                                        true
                                      );
                                    }}
                                  ></img>
                                ) : data.isActive === false &&
                                  data.status === "Rejected" ? (
                                  <img
                                    src={Deactivate}
                                    width="20"
                                    title="Click to Deactivate "
                                    className="mr-1 conf-cursorpointer"
                                    alt=""
                                    onClick={() => {
                                      this.statusChangeHandler(
                                        data.pharmaRepAdsId,
                                        true
                                      );
                                    }}
                                  ></img>
                                ) : (
                                  <span className="ml-2">Deactivated</span>
                                )}
                                {/* {(data.status === "Rejected" || data.status === "Approved" ) && data.isActive === true ? 
                                                        <img src={Deactivate} width="20" title="Click to Deactivate " className="mr-1 conf-cursorpointer" alt="" onClick={() => { this.statusChangeHandler(data.pharmaRepAdsId, true) }}></img> : 
                                                        <span className="ml-2">Deactivated</span>} */}
                              </div>
                            </td>
                            <td>
                              {data.status === "Pending" ? (
                                <span>
                                  <a
                                    href="#"
                                    id="Approved"
                                    className="mr-2"
                                    onClick={(e) => {
                                      this.approveStatusChange(
                                        e,
                                        data.pharmaRepAdsId
                                      );
                                    }}
                                  >
                                    Approve
                                  </a>
                                  <a
                                    href="#"
                                    id="Rejected"
                                    onClick={(e) => {
                                      this.approveStatusChange(
                                        e,
                                        data.pharmaRepAdsId
                                      );
                                    }}
                                  >
                                    Reject
                                  </a>
                                </span>
                              ) : data.status === "Approved" ? (
                                <span>Approved</span>
                              ) : data.status === "Rejected" ? (
                                <span>Rejected</span>
                              ) : (
                                ""
                              )}
                            </td>
                            {/* <td>{data.status.replace(/\s+/g,'')==="Pending" && data.isActive === false ? <span><a href="#" id="Approved" className="mr-2" onClick={(e)=>{this.approveStatusChange(e,data.pharmaRepAdsId)}}>Approve</a>
                                                <a href="#" id="Rejected" onClick={(e)=>{this.approveStatusChange(e,data.pharmaRepAdsId)}}>Reject</a></span>:<span>{data.status}</span>}
                                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {this.props.state.totalCount > 5 ? (
                    <div className="pagination-qualList pagination-qualListwidth">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.totalCount}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                      />
                    </div>
                     ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <p className="error-msg text-center mb-0">
                    {" "}
                    {this.state.showMessage}{" "}
                  </p>
                )}
              </div>
            </div>
            {folderName !== null && <Footer />}
          </section>
        </Suspense>
        {/* <Suspense fallback={<div></div>}>
                    <section>
                        <Footer />
                    </section>
                </Suspense> */}
      </div>
    );
  }
}

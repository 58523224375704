import React, { Component, Suspense } from "react";
import $ from "jquery";
import ReactTooltip from 'react-tooltip';
import images from "../image/icons/underMaintenance_6318436_lrg.jpg";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class PrivacyPolicy extends Component {

    
  render() {
    return (
        <div class="purpleWrap" style={{backgroundColor:"black",height:"700px"}}>
        <Suspense fallback={<div class="loader"></div>}>
            <section>
                {/* <Header /> */}
                <div class="container">
                    <div class="main-wrapper1 terms-wrapper" style={{textAlign: "center"}}>
                        <img src={images}></img>
                    </div>
                </div>
                {/* <Footer /> */}
            </section>
        </Suspense>
        </div>
    )
    }
}
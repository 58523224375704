import { apiCalling } from "../apiService";

export async function getSiteData() {
  const jsonReq = {
    method: "POST",
    body: JSON.stringify({
      functionName: "getSiteDetails",
      siteId: "",
      siteUrl: window.location.host,
    }),
  };
  // alert(jsonReq.body);
  return new Promise((resolve, reject) => {
    apiCalling(jsonReq).then((data) => {
      if (data.success === "1") {
        // console.log(data.siteDetailsData[0].siteData[0]);
        var location = data.siteDetailsData[0].siteData[0].siteName;
        let is_theme_based = data.siteDetailsData[0].siteData[0].is_theme_based;
        let project_folder = data.siteDetailsData[0].siteData[0]?.project_folder
          ? data.siteDetailsData[0].siteData[0]?.project_folder
          : "DoctorsCabin";
        //alert(project_folder);
        var folder = "DoctorsCabin";
        if (is_theme_based === false && project_folder !== "") {
          folder = project_folder;
        } else {
          folder = data.siteDetailsData[0].siteData[0].themeName;
        }
        if (data.siteDetailsData[0].siteData[0].id) {
        } else {
          folder = "DoctorsCabin";
        }
        //folder = 'SAANVI'
        let folderName = folder;
        window.localStorage.setItem(
          "groupHospitalAdminId",
          data.siteDetailsData[0].groupHospitalAdminData[0]
            ?.groupHospitalAdminId
        );
        window.localStorage.setItem(
          "themeId",
          data.siteDetailsData[0].siteData[0].themeId
        );

        window.localStorage.setItem("themeVersion", "0");
        window.localStorage.setItem("homeName", folder);
        window.localStorage.setItem("reload", "1");
        window.localStorage.setItem(
          "theme_custom_color",
          data.siteDetailsData[0].siteData[0].theme_custom_color
        );
        window.localStorage.setItem(
          "contact_emailId",
          data.siteDetailsData[0].siteData[0].emailId
        );
        window.localStorage.setItem(
          "contact_no",
          data.siteDetailsData[0].siteData[0].contact_no
        );
        window.localStorage.setItem(
          "fav_icon",
          data.siteDetailsData[0].siteData[0].fav_icon
        );
        window.localStorage.setItem(
          "seo_description",
          data.siteDetailsData[0].siteData[0].seo_description
        );
        window.localStorage.setItem(
          "seo_keywords",
          data.siteDetailsData[0].siteData[0].seo_keywords
        );
        window.localStorage.setItem(
          "seo_title",
          data.siteDetailsData[0].siteData[0].seo_title
        );
        window.localStorage.setItem(
          "slug",
          data.siteDetailsData[0].siteData[0].slug
        );
        var google_analytics_script =
          data.siteDetailsData[0].siteData[0].google_analytics_script;

        var clarityId = "";
        var googleId = "";
        if (google_analytics_script.length < 30) {
          var Gdata = google_analytics_script.split(",");
          if (Gdata[0]) {
            googleId = Gdata[0];
          }
          if (Gdata[1]) {
            clarityId = Gdata[1];
          }
        }

        window.localStorage.setItem("clarityId", clarityId);
        window.localStorage.setItem("googleId", googleId);
        // window.localStorage.setItem("googleId",data.siteDetailsData[0].siteData[0].google_analytics_script_id);
        window.localStorage.setItem(
          "siteName",
          data.siteDetailsData[0].siteData[0].siteName
        );
        window.localStorage.setItem(
          "book_appointment_terms",
          data.siteDetailsData[0].siteData[0].book_appointment_terms
        );
        window.localStorage.setItem(
          "video_chat_terms",
          data.siteDetailsData[0].siteData[0].video_chat_terms
        );
        window.localStorage.setItem(
          "hospital_working_time",
          data.siteDetailsData[0].siteData[0].hospital_working_time
        );
        window.localStorage.setItem(
          "site_url",
          data.siteDetailsData[0].siteData[0].site_url
        );
        window.localStorage.setItem(
          "fbPixel",
          data.siteDetailsData[0].siteData[0].fbPixel
        );
        window.localStorage.setItem(
          "hasHealthTracker",
          data.siteDetailsData[0].siteData[0].hasHealthTracker
        );
        window.localStorage.setItem(
          "footerData",
          JSON.stringify(data.siteDetailsData[0].footerData)
        );

        window.localStorage.setItem(
          "siteLogo",
          data.siteDetailsData[0].siteData[0].logo_path
        );
        this.setState({ isLoader: true });
        window.localStorage.setItem(
          "siteId",
          data.siteDetailsData[0].siteData[0].id
        );
        const hosDataVar = JSON.stringify(data.siteDetailsData[0].hospitalData);
        window.localStorage.setItem(
          "siteServices",
          JSON.stringify(data.siteDetailsData[0].services)
        );

        if (hosDataVar) {
          window.localStorage.setItem(
            "footerhospitalData",
            JSON.stringify(data.siteDetailsData[0].hospitalData)
          );
          window.localStorage.setItem(
            "hospitalLocationCount",
            data.siteDetailsData[0].hospitalLocationCount
          );

          window.localStorage.setItem(
            "footerHospitalName",
            data.siteDetailsData[0].hospitalData.hospitalName
          );
          window.localStorage.setItem(
            "footerHospitalcity",
            data.siteDetailsData[0].hospitalData.city
          );
          window.localStorage.setItem(
            "footerHospitaladdress",
            data.siteDetailsData[0].hospitalData.address
          );
          window.localStorage.setItem(
            "footerHospitalstateName",
            data.siteDetailsData[0].hospitalData.stateName
          );
          window.localStorage.setItem(
            "footerHospitalzipCode",
            data.siteDetailsData[0].hospitalData.zipCode
          );
        }
        //alert(data.siteDetailsData[0].themeSectionList.length);
        for (
          let i = 0;
          i < data.siteDetailsData[0].themeSectionList.length;
          i++
        ) {
          window.localStorage.setItem(
            data.siteDetailsData[0].themeSectionList[i].themeSectionName,
            JSON.stringify(
              data.siteDetailsData[0].themeSectionList[i].themeSectionContent
            )
          );
        }
        window.localStorage.setItem(
          "doctorDetails",
          JSON.stringify(data.siteDetailsData[0].doctorDetails)
        );
        // alert(window.localStorage.getItem("pathname"))
        if (String(window.localStorage.getItem("pathname")) != "null") {
          alert("if ok");
          window.location.href = window.localStorage.getItem("pathname");
          //window.location.href="/maintenance"
        } else {
          alert("else ok");
        }
      }
    });
  });
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faArrowDown,
  faSignal,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import { Component } from "react";
import Person from "../image/analytics/inperson.png";
import Chat from "../image/analytics/chat.png";
import VideoCall from "../image/analytics/video-call.png";
import VoiceCall from "../image/analytics/voicemail.png";
import LineGraph from "./line_graph";
import Overview from "./overview";
import { apiCalling } from "../apiService";
import moment from "moment";
import SelectHosp from "./selecthosp";
import SelectDoct from "./selectDoctor";

var xAxisLabels = [];
var seriesData = [];
export default class App extends Component {
  constructor() {
    super();
    this.state = {
      startDate: "",
      endDate: "",
      fromDate: "",
      toDate: "",
      dayFlag: true,
      monthFlag: false,
      yearFlag: false,
      xAxisLabels: [],
      seriesData: [],
      doctorSeriesData: [],
      doctorXAxisLabels: [],
      patientTotalCount: "",
      doctorTotalCount: "",
      overviewInPersonData: [],
      overviewVideoCallData: [],
      overviewTextChatData: [],
      overviewAudioChatData: [],
      doctorLoginCount: "",
      mobileAppDoctors: "",
      totalInpersonCount: "",
      totalTextChatCount: "",
      totalVideoChatCount: "",
      totalAudioChatCount: "",
      overviewKey: "",
      dateData: [],
      pieChartlabels: ["Dr. Nagarjuna", "Dr. Prasad"],
      pieChartPercentageCount: [72, 28],
      fromInputList: "",
      toInputList: "",
      overViewMax: 0,
      monthTable: [
        {
          id: 1,
          month: "January",
        },
        {
          id: 2,
          month: "February",
        },
        {
          id: 3,
          month: "March",
        },
        {
          id: 4,
          month: "April",
        },
        {
          id: 5,
          month: "May",
        },
        {
          id: 6,
          month: "June",
        },
        {
          id: 7,
          month: "July",
        },
        {
          id: 8,
          month: "August",
        },
        {
          id: 9,
          month: "September",
        },
        {
          id: 10,
          month: "October",
        },
        {
          id: 11,
          month: "November",
        },
        {
          id: 12,
          month: "December",
        },
      ],
      revenueDateXAxisLabels: [],
      revenueAudioChatData: [],
      revenueVideoChatData: [],
      revenueTextchatData: [],
      revenueChanged: "",
      revenueMax: 0,
    };
  }
  componentDidMount() {
    $("#fromDatePicker").attr("hidden", true);
    $("#dateSearchFunction").attr("hidden", true);
    $("#toDatePicker").attr("hidden", true);
    this.getPatientGraphDetails("", "");
    this.getDoctorGraphDetails("", "");
    this.getDoctorPieChartAnalytics("", "");
    this.getOverviewChartData("", "");
    this.getAppointmentRevenueAnalytics("", "");
    // this.getAppointmentRevenueAnalytics(moment().startOf("year"), new Date());

    // this.getTotalAppointmentAnalytics("", "");

    // var dateData = [
    //   "2018-09-06T00:00:00.000Z",
    //   "2018-09-09T01:30:00.000Z",
    //   "2018-09-11T02:30:00.000Z",
    //   "2018-09-13T03:30:00.000Z",
    //   "2018-09-15T04:30:00.000Z",
    //   "2018-09-20T05:30:00.000Z",
    //   "2018-09-22T06:30:00.000Z",
    // ];
    // for (let index = 0; index < dateData.length; index++) {
    //   this.state.dateData.push(
    //     new Date(moment(dateData[index]).format("YYYY-MM-DD")).getTime()
    //   );
    // }
  }
  getAppointmentRevenueAnalytics = (fromDate, toDate) => {
    var revenueMax = 0;
    const getGraphDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "appointmentRevenueAnalytics",
        siteId: window.localStorage.getItem("siteId"),
        fromDate: fromDate === "" ? "" : moment(fromDate).format("yyyy-MM-DD"),
        toDate: toDate === "" ? "" : moment(toDate).format("yyyy-MM-DD"),
        year: this.state.yearFlag,
        month: this.state.monthFlag,
        week: this.state.dayFlag,
      }),
    };
    // console.log(getGraphDetails.body,"appointmentRevenueAnalytics,body.........................");
    apiCalling(getGraphDetails).then((data) => {
      // console.log(
      //   "🚀 ~ file: analytics.jsx:155 ~ App ~ apiCalling ~ getGraphDetails:",
      //   getGraphDetails.body
      // );
      // console.log(
      //   "🚀 ~ file: analytics.jsx:155 ~ App ~ apiCalling ~ data:",
      //   data
      // );
      if (data.success === "1") {
        for (let i = 0; i < data.result.appointmentRevenueDetails.length; i++) {
          this.state.revenueDateXAxisLabels.push(
            data.result.appointmentRevenueDetails[i].appointmentDate.toString()
          );
          this.state.revenueAudioChatData.push(
            data.result.appointmentRevenueDetails[i].audioRevenue
          );
          this.state.revenueTextchatData.push(
            data.result.appointmentRevenueDetails[i].textChatRevenue
          );
          this.state.revenueVideoChatData.push(
            data.result.appointmentRevenueDetails[i].videoRevenue
          );
          revenueMax =
            data.result.appointmentRevenueDetails[i].audioRevenue > revenueMax
              ? data.result.appointmentRevenueDetails[i].audioRevenue
              : revenueMax;
          revenueMax =
            data.result.appointmentRevenueDetails[i].textChatRevenue >
            revenueMax
              ? data.result.appointmentRevenueDetails[i].textChatRevenue
              : revenueMax;
          revenueMax =
            data.result.appointmentRevenueDetails[i].videoRevenue > revenueMax
              ? data.result.appointmentRevenueDetails[i].videoRevenue
              : revenueMax;
        }
        this.setState({
          revenueChanged: data.result.appointmentRevenueDetails.length,
          revenueMax: revenueMax,
        });
      }
    });
  };
  getPatientGraphDetails = (fromDate, toDate) => {
    xAxisLabels = [];
    seriesData = [];
    const getGraphDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "patientRegistraionAnalytics",
        siteId: window.localStorage.getItem("siteId"),
        hospitalId: "",
        doctorId: "",
        fromDate: fromDate === "" ? "" : moment(fromDate).format("yyyy-MM-DD"),
        toDate: toDate === "" ? "" : moment(toDate).format("yyyy-MM-DD"),
        year: this.state.yearFlag,
        month: this.state.monthFlag,
        week: this.state.dayFlag,
      }),
    };
    // console.log(getGraphDetails.body,"patientRegistraionAnalytics,Body...........................");
    apiCalling(getGraphDetails).then((data) => {
      // console.log(
      //   "🚀 ~ file: analytics.jsx:213 ~ App ~ apiCalling ~ data:",
      //   data
      // );
      if (data.result.countResult.length !== 0) {
        for (let i = 0; i < data.result.countResult.length; i++) {
          // xAxisLabels.push(
          //   new Date(
          //     moment(data.result.countResult[i].cretedDate).format("YYYY-MM-DD")
          //   ).getTime()
          // );
          xAxisLabels.push(data.result.countResult[i].cretedDate.toString());
          seriesData.push(data.result.countResult[i].createdCount);
        }
      }
      this.setState(
        {
          xAxisLabels: xAxisLabels,
          seriesData: seriesData,
          patientTotalCount: data.result.totalCount,
        },
        () => {}
      );
    });
  };
  getDoctorGraphDetails = (fromDate, toDate) => {
    var doctorXAxisLabels = [];
    var doctorSeriesData = [];
    const getDoctorGraphDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorRegistrationAnalytics",
        siteId: window.localStorage.getItem("siteId"),
        hospitalId: "",
        doctorId: "",
        fromDate: fromDate === "" ? "" : moment(fromDate).format("yyyy-MM-DD"),
        toDate: toDate === "" ? "" : moment(toDate).format("yyyy-MM-DD"),
        year: this.state.yearFlag,
        month: this.state.monthFlag,
        week: this.state.dayFlag,
      }),
    };
    apiCalling(getDoctorGraphDetails).then((data) => {
      // console.log(
      //   "🚀 ~ file: analytics.jsx:263 ~ App ~ apiCalling ~ data:",
      //   data
      // );
      if (data.success === "1") {
        for (let i = 0; i < data.result.countResult.length; i++) {
          // if(this.state.monthFlag===true){
          //   debugger;
          //   var object = this.state.monthTable.filter((obj) => {
          //       console.log(obj.month);
          //       if(obj.id === data.result.countResult[i].cretedDate)
          //       return obj.month
          //   })
          //   doctorXAxisLabels.push(object[0].month)
          // }
          // else {

          // }
          doctorXAxisLabels.push(
            data.result.countResult[i].cretedDate.toString()
          );
          doctorSeriesData.push(data.result.countResult[i].createdCount);
        }
        this.setState(
          {
            doctorXAxisLabels: doctorXAxisLabels,
            doctorSeriesData: doctorSeriesData,
            doctorTotalCount: data.result.totalCount,
            doctorLoginCount: data.result.doctorLoginCount,
            mobileAppDoctors: data.result.mobileAppDoctors,
          },
          () => {}
        );
      } else {
        this.setState({
          doctorXAxisLabels: [],
          doctorSeriesData: [],
          doctorTotalCount: 0,
        });
      }
    });
  };
  getDoctorPieChartAnalytics = (fromDate, toDate) => {};
  // getTotalAppointmentAnalytics = (fromDate, toDate) => {
  //   const totalAppointmentsJSON = {
  //     method: "POST",
  //     body: JSON.stringify({
  //       functionName: "appointmentAnalytics",
  //       siteId: window.localStorage.getItem("siteId"),
  //       hospitalId: "",
  //       fromDate: fromDate === "" ? "" : moment(fromDate).format("yyyy-MM-DD"),
  //       toDate: toDate === "" ? "" : moment(toDate).format("yyyy-MM-DD"),
  //       year: this.state.yearFlag,
  //       month: this.state.monthFlag,
  //       week: this.state.dayFlag,
  //     }),
  //   }
  //   apiCalling(totalAppointmentsJSON).then((data) => {
  //     console.log(data);
  //     this.setState({
  //       totalInpersonCount:data.result.totalInpersonCount,
  //       totalAudioChatCount:data.result.totalaudioCount,
  //       totalVideoChatCount:data.result.totalVideoCount,
  //       totalTextChatCount:data.result.totalTextChatCount
  //     })
  //   })
  // };

  getOverviewChartData = (fromDate, toDate) => {
    var overviewGreat = 0;
    this.setState({
      dateData: [],
      overviewInPersonData: [],
      overviewVideoCallData: [],
      overviewTextChatData: [],
      overviewAudioChatData: [],
    });
    const overviewJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "appointmentAnalytics",
        siteId: window.localStorage.getItem("siteId"),
        hospitalId: "",
        fromDate: fromDate === "" ? "" : moment(fromDate).format("yyyy-MM-DD"),
        toDate: toDate === "" ? "" : moment(toDate).format("yyyy-MM-DD"),
      }),
    };
    // console.log(overviewJson.body,"appointmentAnalytics,body,,,,,,,,,,,,,,,,,,,,,,,,,,,");
    apiCalling(overviewJson).then((data) => {
      if (data.success === "1") {
        // console.log(data,"appointmentAnalyticsappointmentAnalyticsappointmentAnalyticsappointmentAnalytics");
        this.setState({
          totalInpersonCount: data.result.totalInpersonCount,
          totalAudioChatCount: data.result.totalaudioCount,
          totalVideoChatCount: data.result.totalVideoCount,
          totalTextChatCount: data.result.totalTextChatCount,
        });
        for (let index = 0; index < data.result.countResult.length; index++) {
          this.state.dateData.push(
            new Date(
              moment(data.result.countResult[index].appointmentTime).format(
                "YYYY-MM-DD"
              )
            ).getTime()
          );
          overviewGreat =
            data.result.countResult[index].details.inpersonCount > overviewGreat
              ? data.result.countResult[index].details.inpersonCount
              : overviewGreat;
          overviewGreat =
            data.result.countResult[index].details.videoCount > overviewGreat
              ? data.result.countResult[index].details.videoCount
              : overviewGreat;
          overviewGreat =
            data.result.countResult[index].details.textChatCount > overviewGreat
              ? data.result.countResult[index].details.textChatCount
              : overviewGreat;
          overviewGreat =
            data.result.countResult[index].details.audioCount > overviewGreat
              ? data.result.countResult[index].details.audioCount
              : overviewGreat;

          this.state.overviewInPersonData.push(
            parseInt(data.result.countResult[index].details.inpersonCount)
          );
          this.state.overviewVideoCallData.push(
            parseInt(data.result.countResult[index].details.videoCount)
          );
          this.state.overviewTextChatData.push(
            parseInt(data.result.countResult[index].details.textChatCount)
          );
          this.state.overviewAudioChatData.push(
            parseInt(data.result.countResult[index].details.audioCount)
          );
        }
      }
      this.setState({
        overviewKey: data.result.countResult.length,
        overViewMax: overviewGreat,
      });
    });
  };
  changeTimeLimit(e) {
    if (e.target.value === "0") {
      $("#fromDatePicker").attr("hidden", false);
      $("#toDatePicker").attr("hidden", false);
      $("#dateSearchFunction").attr("hidden", false);
      this.setState({
        fromDate: "",
        toDate: "",
        dayFlag: true,
        monthFlag: false,
        yearFlag: false,
      });
    } else if (e.target.value === "1") {
      $("#fromDatePicker").attr("hidden", false);
      $("#toDatePicker").attr("hidden", false);
      $("#dateSearchFunction").attr("hidden", false);
      this.setState({
        fromDate: "",
        toDate: "",
        dayFlag: false,
        monthFlag: true,
        yearFlag: false,
      });
    } else if (e.target.value === "2") {
      $("#fromDatePicker").attr("hidden", false);
      $("#toDatePicker").attr("hidden", false);
      $("#dateSearchFunction").attr("hidden", false);
      this.setState({
        fromDate: "",
        toDate: "",
        dayFlag: false,
        monthFlag: false,
        yearFlag: true,
      });
    } else if (e.target.value === "3") {
      $("#fromDatePicker").attr("hidden", true);
      $("#toDatePicker").attr("hidden", true);
      $("#dateSearchFunction").attr("hidden", true);
      this.setState({
        fromDate: "",
        toDate: "",
        dayFlag: false,
        monthFlag: false,
        yearFlag: false,
      });
    }
  }
  handleDateChange() {
    if (this.state.fromDate === "") {
      alert("Please select From Date first");
      return false;
    } else if (this.state.toDate === "") {
      alert("Please select To Date first");
      return false;
    } else if (
      this.state.yearFlag === false &&
      this.state.monthFlag === false &&
      this.state.dayFlag === false
    ) {
      alert("Please select a filter ");
      return false;
    } else {
      this.getPatientGraphDetails(this.state.fromDate, this.state.toDate);
      this.getDoctorGraphDetails(this.state.fromDate, this.state.toDate);
      this.getOverviewChartData(this.state.fromDate, this.state.toDate);
      // this.getTotalAppointmentAnalytics(this.state.fromDate, date);
    }
  }
  render() {
    return (
      <div className="analytics_dash">
        <div className="container-fluid">
          <div className="row pt-2">
            <div className="col-lg-6">
              <div className="analytics_headleft">
                <div className="analytics_dashhead">Analytics Dashboard</div>
                <div className="analytics_datediv">
                  {this.state.fromDate !== "" && this.state.toDate !== "" ? (
                    <>
                      {moment(this.state.fromDate).format("YYYY/MM/DD")} -{" "}
                      {moment(this.state.toDate).format("YYYY/MM/DD")}
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="analytics_headright">
                <div className="analytics_rightselect">
                  <select onClick={(e) => this.changeTimeLimit(e)}>
                    <option value="3">Select</option>
                    <option value="0">Day</option>
                    <option value="1">Month</option>
                    <option value="2">Year</option>
                  </select>
                </div>
                <div className="analytics_fromto">
                  <div>
                    <DatePicker
                      id="fromDatePicker"
                      hidden
                      name="fromDate"
                      selected={this.state.fromDate}
                      onChange={(date) => this.setState({ fromDate: date })}
                      dateFormat="yyyy-MM-dd"
                      calendarClassName="date_filter"
                      placeholderText="From date"
                      showMonthDropdown={
                        this.state.dayFlag === true ? true : false
                      }
                      useShortMonthInDropdown={
                        this.state.dayFlag === true ? true : false
                      }
                      showYearDropdown={
                        this.state.dayFlag === true ? true : false
                      }
                      scrollableYearDropdown={
                        this.state.dayFlag === true ? true : false
                      }
                      showFullMonthYearPicker={
                        this.state.monthFlag === true ? true : false
                      }
                      showMonthYearPicker={
                        this.state.monthFlag === true ? true : false
                      }
                      showYearPicker={
                        this.state.yearFlag === true ? true : false
                      }
                    />
                    {/* {this.state.fromInputList} */}
                  </div>
                  <div>
                    <DatePicker
                      id="toDatePicker"
                      hidden
                      name="toDate"
                      selected={this.state.toDate}
                      onChange={(date) => this.setState({ toDate: date })}
                      dateFormat="yyyy-MM-dd"
                      calendarClassName="date_filter"
                      placeholderText="To Date"
                      showMonthDropdown={
                        this.state.dayFlag === true ? true : false
                      }
                      useShortMonthInDropdown={
                        this.state.dayFlag === true ? true : false
                      }
                      showYearDropdown={
                        this.state.dayFlag === true ? true : false
                      }
                      scrollableYearDropdown={
                        this.state.dayFlag === true ? true : false
                      }
                      showFullMonthYearPicker={
                        this.state.monthFlag === true ? true : false
                      }
                      showMonthYearPicker={
                        this.state.monthFlag === true ? true : false
                      }
                      showYearPicker={
                        this.state.yearFlag === true ? true : false
                      }
                    />
                    {/* {this.state.toInputList} */}
                  </div>
                  <div>
                    <button
                      id="dateSearchFunction"
                      className="analytics_datediv"
                      onClick={() => this.handleDateChange()}
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div className="analytics_expertselect">
                  <span></span>
                </div>
                <div className="analytics_topemail">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div className="row analytics_box">
            <div className="col-lg-3 col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="analytics_user analytics_blue">
                    <img src={Person} />
                  </div>
                  <div className="analytics_userdata">
                    <div className="userdata_head">
                      {this.state.totalInpersonCount}
                    </div>
                    <div className="userdata_content">
                      Grand Total In Person
                    </div>
                  </div>
                  {/* <div className="analytics_usergraph">
                    <div className="analytics_missed">Missed</div>
                    <div className="analytics_rating">
                      <FontAwesomeIcon icon={faArrowDown} />8
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="analytics_user analytics_purple">
                    <img src={Chat} />
                  </div>
                  <div className="analytics_userdata">
                    <div className="userdata_head">
                      {this.state.totalTextChatCount}
                    </div>
                    <div className="userdata_content">
                      Grand Total Text Chat
                    </div>
                  </div>
                  {/* <div className="analytics_usergraph">
                    <div className="analytics_missed">Missed</div>
                    <div className="analytics_rating">
                      <FontAwesomeIcon icon={faArrowDown} />8
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="analytics_user analytics_danger">
                    <img src={VideoCall} />
                  </div>
                  <div className="analytics_userdata">
                    <div className="userdata_head">
                      {this.state.totalVideoChatCount}
                    </div>
                    <div className="userdata_content">
                      Grand Total Video Chat
                    </div>
                  </div>
                  {/* <div className="analytics_usergraph">
                    <div className="analytics_missed">Missed</div>
                    <div className="analytics_rating">
                      <FontAwesomeIcon icon={faArrowDown} />8
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="analytics_user analytics_green">
                    <img src={VoiceCall} />
                  </div>
                  <div className="analytics_userdata">
                    <div className="userdata_head">
                      {this.state.totalAudioChatCount}
                    </div>
                    <div className="userdata_content">
                      Grand Total Audio Chat
                    </div>
                  </div>
                  {/* <div className="analytics_usergraph">
                    <div className="analytics_missed">Missed</div>
                    <div className="analytics_rating">
                      <FontAwesomeIcon icon={faArrowDown} />8
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row analytics_chatbox">
            <div className="col-md-7">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="analytics_overhead">
                        <div className="analytics_overleft">Overview</div>
                      </div>
                      <div className="analytics_overbody">
                        <Overview
                          key={this.state.overviewKey}
                          overViewMax={this.state.overViewMax}
                          overviewInPersonData={this.state.overviewInPersonData}
                          overviewVideoCallData={
                            this.state.overviewVideoCallData
                          }
                          overviewTextChatData={this.state.overviewTextChatData}
                          overviewAudioChatData={
                            this.state.overviewAudioChatData
                          }
                          dateData={this.state.dateData}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="analytics_overhead">
                        <div className="analytics_overleft">
                          <FontAwesomeIcon icon={faSignal} />
                          <span>Patient Registration</span>
                        </div>
                        <div className="analytics_overright">
                          <span>{this.state.patientTotalCount}</span>
                        </div>
                      </div>
                      <div className="analytics_overbody">
                        <LineGraph
                          key={this.state.patientTotalCount}
                          seriesName="Patient Registration"
                          xAxisLabels={this.state.xAxisLabels}
                          seriesData={this.state.seriesData}
                          year={this.state.yearFlag}
                          month={this.state.monthFlag}
                          week={this.state.dayFlag}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="analytics_overhead">
                        <div className="analytics_overleft ">
                          <FontAwesomeIcon icon={faSignal} />
                          <span> Doctor Registration</span>
                        </div>
                        <div className="analytics_overright">
                          <span>{this.state.doctorTotalCount}</span>
                        </div>
                      </div>
                      <div className="analytics_overbody">
                        <LineGraph
                          key={this.state.doctorSeriesData}
                          seriesName="Doctor Registration"
                          xAxisLabels={this.state.doctorXAxisLabels}
                          seriesData={this.state.doctorSeriesData}
                          year={this.state.yearFlag}
                          month={this.state.monthFlag}
                          week={this.state.dayFlag}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="analytics_userdata">
                        <div className="userdata_head">
                          {this.state.mobileAppDoctors}
                        </div>
                        <div className="userdata_content">
                          Mobile App Doctors
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="analytics_userdata">
                        <div className="userdata_head">
                          {this.state.doctorLoginCount}
                        </div>
                        <div className="userdata_content">
                          Doctor Login Count
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="row">
                {/* <div className="col-md-12">
                  <div className="card">
                    <div className="card-body anaytics_datalabelcircle">
                      <div className="analytics_overhead">
                        <div className="analytics_overleft ">
                          <span>Revenue Generated by Appointment</span>
                        </div>
                        <div className="analytics_overright">
                          <select>
                            <option>Select Hospital</option>
                            <option>Select Hospital</option>
                          </select>
                        </div>
                      </div>
                      <div className="analytics_overbody">
                        <SelectHosp 
                          key={this.state.revenueChanged}
                          revenueMax={this.state.revenueMax}
                          revenueAudioChatData={this.state.revenueAudioChatData}
                          revenueTextchatData={
                            this.state.revenueTextchatData
                          }
                          revenueVideoChatData={this.state.revenueVideoChatData}

                          dateData={this.state.revenueDateXAxisLabels}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-md-12">
                  <div className="card">
                    <div className="card-body anaytics_datalabelsquare">
                      <div className="analytics_overhead">
                        <div className="analytics_overleft ">
                          <span>Top Doctors</span>
                        </div>
                        <div className="analytics_overright">
                          <select>
                            <option>Select Doctor</option>
                            <option>Select Hospital</option>
                          </select>
                        </div>
                      </div>
                      <div className="analytics_overbody">
                        <SelectDoct
                          pieChartlabels={this.state.pieChartlabels}
                          pieChartPercentageCount={
                            this.state.pieChartPercentageCount
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import $ from "jquery";
import { apiCalling, changeDateFormat } from "../apiService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import TimePicker from "rc-time-picker";
const format = "h:mm a";
const now = moment().hour(0).minute(0);

export default class DateTimePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(parseInt(this.props.appTime)),
    };
    this.confirmAppointmentPopUp = this.confirmAppointmentPopUp.bind(this);
  }
  componentDidMount() { 
   // alert(this.props.appTime)
    $(".time-date-popup").hide();
    $(".popup-hide").click(function () {
      $(".popup-overlay").hide();
      window.localStorage.setItem("confirmId", "");
      $("body").removeClass("overflow-hidden");
    });
  }
  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
    $("#selectDate").val(moment(date).format("DD/MM/YYYY"));
  };

   confirmAppointmentPopUp(id) {
    $(".loader2").show();
    let appDate = $("#editAppDate" + id).val();
    let appTime = $("#editAppTime" + id).val();
    let newDates = changeDateFormat(appDate);
    let appointmentTime = moment(appTime, "hh:mm a").format("LT")
    //alert(appTime);
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "confirmVideoAppointment",
        appointmentDate: appDate,
        appointmentTime: appointmentTime,
        appointmentDateTime: new Date(
          moment(appDate, "DD/MM/YYYY").format("YYYY-MM-DD") +
            " " +
            moment(appTime, ["h:mm A"]).format("kk:mm:ss")
        ).getTime(),
        requestVideoAppointmentId: id,
        ratePerSession: "",
        ratePerSessionAudio: "",
        siteId: window.localStorage.getItem("siteId"),
        isConfirmAndDeduct: "",
        isReferralWithOtp: "",
      }),
    };
   // console.log(apiJson.body);
   
     apiCalling(apiJson).then((data) => {
      
      if (data.success == "1") {
        alert(data.successMessage);
        $("#dateTime-popup" + this.props.appId).hide();
        $("#rowOpenApp"+id).hide();
        this.confirmVideoAppointmentMail(data);
       
       // window.location.reload();
        $(".loader2").hide();
      } else {
        alert(data.errorMessage);
        $(".loader2").hide();
      }
    });
  }
  async confirmVideoAppointmentMail(data){
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "confirmVideoAppointmentMail",
        siteId: "",
        jsonMailOptions: data.jsonMailOptions,
        jsonAdminDocMailOptions: data.jsonAdminDocMailOptions,
        smsObj: data.smsObj,
        smsDoctorObj: data.smsDoctorObj,
        smsAdminObj: data.smsAdminObj,
       
      }),
    };
    //console.log(apiJson.body);
    apiCalling(apiJson).then((data) => {

    });

  }
  getDisabledHours() {
    var hours = [];
    for (var i = 0; i < moment().hour(); i++) {
      hours.push(i);
    }
    return hours;
  }
  getDisabledMinutes(selectedHour) {
    var minutes = [];
    if (selectedHour === moment().hour()) {
      for (var i = 0; i < moment().minute(); i++) {
        minutes.push(i);
      }
    }
    return minutes;
  }
  render() {
    return (
      <div 
        class="popup-overlay time-date-popup"
        id={"dateTime-popup" + this.props.appId}
      >
        <div className="popup-content">
          <div className="modal-popup">
            <a className="close-popup popup-hide">&times;</a>
            <div className="header border-0">
              {" "}
              Change Appointment Date and Time
              {""}
            </div>
            <div className="content">
              <div class="row my-3">
                <div class="col-6">
                  <label class="label-text">Appointment Date</label>
                  <DatePicker
                    placeholder="Appointment Date"
                    id={"editAppDate" + this.props.appId}
                    name={"editAppDate" + this.props.appId}
                    minDate={new Date()}
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                    dateFormat="dd/MM/yyyy"
                    calendarClassName="react-date-picker"
                  />
                </div>
                <div class="col-6">
                  <label class="label-text">Appointment Time</label>
                  <TimePicker
                    showSecond={false}
                    defaultValue={moment(this.props.appTime)}
                    className="app-popup-time"
                    format={format}
                    use12Hours
                    inputReadOnly
                    id={"editAppTime" + this.props.appId}
                    name={"editAppTime" + this.props.appId}
                    disabledHours={() => this.getDisabledHours()}
                    disabledMinutes={(selectedHour) =>
                      this.getDisabledMinutes(selectedHour)
                    }
                    // onChange={this.handleChange}
                  />
                  ,
                </div>
              </div>
              <div className="text-center">
                <a href="#">
                  <input
                    type="button"
                    value="Confirm"
                    className="formButtonBg mx-1"
                    onClick={() =>
                      this.confirmAppointmentPopUp(this.props.appId)
                    }
                  ></input>
                </a>
                <input
                  type="button"
                  value="cancel"
                  className="formButtonBg cancelButtonBg mx-1 popup-hide"
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

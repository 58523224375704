import React, { Component, Suspense } from "react";
import $ from "jquery";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { apiCalling } from "../apiService";
import { getSiteData } from "../layout/getSiteDetails";
import bolgtemp from "../image/icons/blogging.png";
import renderHTML from "react-render-html";
import { Helmet } from "react-helmet";
import * as ReactDOM from "react-dom";
import edit from "../image/icons/edit.png";
import plus from "../image/icons/plus.png";
import ReactPlayer from "react-player";
import { S3ImagePath } from "../config";
const imgPath = S3ImagePath();

const folderName = window.localStorage.getItem("homeName");
let imageFlag = "";
let vcLinks = "";
const Home = React.lazy(() => import("../" + folderName + "/home"));
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
// const Gallery = React.lazy(() => import("../" + folderName + "/gallery"));
const Gallery = React.lazy(() => import("../" + folderName + "/gallery"));

export default class DrGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bouquetData: [],
      SubList: [],
      sectionName: "Bouquet",
      parentName: "",
      imageFlag: "",
      videFlag: "",
    };
  }
  componentDidMount() {
    // console.log("prooooooooooops",this.props);
    this.awsCredentials();
    const Bouquet = JSON.parse(window.localStorage.getItem("Bouquet"));
    if (Bouquet) {
      this.setState({ bouquetData: Bouquet });
      this.setState({ SubList: Bouquet[0].sectionList });
      this.setState({ parentName: Bouquet[0].parentName });
    } else {
      //window.location.reload(false);
    }
    const themeContent = {
      method: "POST",
      body: JSON.stringify({
        functionName: "themeList",
        siteId: window.localStorage.getItem("siteId"),
        themeId: window.localStorage.getItem("themeId"),
      }),
    };
    // setInterval(async () => {
    apiCalling(themeContent).then((data) => {
      if (data.success == "1") {
        for (let i = 0; i < data.themeSectionList.length; i++) {
          window.localStorage.setItem(
            data.themeSectionList[i].themeSectionName,
            JSON.stringify(data.themeSectionList[i].themeSectionContent)
          );
        }
      }
    });
  }
  awsCredentials() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        siteId: window.localStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        window.localStorage.setItem(
          "s3bucketAccessKey",
          data.configPathDetails[0].s3bucketAccessKey
        );
        window.localStorage.setItem(
          "AWSAccessKeyId",
          data.configPathDetails[0].s3bucketSecretKey
        );
        // window.localStorage.setItem("bucket", data.configPathDetails[0].S3BucketMainFolder);
        window.localStorage.setItem(
          "bucket",
          data.configPathDetails[0].s3BucketReliance
        );
        window.localStorage.setItem(
          "S3BucketMainFolder",
          data.configPathDetails[0].S3BucketMainFolder
        );
      }
    });
  }
  addClick(
    sid,
    pId,
    cId,
    psubTitle,
    cTitile,
    cdescri,
    childUrl,
    childImg,
    sOrder
  ) {
    if ($("#BouquetParentId").val() !== "") {
      pId = $("#BouquetParentId").val();
    }
    if (childImg) {
      var splitImg = childImg.split("theme/");
      childImg = splitImg[1];
    }
    const LazyPage = React.lazy(() =>
      import("../" + folderName + "/ThemeComponent")
    );
    ReactDOM.render(
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <section>
            <LazyPage
              sessionId={sid}
              parentId={pId}
              childId={cId}
              patentTitle={this.state.parentName}
              parentSubTitle={psubTitle}
              childTitle={cTitile}
              childDesri={cdescri}
              sectionName={this.state.sectionName}
              childUrl={childUrl}
              childImg={childImg}
              sortSubOrder={sOrder}
            />
          </section>
        </Suspense>
      </div>,
      document.getElementById("gallarypopUpBlock")
    );
    $("#gallarypopUpBlock").addClass("editActive");
  }

  checkURL(url) {
    return url.match(/\.(jpeg|jpg|gif|png|MOV|mp4|MKV)$/) != null;
  }

  render() {
    const themeFlag ="theme"
    var settingsGallry = {
      dots: false,
      infinite: false,
      arrows: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    window.$(document).ready(function () {
      window.$(".popup-gallery").magnificPopup({
        delegate: "a",
        type: "iframe",
        tLoading: "Loading...",
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1],
        },
      });
      window.$(".popup-image").magnificPopup({
        delegate: "a",
        type: "image",
        tLoading: "Loading...",
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1],
        },
      });
    });
    const urlPath = window.location.origin.toString();
    return (
      <div className="pamba-wrapper">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <Helmet>
          <title>
            {"HealthInfo | " + window.localStorage.getItem("siteName") ===
            null
              ? ""
              : window.localStorage.getItem("siteName")}
          </title>
          <meta
            name="description"
            content={
              "HealthInfo | " + window.localStorage.getItem("siteName") ===
              null
                ? ""
                : window.localStorage.getItem("siteName")
            }
          />
        </Helmet>

        <Suspense fallback={<div></div>}>
          <section>
           <div className="themeSubCover">
            <Gallery changeTheme={"theme"} themeFlag={"theme"} themeChange={"theme"}/>
           </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

import React, { Component, Suspense } from "react";

import $ from "jquery";
import { Link } from "react-router-dom";
import { apiCalling } from "../apiService";
import phone from "../image/theme/phoneg.png";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class Favorate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      favorateArrayRemove: [],
      doctorId: [],
      favorateArray: []
    };
  }
  addToFavourites(e, docId) {
    var doctorIdfromList = []
    for (let i = 0; i < this.state.favorateArray.length; i++) {
      doctorIdfromList.push(this.state.favorateArray[i].doctorId)
    }
    let index = doctorIdfromList.indexOf(docId);
    doctorIdfromList.splice(index, 1);
    if (doctorIdfromList.length > 0) {
      $(".loader").show();
      const jsonData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "addToFavourites",
          siteId: window.localStorage.getItem("siteId"),
          userId: window.localStorage.getItem("userId"),
          favouriteUserId: docId,
          removeFavourites: e.target.checked,
          favouriteUserDetails: doctorIdfromList.toString().replaceAll(",", "|"),
        }),
      };
      apiCalling(jsonData).then((data) => {
        $(".loader").hide();
        if (data.success === "1") {
          alert(data.successMessage);
          this.loadFirstData();
        }
      });
    }
    else {
      this.setState({
        favorateArray: [],
      });
      alert("All Favourites Removed")
    }
  }
  componentDidMount() {
    this.loadFirstData();
  }
  loadFirstData() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        "functionName": "myFavourites",
        "userId": window.localStorage.getItem("userId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        // $(".hiddenTestDiv").show();
        $(".loader").hide();
        this.setState({
          favorateArray: data.doctorList,
        });
      }
      else {
        this.setState({
          favorateArray: [],
        });
      }
      ;

    })
  }

  render() {
    return (
      <div className="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        {/* <div class="purpleWrap">        */}
        <div class="container">
          <div class="main-wrapper">
            <div class="form-head">Favourite </div>
            {this.state.favorateArray.length > 0 ?
              <div className="row">
                {this.state.favorateArray.map((obj) => {
                  return (
                    <div className="col-md-3 Dr-details">
                      <div className="row">
                        <div className="col-md-2">
                          <label className="drListHearts imgshowhide">
                            <input
                              id="fav"
                              type="checkbox"
                              defaultChecked
                              onChange={(e) =>
                                this.addToFavourites(e, obj.doctorId)
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>

                        <div className="col-md-10">
                          <Link
                            to={{
                              pathname: "/Doctor-" + obj.slug,
                              doctorId: obj.doctorId,
                              hospitalId: obj.hospitalId,
                            }}
                          >
                            <div className="doctorDetails-favourite">
                              <img src={obj.doctorImage} alt="" />
                              <div className="dr-details">
                                <div title={obj.doctorName}>{obj.doctorName}</div>
                                <div title={obj.speciality}>{obj.speciality}</div>
                                <div title={obj.hospitalName}>{obj.hospitalName}</div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>

                    </div>
                  )
                })}
              </div>
              :
              "No Data Available"
            }
          </div>
        </div>

        {/* </div> */}
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

import React, { useState, useEffect, Suspense, useRef } from "react";
import $ from "jquery";
import { apiCalling } from "../apiService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import rightarrow from "../image/icons/ht-arrow-right.png";
import htimmune from "../image/icons/ht-immune.png";

const HealthTrackbabymilestns = () => {
  const [babyTxtName, setBabyTxtName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [Header, setHeader] = useState(null);
  const [Footer, setFooter] = useState(null);
  const babyTxtNameRef = useRef(null);
  // const dateOfBirthRef = useRef(null);
  const folderName = window.localStorage.getItem("homeName");
  useEffect(() => {
    const loadComponents = async () => {
      if (folderName) {
        const HeaderComp = React.lazy(() => import(`../${folderName}/header`));
        const FooterComp = React.lazy(() => import(`../${folderName}/footer`));
        setHeader(() => HeaderComp);
        setFooter(() => FooterComp);
      }
    };
    loadComponents();
  }, [folderName]);
  
  const handleChange = (event) => {
    const { value, name } = event.target;
    if (name === 'babyTxtName') {
      setBabyTxtName(value);
    }
  };
  const handleChangeDate = (date) => {
    setDateOfBirth(date);
  };
  const checkHandleDetails = () => {
    if (!babyTxtName) {
      alert("Please Enter Name of the Baby");
      babyTxtNameRef.current.focus();
      return;
    }
    if (!dateOfBirth) {
      alert("Please Select Date of Birth");
      $("#txtDateOfBirth").focus();
      // dateOfBirthRef.current.focus();
      return;
    }

    const babyDob = dateOfBirth.toISOString().split('T')[0];

    const detailsCheck = {
      method: 'POST',
      body: JSON.stringify({
        functionName: "getHealthSchedule",
        healthTrackerTypeId: "1",
        userId: window.localStorage.getItem("userId"),
        dateOfBirth: babyDob
      })
    };

    $(".loader").show();
    apiCalling(detailsCheck).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        window.localStorage.setItem('babyName', babyTxtName);
        window.localStorage.setItem('dob', babyDob);
        window.location.href = "/health-trackers-immunisation-download-pdf";
      } else if (data.success === "0") {
        alert("No Data Found");
      }
    }).catch(() => {
      $(".loader").hide();
      alert("An error occurred. Please try again.");
    });
  };

  return (
    <div className="purpleWrap ph-selectDisable ph-mainWrap">
      <Suspense fallback={<div></div>} key={Header}>
        <section >
          <div className='loader'></div>
          {Header && <Header />}
          <div className="container container-auto">
            <div className="main-wrapper1">
              <h1 className="text-center telehealthfeaturehead"><span>Immunization Schedule</span> </h1>
              <div className="row">
                <div className="col-lg-6">
                  <div className="health-track-card">
                    <div className="health-trck-img">
                      <img src={htimmune} alt="Immunization" />
                    </div>
                    <p>Immunization Schedule</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="health-track-card-right">
                    <div className="form-section mt-2">
                      <div className="row">
                        <div className="col-lg-12 row pl-3 d-center">
                          <label className="col-5 label-text label-text-cln text-right">
                            Name of the Baby
                          </label>
                          <label className="col-5 text-uppercase">
                            <input 
                              type="text" 
                              className="input-design mb-0" 
                              id="babyTxtName" 
                              name="babyTxtName" 
                              value={babyTxtName} 
                              onChange={handleChange}
                              ref={babyTxtNameRef}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-section mt-2">
                      <div className="row">
                        <div className="col-lg-12 row pl-3 d-end-center">
                          <label className="col-5 label-text label-text-cln text-right">
                            Date of Birth
                          </label>
                          <div className="col-sm-5 col-5 ph-calendar">
                            <DatePicker
                              placeholderText="Date of Birth"
                              id="txtDateOfBirth" 
                              name="txtDateOfBirth"
                              maxDate={new Date()}
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={100}
                              dropdownMode="scroll"
                              peekNextMonth
                              disabledKeyboardNavigation
                              selected={dateOfBirth}
                              onChange={handleChangeDate}
                              dateFormat="dd/MM/yyyy"
                              calendarClassName="react-date-picker"
                              autoComplete="off"
                              tabIndex="8"
                              // ref={dateOfBirthRef}
                            />
                          </div>
                          <label className="col-2">
                            <button className="ht-button" onClick={checkHandleDetails}>
                              <img src={rightarrow} alt="Submit" />
                            </button>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {Footer && <Footer />}
        </section>
      </Suspense>
    </div>
  );
};

export default HealthTrackbabymilestns;

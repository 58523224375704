import React, { Component, Suspense } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import videoImg from "../image/theme/video1.png";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { apiCalling } from "../apiService";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class ChatHistory extends Component {

  constructor(props) {
    super(props);

    this.state = {

      startDate: new Date(),
      videoChatData: [],
      toDate: "",
      fromDate: "",
      totalCount: 0,
      activePage: 1,
    };
    //this.searchHandler = this.searchHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: '0'
      })
    }
    else {
      offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: '0'
      })
    }
    let todateT = moment(this.state.toDate);
    let fromdateT = moment(this.state.fromDate);
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorVideoChatHistory",
        doctorId: window.localStorage.getItem("userId"),
        max: max,
        Offset: offset,
        fromDate: fromdateT.format("DD/MM/YYYY") == "Invalid date"
          ? ""
          : fromdateT.format("DD/MM/YYYY"),
        toDate: todateT.format("DD/MM/YYYY") == "Invalid date"
          ? ""
          : todateT.format("DD/MM/YYYY"),
      }),
    };
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
        this.setState({
          videoChatData: data.videoChatHistoryData,
          totalCount: data.totalCount,
        });
        $(".loader").hide();
      }
    });
  }


  componentDidMount() {

    this.doctorVideoChatHistory();

  }

  doctorVideoChatHistory() {

    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorVideoChatHistory",
        doctorId: window.localStorage.getItem("userId"),
        max: "10",
        Offset: "0",
        fromDate: "",
        toDate: "",
      }),
    };
    //console.log(apiJson.body);
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      //console.log("totalCount", data)
      if (data.success == "1") {
        this.setState({
          videoChatData: data.videoChatHistoryData,
          totalCount: data.totalCount,
        });
        $(".loader").hide();
      }
    });
  }

  searchHandler() {

    let todateT = moment(this.state.toDate);
    let fromdateT = moment(this.state.fromDate);
    if (todateT === "" && fromdateT === "") {
      this.doctorVideoChatHistory();
    } else {
      const searchData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "doctorVideoChatHistory",
          doctorId: window.localStorage.getItem("userId"),
          max: "10",
          Offset: "0",
          fromDate: fromdateT.format("DD/MM/YYYY") == "Invalid date"
            ? ""
            : fromdateT.format("DD/MM/YYYY"),

          toDate: todateT.format("DD/MM/YYYY") == "Invalid date"
            ? ""
            : todateT.format("DD/MM/YYYY"),
        }),
      };
      // console.log(searchData.body)
      $(".loader").show();
      apiCalling(searchData).then((data) => {
        $(".loader").show();
        this.setState({ toDate: "", fromDate: "" })
        if (data.success == "1") {
          //console.log("seach Data", data)
          this.setState({
            videoChatData: data.videoChatHistoryData,
            totalCount: data.totalCount,
            activePage: 1,
          });
        }
        else {
          alert("No Data Found in selected date's")
        }
      })
    }
  }

  handleChange = (date) => {
    this.setState({
      fromDate: date,
    });
  };
  handleChangeTo = (date) => {
    this.setState({
      toDate: date,
    });
  };


  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
            <div className='loader'></div>
            <div class="container">
              <div class="main-wrapper1">
                <div class="col-md-12 p-0">
                  <ul class="breadcrumb">
                    <li>
                      <Link to="/doctor/dashboard">Dashboard</Link>
                      <i class="fa fa-chevron-right"></i>
                    </li>
                    <li>Video chat history</li>
                  </ul>
                </div>

                <div class="form-head"> Video chat history </div>
                <div class="custom-search row">
                  <div class="col-md-4 col-6 mb-3 custom-search-sub">
                    <DatePicker
                      placeholderText="From Date"
                      id="fromDate"
                      name="fromDate"
                      selected={this.state.fromDate}
                      onChange={this.handleChange}
                      dateFormat="dd/MM/yyyy"
                      calendarClassName="react-date-picker"
                    />
                  </div>
                  <div class="col-md-4 col-6 mb-3 custom-search-sub">
                    <DatePicker
                      placeholderText="To Date"
                      id="toDate"
                      name="toDate"
                      selected={this.state.toDate}
                      onChange={this.handleChangeTo}
                      dateFormat="dd/MM/yyyy"
                      calendarClassName="react-date-picker"
                    />
                  </div>
                  <div class="col-md-4 col-12 mb-3 custom-search-sub">
                    <input
                      type="submit"
                      value="Search"
                      class="formButton m-0"
                      onClick={() => this.searchHandler()}
                    />
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th> Date</th>
                        <th>Patient</th>
                        <th>Start time</th>
                        <th>End time</th>
                        {/* <th>Billing start time</th>
                        <th>Billing end time</th> */}
                        {/* <th>Billed Time (minutes)</th> */}
                        {/* <th>Charge/Minute</th> */}
                        <th>Charge/Session</th>
                        <th>Amount</th>
                        <th>Video Recorded</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "12px" }}>
                      {this.state.videoChatData.map((data) => {
                        let videoStartTime = new Date(data.videoStartTime);
                        let videoEndTime = moment(data.videoEndTime, "DD/MM/YYYY hh:mm a").format("ddd MMM DD YYYY hh:mm a");
                        //  console.log(data.videoEndTime)
                        return (
                          <tr>
                            <td>{data.chatDate}</td>
                            <td className="whitespace-nowrap">{data.firstName}</td>
                            <td>{videoStartTime.toDateString()}{" "}
                              {videoStartTime.toLocaleTimeString("en-IN", {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              })}</td>
                            <td>
                              {videoEndTime}</td>
                            {/* <td> {data.billingStartTime} </td>
                          <td> {data.billingEndTime} </td>
                          <td> {data.billingStartTime}</td> */}
                            {/* <td>₹ {data.ratePerMinute}</td> */}
                            <td className="whitespace-nowrap"> ₹ {data.ratePerSession}</td>
                            <td className="whitespace-nowrap"> ₹ {data.billedAmount}</td>
                            <td>
                              <Link to={"/doctor/videoRecordList/" + data.appointmentId}
                              >
                                <img src={videoImg} width="20" className="" alt=""></img>
                              </Link>
                            </td>
                          </tr>

                        )
                      })}
                    </tbody>
                  </table>
                </div>
                {this.state.totalCount > 5 ?
                  <div className="paginationSection orderm-paginationSection">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.totalCount}
                      pageRangeDisplayed={10}
                      onChange={this.handlePageChange}
                    />
                  </div>
                  : ""}
              </div>
            </div>

            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

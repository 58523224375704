import React, { Component, Suspense } from "react";
import $ from "jquery";
import { apiCalling } from "../apiService";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import htimmune from "../image/icons/ht-immune.png";
import { Link } from "react-router-dom";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class HealthTrackimmunisationdwd extends Component {
  constructor(props) {
    super(props);

    this.state = {

      babyName: window.localStorage.getItem('babyName'),
      babyDOB: window.localStorage.getItem('dob'),
      healthScheduleData: [],

    };
  }

  componentDidMount() {

    this.babyDetails();

  }

  babyDetails() {
    const Listing = {
      method: 'POST',
      body: JSON.stringify({
        functionName: "getHealthSchedule",
        healthTrackerTypeId: "1",
        userId: "", //window.localStorage.getItem("userId"),
        dateOfBirth: this.state.babyDOB
      })
    }
    $(".loader").show();
    apiCalling(Listing).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        this.setState({
          healthScheduleData: data.healthScheduleData,
        });
      }
    })
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div ></div>}>
          <section>
            <div className='loader'></div>
            <Header />
            <div class="container container-auto">
              <div class="main-wrapper1">
                <div class="col-sm-12 form-head text-center">Immunization Schedule</div>
                <div className="row">
                  <div className="col-lg-5">
                    <div className="health-track-card">
                      <div className="health-trck-img">
                        <img src={htimmune} />
                      </div>
                      <p>Immunization Schedule</p>
                    </div>
                  </div>
                  <div className="col-lg-7 ">
                    <div className="health-track-card-right hlth-immunetb">

                      <div class="form-section mt-2">
                        <div class="row">
                          <div class="col-lg-12 row pl-3 d-center">
                            <label class="col-12"> <div className="row pl-1">Name : {this.state.babyName}  <br /> Date of Birth : {moment(this.state.babyDOB, 'DD/MM/YYYY').format("DD MMM YYYY")}</div></label>
                            <table>
                              <thead >
                                <tr>
                                  <th scope="col">Date</th>
                                  <th scope="col"> Vaccines</th>
                                  <th scope="col"> Diseases</th>
                                  <th scope="col"> NIS</th>
                                  <th scope="col"> IAP</th>
                                </tr>
                              </thead>
                              <tbody >
                                <div className="tbody">
                                  {this.state.healthScheduleData.map((data) => {
                                    let dayToVisit = moment(data.dayToVisit, 'DD-MM-YYYY').format("DD MMM YYYY");
                                    return (
                                      <tr>
                                        <td  scope="row" data-label="Date" className="immunisationTd">{dayToVisit}</td>
                                        <td data-label="Vaccines">{data.vaccineName}</td>
                                        <td data-label="Diseases">{data.diseasesName}</td>
                                        <td data-label="NIS">{data.nis}</td>
                                        <td data-label="IAP">{data.iap}</td>
                                      </tr>
                                    )
                                  })}
                                </div>
                              </tbody>
                            </table>
                            <div className="ht-dwnldbutton">
                              <Link to={{
                                pathname: "/health-trackers-immunisation-download-pdf",
                              }}><input type="submit" class="formButton formButtonBold" value="Preview"></input>
                              </Link>
                              {/* <Link to={{
                                pathname: "/patient/health-trackers-immunisation-reminders",
                              }}><input type="submit" class="formButton formButtonBold" value="Set Reminders"></input>
                              </Link> */}
                            </div>
                            <div className="ht-terms">
                               <p> NIS Schedule: Recommended by MoHFW. Mandatory.</p>
                                  <p> IAP Schedule: Recommended by Indian Academy of pediatrics. Optional</p>
                                  <p>Disclaimer:</p>
                                  <p>Please note: No two babies are exactly alike. Your baby will develop at his/ her own pace. Most babies reach certain milestones at similar ages. However, it's not unusual for a healthy, “normal” baby to fall behind in some areas or race ahead in others.</p>
                               <p>This information is not intended to, and does not constitute, medical or other health advice or diagnosis and should not be used as such. You should always consult with a qualified medical practitioner about your specific circumstances for the right guidance.If your baby was born prematurely (before 37 weeks of pregnancy), you need to look at the milestone guidelines a little differently. The age at which your baby is expected to reach various milestones is based on his/ her due date, not the birthday. So if your baby was born two months early, he/ she will most likely achieve milestones two months later than the guidelines mentioned.</p>
                                  <p>Source: Medline, MedIndia, IAP, TheBump</p>
                                  <p>This is only for guidance. Please check with Doctor for suitable schedule for your child"</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

import React, { Component, Suspense } from "react";

import Dashboardleftmenu from "./dashboardleftmenu";
import $ from "jquery";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class WorkTime extends Component {
  componentDidMount() {
    $("#workingTime").addClass("dashBoardLeftMenu-active");
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container container-auto">
          <div class="main-wrapper1 mt-2 pt-0">
            <div class="row">
              <div class="col-lg-3">
                <Dashboardleftmenu></Dashboardleftmenu>
              </div>
              <div class="col-lg-9">
                <div class="form-head mt-4">Doctor Schedule Management</div>
                <div class="dashboard-right form-section px-2">
                  <div class="m-0 form-head text-center"> Office Hours</div>
                  <div class="m-0 form-subhead text-muted text-capitalize text-center">
                    {" "}
                    Xilligence Clinic Test ortho{" "}
                  </div>

                  <div class="row row-margin mt-4">
                    <label class="col-2 week-text"></label>
                    <div class="col-5 px-2 label-text-brdr"> schedule 1 </div>
                    <div class="col-5 px-2 label-text-brdr"> schedule 2 </div>
                  </div>
                  <div class="row row-margin mt-4">
                    <label class="col-2 week-text">Monday</label>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2">
                        <select
                          id="input-design"
                          class="input-design input-designBold"
                        >
                          <option value="From">From</option>
                          <option value="0">09:30 AM</option>
                        </select>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">To</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">From</option>
                        </select>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">To</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row row-margin">
                    <label class="col-2 week-text">Tuesday</label>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">From</option>
                        </select>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">To</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">From</option>
                        </select>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">To</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row row-margin">
                    <label class="col-2 week-text">Wednesday</label>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">From</option>
                        </select>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">To</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">From</option>
                        </select>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">To</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row row-margin">
                    <label class="col-2 week-text">Thursday</label>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">From</option>
                        </select>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">To</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">From</option>
                        </select>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">To</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row row-margin">
                    <label class="col-2 week-text">Friday</label>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">From</option>
                        </select>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">To</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">From</option>
                        </select>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">To</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row row-margin">
                    <label class="col-2 week-text">Saturday</label>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">From</option>
                        </select>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">To</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">From</option>
                        </select>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">To</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row row-margin">
                    <label class="col-2 week-text">Sunday</label>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">From</option>
                        </select>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">To</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">From</option>
                        </select>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2">
                        <select class="input-design input-designBold">
                          <option value="0">To</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 px-0 mt-3 text-center text-lg-left">
                  <a href="#">
                    <input
                      type="submit"
                      value="Update"
                      className="formButton formButtonBold"
                    ></input>
                  </a>
                  <input
                    type="button"
                    value="cancel"
                    className="formButtonBold cancelButton"
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
import { S3ImagePath } from "../config";
import { apiCalling } from "../apiService";
import InnerHTML from "dangerously-set-html-content";
import tick from "../image/icons/tick.png";
import purple from "../image/media/purple.png";
const imgPath = S3ImagePath();
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class HospitalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      hide: false,
      hospitalId: props.match.params.hospitalId,
      hospitalDetails: null,
    };
    this.getHospitalDetails = this.getHospitalDetails.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    // console.log("proosososososospsssss", nextProps.match.params.hospitalId);
    // console.log("proosososososospsssss", this.props.match.params.hospitalId);
    if (
      nextProps.match.params.hospitalId !== this.props.match.params.hospitalId
    ) {
      window.location.reload();
    }
  }
  getHospitalDetails() {
    const hospitalDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "IMAhospitalProfile",
        hospitalId: this.state.hospitalId,
        siteId: window.localStorage.getItem("siteId"),
      }),
    };

    apiCalling(hospitalDetails).then((data) => {
      // console.log(data);
      if (data.success === "1") {
        this.setState({
          hospitalDetails: data.result,
        });
      }
    });
  }
  componentDidMount() {
    this.getHospitalDetails();
  }

  showup = (e) => {
    e.preventDefault();
    this.setState({ show: !this.state.show });
    this.setState({ hide: !this.state.hide });
  };
  render() {
    return (
      <div className="main">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <main role="main">
          <div className="subpage_container ">
            <div className="container">
              <div className="content_help">
                <h1 className="default_header">Hospitals</h1>
                <div className="help_border">
                  <div className="hospital_details">
                    <div className="hospdetails_left">
                      <Link to="" className="d-block">
                        <img
                          src={
                            this.state.hospitalDetails
                              ? this.state.hospitalDetails.hospitalImageResult
                                  .length > 0
                                ? this.state.hospitalDetails
                                    .hospitalImageResult[0].photo
                                : purple
                              : purple
                          }
                          onClick={(e) => this.showup(e)}
                          alt="hospital"
                          width="200"
                          height="200"
                        />
                      </Link>
                    </div>
                    <div className="hospdetails_right text-left">
                      <p className="details_heading">
                        {this.state.hospitalDetails
                          ? this.state.hospitalDetails.hospitalResult[0].hName
                          : ""}
                      </p>
                      <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6 hosp_address">
                          {this.state.hospitalDetails
                            ? this.state.hospitalDetails.hospitalResult[0]
                                .hAddress
                            : ""}
                          <br />
                          {this.state.hospitalDetails
                            ? this.state.hospitalDetails.hospitalResult[0].city
                            : ""}
                          <br />
                          {this.state.hospitalDetails
                            ? this.state.hospitalDetails.hospitalResult[0].state
                            : ""}{" "}
                          -{" "}
                          {this.state.hospitalDetails
                            ? this.state.hospitalDetails.hospitalResult[0].zip
                            : ""}
                          <br />
                          Phone :{" "}
                          {this.state.hospitalDetails
                            ? this.state.hospitalDetails.hospitalResult[0]
                                .hPhone
                            : ""}
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 hosp_time">
                          <div className="hosp_timehead">Timings</div>
                          <ul className="nav flex-column">
                            <li className="nav-item">
                              <span className="hosp_timeleft">
                                Monday<span className="colon_right">:</span>
                              </span>
                              <span className="hosp_timeright">
                                <span className="timer_span">
                                  {this.state.hospitalDetails
                                    ? this.state.hospitalDetails
                                        .weektimeDetails[0].monTime
                                    : ""}
                                </span>
                                {this.state.hospitalDetails &&
                                this.state.hospitalDetails
                                  .weektimeDetails[1] ? (
                                  <span>
                                    <span className="time_divider">&</span>
                                    <span className="timer_span">
                                      {
                                        this.state.hospitalDetails
                                          .weektimeDetails[1].monTime
                                      }
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </li>
                            <li className="nav-item">
                              <span className="hosp_timeleft">
                                Tuesday<span className="colon_right">:</span>
                              </span>
                              <span className="hosp_timeright">
                                <span className="timer_span">
                                  {this.state.hospitalDetails
                                    ? this.state.hospitalDetails
                                        .weektimeDetails[0].tueTime
                                    : ""}
                                </span>
                                {this.state.hospitalDetails &&
                                this.state.hospitalDetails
                                  .weektimeDetails[1] ? (
                                  <span>
                                    <span className="time_divider">&</span>
                                    <span className="timer_span">
                                      {
                                        this.state.hospitalDetails
                                          .weektimeDetails[1].tueTime
                                      }
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </li>
                            <li className="nav-item">
                              <span className="hosp_timeleft">
                                Wednesday<span className="colon_right">:</span>
                              </span>
                              <span className="hosp_timeright">
                                <span className="timer_span">
                                  {this.state.hospitalDetails
                                    ? this.state.hospitalDetails
                                        .weektimeDetails[0].wedTime
                                    : ""}
                                </span>
                                {this.state.hospitalDetails &&
                                this.state.hospitalDetails
                                  .weektimeDetails[1] ? (
                                  <span>
                                    <span className="time_divider">&</span>
                                    <span className="timer_span">
                                      {
                                        this.state.hospitalDetails
                                          .weektimeDetails[1].wedTime
                                      }
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </li>
                            <li className="nav-item">
                              <span className="hosp_timeleft">
                                Thursday<span className="colon_right">:</span>
                              </span>
                              <span className="hosp_timeright">
                                <span className="timer_span">
                                  {this.state.hospitalDetails
                                    ? this.state.hospitalDetails
                                        .weektimeDetails[0].thuTime
                                    : ""}
                                </span>
                                {this.state.hospitalDetails &&
                                this.state.hospitalDetails
                                  .weektimeDetails[1] ? (
                                  <span>
                                    <span className="time_divider">&</span>
                                    <span className="timer_span">
                                      {
                                        this.state.hospitalDetails
                                          .weektimeDetails[1].thuTime
                                      }
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </li>
                            <li className="nav-item">
                              <span className="hosp_timeleft">
                                Friday<span className="colon_right">:</span>
                              </span>
                              <span className="hosp_timeright">
                                <span className="timer_span">
                                  {this.state.hospitalDetails
                                    ? this.state.hospitalDetails
                                        .weektimeDetails[0].friTime
                                    : ""}
                                </span>
                                {this.state.hospitalDetails &&
                                this.state.hospitalDetails
                                  .weektimeDetails[1] ? (
                                  <span>
                                    <span className="time_divider">&</span>
                                    <span className="timer_span">
                                      {
                                        this.state.hospitalDetails
                                          .weektimeDetails[1].friTime
                                      }
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </li>
                            <li className="nav-item">
                              <span className="hosp_timeleft">
                                Saturday<span className="colon_right">:</span>
                              </span>
                              <span className="hosp_timeright">
                                <span className="timer_span">
                                  {this.state.hospitalDetails
                                    ? this.state.hospitalDetails
                                        .weektimeDetails[0].satTime
                                    : ""}
                                </span>
                                {this.state.hospitalDetails &&
                                this.state.hospitalDetails
                                  .weektimeDetails[1] ? (
                                  <span>
                                    <span className="time_divider">&</span>
                                    <span className="timer_span">
                                      {
                                        this.state.hospitalDetails
                                          .weektimeDetails[1].satTime
                                      }
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </li>
                            <li className="nav-item">
                              <span className="hosp_timeleft">
                                Sunday<span className="colon_right">:</span>
                              </span>
                              <span className="hosp_timeright">
                                <span className="timer_span">
                                  {this.state.hospitalDetails
                                    ? this.state.hospitalDetails
                                        .weektimeDetails[0].sunTime
                                    : ""}
                                </span>
                                {this.state.hospitalDetails &&
                                this.state.hospitalDetails
                                  .weektimeDetails[1] ? (
                                  <span>
                                    <span className="time_divider">&</span>
                                    <span className="timer_span">
                                      {
                                        this.state.hospitalDetails
                                          .weektimeDetails[1].sunTime
                                      }
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 hospt_timings">
                          <div className="hosp_timehead">Services</div>
                          <span>
                            {this.state.hospitalDetails ? (
                              this.state.hospitalDetails.serviceDetails[0]
                                .serviceOffered ? (
                                <InnerHTML
                                  html={
                                    this.state.hospitalDetails.serviceDetails[0]
                                      .serviceOffered
                                  }
                                />
                              ) : (
                                "Not Available"
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hosp_description text-left">
                    <p className="head_special">Speciality</p>
                    <p className="para_special">
                      {this.state.hospitalDetails
                        ? this.state.hospitalDetails.specialityResult.map(
                            (data) => data.specializationName
                          )
                        : ""}
                    </p>
                  </div>
                  <div className="hosp_description text-left">
                    <p className="head_special">About</p>
                    <p className="para_special">
                      {this.state.hospitalDetails ? (
                        <InnerHTML
                          html={
                            this.state.hospitalDetails.officeDetails[0]
                              .officeDetails
                          }
                        />
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                </div>
                <div className="hosp_doctorDetails text-left">
                  <div className="row mx-0">
                    <div className="col-xl-8 col-md-12">
                      <p className="head_speciall">Doctors</p>
                      {this.state.hospitalDetails
                        ? this.state.hospitalDetails.doctorsBySpecializationResult.map(
                            (data) => (
                              <div className="doctor_listItem">
                                <div className="doctor_listItemInner">
                                  <div className="doctor_innerImg">
                                    <img
                                      src={
                                        imgPath + "/doctorimages/doctor_194.jpg"
                                      }
                                      alt="doctor"
                                      width="200"
                                      height="200"
                                    />
                                  </div>
                                  <div className="doctor_innerdescription">
                                    <div className="doctordec_Left">
                                      <div className="details_heading">
                                        {data.doctorName}
                                      </div>
                                      <ul className="nav flex-column">
                                        <li className="">
                                          {data.specializationDescription}
                                        </li>
                                        <li className="">{data.hName}</li>
                                        <li className="">
                                          {data.hospitalAddress}
                                        </li>
                                        <li className="">
                                          {data.city} - {data.zip}695010
                                        </li>
                                        <li className="">{data.state}</li>
                                      </ul>
                                    </div>
                                    <div className="doctordec_Right">
                                      <p className="docto_rightpara">
                                        <span className="docto_rightparaimg">
                                          <img
                                            src={tick}
                                            alt="tick"
                                            width="50"
                                            height="60"
                                          />
                                        </span>
                                        <span className="span_doctoruser">
                                          {data.education}
                                        </span>
                                      </p>
                                      <p className="docto_rightpara">
                                        <span className="docto_rightparaimg">
                                          <img
                                            src={tick}
                                            alt="tick"
                                            width="50"
                                            height="60"
                                          />
                                        </span>
                                        <span className="span_doctoruser">
                                          Rs.{data.consultation_price}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 text-right flex_buttons">
                                  <Link
                                    to={
                                      "/Doctor-" +
                                      data.slug
                                        .replaceAll("|", "-")
                                        .replaceAll(" ", "")
                                    }
                                    className="btn_view view_profile"
                                  >
                                    View Profile
                                  </Link>
                                  <Link
                                    to={
                                      "/Doctor-" +
                                      data.slug
                                        .replaceAll("|", "-")
                                        .replaceAll(" ", "")
                                    }
                                    className="btn_view book_appoinment"
                                  >
                                    Book Appoinment
                                  </Link>
                                </div>
                              </div>
                            )
                          )
                        : ""}
                    </div>

                    <div className="col-xl-4 col-md-12"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
        {this.state.hide && (
          <div
            className="modal_popup"
            modalOpen={this.state.modalOpen}
            handleModalOpen={this.handleModalOpen}
          >
            <div className="modal_innerpop">
              <div className="modal_header">
                <span
                  className="modal_close mr-3 text-white"
                  onClick={this.showup}
                >
                  x
                </span>
              </div>
              <div className="modal_body">
                <img
                  src={imgPath + "/hospital_details.jpg"}
                  className="modal_img"
                  alt="hospital"
                  width="500"
                  height="600"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

import React, { Component, Suspense } from "react";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
// import Header from "../../layout/header";
// import Footer from "../../layout/footer";
import Plus from "../../image/icons/plus.png";
import AWS from "aws-sdk";
import showImg from "../../image/icons/blogging.png";
import Closebtn from "../../image/icons/close-sm.png";
import download from "../../image/icons/download.png";
import cancel from "../../image/icons/sh-cancel.png";
import pdf from "../../image/icons/pdf.png";
import attachment from "../../image/icons/attachment.png";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../../" + folderName + "/header"));
const Footer = React.lazy(() => import("../../" + folderName + "/footer"));
var s3;
var copyBranches
var imageFile;

export default class AddJournal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleTxt: "",
      siteDataTxt:window.localStorage.getItem("siteId"),
      fileNameOnly: "",
      fileNameWithPath: "",
      imageNameWithPath: [],
      imageName: [],
      imageNameWithPathLocal: [],
      imageNameLocal: [],
      siteList: [],
      s3bucketAccessKey: "",
      AWSAccessKeyId: "",
      region: "us-east-1",
      bucket: "",
      bucketFolder: "",
      S3BucketBlogImages: "",
      branchesDropdown: [],
      branchData: [],
      displayForBranches: false,
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML("<p></p>"))
      ),
    };
  }
  componentDidMount() {
    this.branchDropdownList();
    this.imageUploadHandler();
    this.getSiteDetails();
    if (this.props.match.params.journalId) {
      this.getjournalDetails();
    }
  }
  handleBranchesAutoFill = (e) => {
    if (e.target.value) {
      copyBranches = [...this.state.branchData];
      copyBranches.push({ branchName: e.target.value })
      this.setState({ branchData: copyBranches });
    }
  }
  handleCancelBranch(id, i) {
    let copyBranchData = [...this.state.branchData];
    copyBranchData.splice(i, 1);
    this.setState({ branchData: copyBranchData });
  }
  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  getSiteDetails() {
    const siteDetail = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getAllSiteDetails",
      }),
    };
    apiCalling(siteDetail).then((data) => {
      if (data.success === "1") {
        this.setState({
          siteList: data.siteData,
        });
      }
    });
  }

  getjournalDetails() {
    const journalData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getJournalsListing",
        siteId: "",
        journalId: this.props.match.params.journalId,
      }),
    };
    $(".loader").show();
    apiCalling(journalData).then((data) => {
      if (data.success === "1") {
        this.setState({
          titleTxt: data.result[0].journalTitle,
          fileNameWithPath: data.result[0].fileNameWithPath,
          fileNameOnly: data.result[0].fileNameOnly,
          imageName:
            data.result[0].imageNameOnly == ""
              ? []
              : data.result[0].imageNameOnly.split(","),
          imageNameWithPath:
            data.result[0].imageNameWithPath == ""
              ? []
              : data.result[0].imageNameWithPath.split(","),
          siteDataTxt: data.result[0].siteId,
          editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(data.result[0].description)
            )
          ),
          branchData: data.result[0].branchList
        });
        if (data.result[0].displayForBranches === true) {
          $("#displayForBranches").prop("checked", true);
          this.setState({ displayForBranches: [] });
        } else {
          $("#displayForBranches").prop("checked", false);
          this.setState({
            displayForBranches: data.result[0].displayForBranches,
          });
        }
      }
      $(".loader").hide();
    });
  }

  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  imageUploadHandler() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        siteId: "",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          s3bucketAccessKey: data.configPathDetails[0].s3bucketAccessKey,
        });
        this.setState({
          AWSAccessKeyId: data.configPathDetails[0].s3bucketSecretKey,
        });
        this.setState({
          bucket: data.configPathDetails[0].PurpleHealthS3bucket,
        });
        this.setState({
          bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
        });
        this.setState({
          S3BucketBlogImages: data.configPathDetails[0].S3BucketBlogImages,
        });
      }
      $(".loader").hide();
    });
  }

  fileChange = (e) => {
    imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert("Add file format with only JPG,JPEG,PNG format");
      return false;
    }
    if (imageFile.size > 30145728) {
      alert("File size must under 30 MB!");
      return false;
    } else {
      var fileObj = e.target.files[0];
      this.setState({ fileNameWithPath: window.URL.createObjectURL(fileObj) });
      this.fileUpload(imageFile);
    }
  };

  async fileUpload(file) {
    AWS.config.update({
      accessKeyId: this.state.s3bucketAccessKey,
      secretAccessKey: this.state.AWSAccessKeyId,
      region: this.state.region,
    });
    var bucketParams = {
      Bucket:
        this.state.bucket +
        "/" +
        this.state.bucketFolder +
        "/" +
        this.state.S3BucketBlogImages,
    };

    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = "";
    let fileName = this.uuidv4() + file.name;
    let fullFilePath = S3BucketMainFolder + fileName;
    imageFile = fileName;
    await s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          alert("There was an error uploading your photo: ", err.message);
        } else if (data) {
          this.setState({
            fileNameWithPath: data.Location,
            fileNameOnly: fileName,
          });
          return null;
        }
      }
    );
  }

  multipleFileChange = (e) => {
    let files = e.target.files;
    for (var i = 0; i < files.length; i++) {
      let imageFile = files[i];
      if (!imageFile.name.match(/\.(jpg|jpeg|png|pdf)$/)) {
        alert("Add file format with only JPG,JPEG,PNG format");
        return false;
      }
      if (imageFile.size > 30145728) {
        alert("File size must under 30 MB!");
        return false;
      } else {
        var fileObj = e.target.files[0];
        let imageNameArrLocal = this.state.imageNameLocal;
        let imageNameWithPathArrLocal = this.state.imageNameWithPathLocal;
        imageNameArrLocal.push(imageFile.name);

        let fileName = imageFile.name.match(/\.(pdf)$/)
          ? pdf
          : window.URL.createObjectURL(fileObj);
        imageNameWithPathArrLocal.push({
          name: fileName,
          isLocal: true,
          loading: 0,
        });

        this.setState({
          imageNameLocal: imageNameArrLocal,
          imageNameWithPathLocal: imageNameWithPathArrLocal,
        });

        this.uploadMutipleFile(imageFile);
      }
    }
  };

  uploadMutipleFile(file) {
    let imageNameArr = this.state.imageName;
    let imageNameWithPathArr = this.state.imageNameWithPath;

    let imageNameArrLocal = this.state.imageNameLocal;
    let imageNameWithPathArrLocal = this.state.imageNameWithPathLocal;

    AWS.config.update({
      accessKeyId: this.state.s3bucketAccessKey,
      secretAccessKey: this.state.AWSAccessKeyId,
      region: this.state.region,
    });

    /* turn offing the timeout*/
    AWS.config.httpOptions.timeout = 0;

    var bucketParams = {
      Bucket:
        this.state.bucket +
        "/" +
        this.state.bucketFolder +
        "/" +
        this.state.S3BucketBlogImages,
    };

    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = "";
    let fileName = this.uuidv4() + file.name;
    let fullMultiplePath = S3BucketMainFolder + fileName;
    let fileIndex = imageNameArrLocal.indexOf(file.name);

    s3.upload(
      {
        Key: fullMultiplePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          return null;
        } else {
          imageNameArrLocal.splice(fileIndex, 1);
          imageNameArr.push(fileName);
          imageNameWithPathArrLocal.splice(fileIndex, 1);
          imageNameWithPathArr.push(data.Location);

          this.setState({
            imageName: imageNameArr,
            imageNameWithPath: imageNameWithPathArr,
          });
        }
      }
    ).on("httpUploadProgress", (evt) => {
      imageNameWithPathArrLocal[fileIndex].loading = Math.round(
        (evt.loaded / evt.total) * 100
      );
      this.setState({
        imageNameWithPathLocal: imageNameWithPathArrLocal,
      });
    });
  }

  removeFile(filePath) {
    //Remove from imageNameWithPath
    let imageNameWithPath = this.state.imageNameWithPath;
    const imageNameWithPathIndex = imageNameWithPath.indexOf(filePath);
    if (imageNameWithPathIndex > -1) {
      imageNameWithPath.splice(imageNameWithPathIndex, 1);
    }

    //Remove from imageNameOnly
    let imageUrl = filePath.split("/");
    let imageUrlIndex = imageUrl.length - 1;

    let fileName = imageUrl[imageUrlIndex];
    let tempimageName = this.state.imageName;

    const index = tempimageName.indexOf(fileName);
    if (index > -1) {
      tempimageName.splice(index, 1);
    }
    this.setState({
      imageNameWithPath: imageNameWithPath,
      imageName: tempimageName,
    });
  }

  validateForm() {
    let editorState = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );

    if (this.state.titleTxt == "") {
      alert("Title required.");
    } else if (this.state.siteDataTxt == "") {
      alert("Site Details required.");
      return false;
    } else if (this.state.fileNameWithPath == "") {
      alert("Blog Image required.");
      return false;
    } else if (editorState == "") {
      alert("Detailed Description required.");
      return false;
    } else {
      return true;
    }
  }

  saveHandle = () => {
    let branchId = []
    if (this.validateForm()) {
      let siteId = $("#siteDataTxt").val();
      if (this.state.displayForBranches === false) {
        for (let i = 0; i < this.state.branchData.length; i++) {
          branchId.push(this.state.branchData[i].branchName);
        }
      }
      const saveField = {
        method: "POST",
        body: JSON.stringify({
          functionName: "saveJournals",
          siteId: siteId,
          journalId:
            this.props.match.params.journalId === "null"
              ? ""
              : this.props.match.params.journalId,
          fileName: this.state.fileNameOnly,
          title: this.state.titleTxt,
          description: draftToHtml(
            convertToRaw(this.state.editorState.getCurrentContent())
          ),
          imageName: this.state.imageName.join(),
          displayForBranches: this.state.displayForBranches,
          branches: branchId.toString(),
        }),
      };

      $(".loader").show();

      apiCalling(saveField).then((data) => {
        $(".loader").hide();
        if (data.success === "1") {
          let message =
            this.props.match.params.journalId > 0
              ? "Updated successfully "
              : "Save successfully";
          alert(message);
          this.cancelHandle();
        } else if (data.success === "0") {
          alert(data.errorMessage);
        }
      });
    }
  };

  cancelHandle() {
    window.location.href = "/adminManagement/journalListing";
  }

  srcTypeImage(ev) {
    ev.target.src = showImg;
  }
  branchDropdownList() {
    const branchDropdown = {
      method: "POST",
      body: JSON.stringify({
        "functionName": "branchListing",
        "keyWord": ""
      }),
    };
    $(".loader").show();
    apiCalling(branchDropdown).then((data) => {
      if (data.success == "1") {
        $(".loader").hide();
        this.setState({ branchesDropdown: data.result });
      }
      if (data.success == "0") {
        $(".loader").hide();
        this.setState({ branchesDropdown: [] });
      }
    });
  }
  handleBranchesChange = (e) => {
    if (!e.target.checked) {
      $("#BranchList").fadeIn("fast");
      this.setState({ displayForBranches: false });
    } else {
      $("#BranchList").fadeOut("fast");
      this.setState({ displayForBranches: true });
    }
  };
  render() {
    const { editorState } = this.state;

    return (
      <div className="purpleWrap">
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            <Header />
        <div className="container">
            <div className="col-md-12 p-0">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  <li>
                    <a href="/admin/dashboard">Dashboard</a>
                  </li>
                  <li>
                    <a href="/adminManagement/journalListing">
                      Journal List
                    </a>
                  </li>
                  <li>
                    <a href="" className="current">
                      Add Journal
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          <div className="addrepwrapperborders">
            
            <div className="col-sm-12 form-head-journal mb-0">Journal</div>
            <div className="form-section-brdr-jounal">
              <div className="row">
                <div className="row-margin col-lg-5">
                  <label className="col-sm-12 label-text-journal">
                    Add Title <span className="star-red-label">*</span>
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="input-design-journal"
                      placeholder="Blog Title"
                      id="titleTxt"
                      name="titleTxt"
                      value={this.state.titleTxt}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row-margin col-lg-4">
                  <label className="col-sm-12 label-text-journal">
                    Site Details <span className="star-red-label">*</span>
                  </label>
                  <div className="col-sm-12">
                    <select
                      className="input-design-journal"
                      id="siteDataTxt"
                      name="siteDataTxt"
                    //   onChange={this.handleChange}
                    value={this.state.siteDataTxt}
                    >
                      {/* <option value="">Select Site</option> */}
                      {this.state.siteList.map((obj) =>
                        obj.siteId === this.state.siteDataTxt ? (
                          <option key={obj.siteId} value={obj.siteId} selected hidden>
                            {obj.siteName}
                          </option>
                        ) : (
                          <option  hidden value={obj.siteId}>{obj.siteName} </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <label className="d-flex align-items-center mb-0">
                  Display For All Branches
                  <input
                    type="checkbox"
                    name="displayForBranches"
                    id="displayForBranches"
                    className="mx-3"
                    onChange={this.handleBranchesChange}
                  />
                </label>
                {this.state.displayForBranches === false ? (
                  <div className="col-md-12">
                    <div id="BranchList" className="row">
                      <div className="col-md-6 mb-3">
                        <div className="row">
                          <div className="col-md-4">
                            Branch List<span className="text-danger">*</span>
                          </div>
                          <div className="col-md-8 d-flex align-items-center">
                            <select
                              className="form-control"
                              onChange={(e) => this.handleBranchesAutoFill(e)}
                            >
                              <option value="" defaultChecked>Select Branches</option>
                              {this.state.branchesDropdown.map((data) => (
                                <option value={data.branchName}>{data.branchName}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        {/* {this.state.companyList.length > 0 ? 
                                        <div className="col-md-3">
                                        {this.state.companyList.map((data)=>(
                                            <>
                                                <label>Company list</label>
                                                <div className="d-flex align-items-center">
                                                    <input type="text" key={data.siteId} value={data.siteName} className="form-control"/>
                                                    <div className="input_icon">
                                                        <img src={Closebtn}/>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>:
                                    <span hidden></span>}  */}
                        {/* {this.state.branchData.length > 0 ? ( */}
                        <div className="row">
                          {this.state.branchData.map((data, i) => (
                            <div className="col-md-3">
                              {/* <label className="invisible">States List list</label> */}
                              <div className="d-flex align-items-center">
                                <input
                                  type="text"
                                  key={data.branchId}
                                  readOnly
                                  className="form-control"
                                  value={data.branchName}
                                />
                                <div className="input_icon">
                                  <a
                                    onClick={() =>
                                      this.handleCancelBranch(data.branchName, i)
                                    }
                                  >
                                    <img src={Closebtn} />
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        {/* // ) : (
                  //   <span hidden></span>
                  // )} */}
                      </div>
                    </div>
                  </div>) : ""}
                <div className="col-sm-6 mt-2 px-5">
                  <div className="fileUpload-medical-journal mr-3">
                    <span>Add Blog Image</span>
                    <input
                      type="button"
                      id="attach"
                      name="attach"
                      className="upload"
                    />
                    <input
                      type="file"
                      className="upload"
                      name="fileText"
                      id="fileText"
                      onChange={this.fileChange}
                    />
                  </div>
                  <div className="fileUpload-img">
                    <img
                      onError={this.srcTypeImage}
                      id="fileNameWithPath"
                      src={this.state.fileNameWithPath}
                      // id="blogImage"
                      width="50"
                    ></img>
                  </div>

                  <p className="note-journlimg">
                    Support File Type:JPEG,JPG,PNG
                  </p>
                </div>
                <div className="row-margin detldes-div col-sm-12">
                  <label className="col-sm-12 label-text-journal">
                    Detailed Description{" "}
                  </label>
                  <div className="col-sm-12">
                    <Editor
                      editorState={this.state.editorState}
                      initialEditorState={editorState}
                      toolbarclassName="toolbarclassName"
                      wrapperclassName="redactor-wrapper"
                      editorclassName="editorclassName"
                      onEditorStateChange={this.onEditorStateChange}
                      handlePastedText={() => false}
                      editorStyle={{
                        minHeight: "200px",
                        maxHeight: "200px",
                        overflowX: "hidden",
                        overflowY: "scroll",
                        borderWidth: "1px",
                        borderColor: "#989898",
                        borderStyle: "solid",
                        borderRadius: "10px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    />
                  </div>
                </div>
                <div className="row-margin col-lg-12">
                  <label className="col-sm-12 label-text-journal">
                    <a>
                      <img
                        src={attachment}
                        style={{
                          padding: "1px",
                          width: "30px",
                          height: "30px",
                        }}
                      ></img>{" "}
                      Attacthments
                    </a>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      multiple
                      id="fileAttacthments"
                      onChange={this.multipleFileChange}
                    />
                  </label>
                  <div className="row col-sm-12 mt-3">
                    {this.state.imageNameWithPath.map((x) => {
                      if (x.split(".").pop() == "pdf") {
                        return (
                          <div key={x} className="attchment-adddiv mb-3">
                            <a onClick={() => this.removeFile(x)}>
                              <img
                                src={cancel}
                                className="attachment-cancelImg"
                              ></img>
                            </a>
                            <img src={pdf} width="100" height="100" style={{ objectFit: "cover", objectPosition: "center" }}></img>
                            <a
                              href={x}
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                            >
                              <img
                                src={download}
                                className="attachment-downloadImg"
                              ></img>
                            </a>
                          </div>
                        );
                      } else {
                        return (
                          <div key={x} className="attchment-adddiv mb-3">
                            <a onClick={() => this.removeFile(x)}>
                              <img
                                src={cancel}
                                className="attachment-cancelImg"
                              ></img>
                            </a>
                            <img src={x} width="100" height="100" style={{ objectFit: "cover", objectPosition: "center" }}></img>
                            <a
                              href={x}
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                            >
                              <img
                                src={download}
                                className="attachment-downloadImg"
                              ></img>
                            </a>
                          </div>
                        );
                      }
                    })}

                    {this.state.imageNameWithPathLocal.map((x) => {
                      return (
                        <div key={x} className="attchment-adddiv mb-3">
                          <img src={x.name} width="100" height="100" style={{ objectFit: "cover", objectPosition: "center" }}></img>
                          <div
                            style={{
                              position: "absolute",
                              display: "block",
                              width: "100px",
                              height: "100px",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              zIndex: 2,
                              cursor: "pointer",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                fontSize: "40px",
                                color: "white",
                                transform: "translate(-50%,-50%)",
                              }}
                            >{x.loading}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-sm-12 formButton-divright mt-3">
                  <input
                    type="submit"
                    value={
                      this.props.match.params.journalId > 0 ? "Update" : "Save"
                    }
                    id="saveType"
                    className="formButton-save"
                    onClick={this.saveHandle}
                  ></input>
                  <input
                    type="button"
                    value="Cancel"
                    className="formButton-cancel"
                    onClick={() => this.cancelHandle()}
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </div>
        {folderName !== null && <Footer />}
          </section>
        </Suspense>
      </div>
    );
  }
}

import React, { Component, Suspense } from "react";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { apiCalling, apiUrlRelianceFunction } from "../apiService";
import Pagination from "react-js-pagination";
import moment from "moment";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class DoctorNetworkReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            doctorNetworkData: [],
            toDate: "",
            fromDate: "",
            totalCount: 0,
            activePage: 1,
            showMessage: "Waiting for data... "
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeTo = this.handleChangeTo.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.doctorNetworkReport();
    }

    handleChange = (date) => {
        this.setState({
            fromDate: date,
        });
    };
    handleChangeTo = (date) => {
        this.setState({
            toDate: date,
        });
    };

    handlePageChange(pageNumber) {

        let nameSelect = $("#nameSelect").val() === '0' ? '' : $("#nameSelect").val();
        let txtDrName = $('#txtDrName').val();
        let statusType = $('#statusType').val();

        window.scrollTo(0, 0);
        this.setState({ activePage: pageNumber });
        var offset;
        var max;
        if (pageNumber === "1") {
            offset = "0";
            max = "10";
            this.setState({
                activePage: pageNumber,
                isLoading: '0'
            })
        }
        else {
            offset = parseInt(pageNumber - 1) * 10;
            max = "10";
            this.setState({
                activePage: pageNumber,
                isLoading: '0'
            })
        }
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "doctorNetworkReport",
                repId: "",
                 siteId: '',
                // siteId:window.localStorage.getItem("siteId"),
                cmbSelect: nameSelect,
                txtInput: txtDrName,
                cmbStatus: statusType,
                fromDate: $('#fromDate').val(),
                toDate: $('#toDate').val(),
                Offset: JSON.stringify(offset),
                max: max
            }),
        };
        $(".loader").show();
        let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction
        apiFunction(apiJson).then((data) => {
            if (data.success === "1") {
                this.setState({
                    doctorNetworkData: data.networkData,
                    totalCount: data.totalCount,
                });
                $(".loader").hide();
            }
        });
    }

    doctorNetworkReport() {
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "doctorNetworkReport",
                repId: "",
                siteId: '', 
                // siteId: window.localStorage.getItem("siteId"), 
                Offset: "0",
                max: "10",
            }),
        };
        let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction
        apiFunction(apiJson).then((data) => {
            $(".loader").hide();
            if (data.success === "1") {
                this.setState({
                    doctorNetworkData: data.networkData,
                    totalCount: data.totalCount,
                    showMessage: data.networkData.length > 0 ? "" : "No data found"
                });
            }
            if (data.success === "0") {
                $(".loader").hide();
                this.setState({
                    doctorNetworkData: [],
                    showMessage: data.errorMessage
                });
            }
        });
    }

    changeText = () => {
        if ($("#nameSelect").val() !== '0') {
            document.getElementById("txtDrName").disabled = false
            $('input[name=txtDrName]').focus();
        }
        else {
            document.getElementById("txtDrName").disabled = true
        }
        document.getElementById("txtDrName").value = ""
    }

    searchHandler() {

        let nameSelect = $("#nameSelect").val() === '0' ? '' : $("#nameSelect").val();
        let txtDrName = $('#txtDrName').val();
        let statusType = $('#statusType').val();
        let startDate = $('#fromDate').val();
        let endDate = $('#toDate').val();

        if ($("#nameSelect").val() !== "0" && $("#txtDrName").val() === "") {
            var t = document.getElementById("nameSelect");
            var selectedText = t.options[t.selectedIndex].text;
            alert(selectedText + " Required");
            $("#txtDrName").focus();
            return false;
        }
        if (startDate > endDate) {
            alert("To date should be greater than or equal to from date")
            return false
        }
        else {
            const searchData = {
                method: "POST",
                body: JSON.stringify({
                    functionName: "doctorNetworkReport",
                    repId: "",
                    siteId: "",
                    // siteId: window.localStorage.getItem("siteId"),
                    cmbSelect: nameSelect,
                    txtInput: txtDrName,
                    cmbStatus: statusType,
                    fromDate: startDate,
                    toDate: endDate,
                    Offset: "0",
                    max: "10"
                }),
            };
            $(".loader").show();
            let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction
            apiFunction(searchData).then((data) => {
                if (data.success === "1") {
                    $(".loader").hide();
                    this.setState({
                        doctorNetworkData: data.networkData,
                        totalCount: data.totalCount,
                        showMessage: data.networkData.length > 0 ? "" : "No data found",
                        activePage: 1,
                    });
                }
                if (data.success === "0") {
                    $(".loader").hide();
                    this.setState({
                        doctorNetworkData: [],
                        showMessage: data.errorMessage
                    });
                }
            })
        }
    }


    render() {
        return (
            <div class="purpleWrap">
                <Suspense fallback={<div >Waiting...</div>}>
                    <section>
                        {folderName !== null &&
                            <Header />
                        }
                        <div className='loader'></div>
                        <div class="container">
                            <div class="main-wrapper1">
                                <div class="col-md-12 p-0">
                                    <ul class="breadcrumb">
                                        <li>
                                            <a href={"/dashboard/reportDashboard/" + this.props.match.params.groupHospitalId}>Reports</a>
                                            <i class="fa fa-chevron-right"></i>
                                        </li>
                                        <li>Doctor Network Report</li>
                                    </ul>
                                </div>
                                <div class="form-head">Doctor Network Report </div>
                                <div class="custom-search row">
                                    <div class="col-md-4 col-6 mb-3 custom-search-sub">
                                        <select class="input-design"
                                            id="nameSelect"
                                            name="nameSelect"
                                            onChange={() => this.changeText()}
                                        >
                                            <option value="0">- - Select - -</option>
                                            <option value="1">Send Doctor Name</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4 col-6 mb-3 custom-search-sub">
                                        <input
                                            type="text"
                                            class="input-design"
                                            id="txtDrName"
                                            name="txtDrName"
                                            disabled
                                        />
                                    </div>
                                    <div class="col-md-4 col-6 mb-3 custom-search-sub">
                                        <select class="input-design"
                                            id="statusType"
                                            name="statusType"
                                        >
                                            <option value="0">- - Status - -</option>
                                            <option value="Request Accepted">Request Accepted</option>
                                            <option value="Request Declined ">Request Declined</option>
                                            <option value="Pending">Pending</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4 col-6 mb-3 custom-search-sub">
                                        <DatePicker
                                            placeholderText="From Date"
                                            id="fromDate"
                                            name="fromDate"
                                            selected={this.state.fromDate}
                                            onChange={this.handleChange}
                                            dateFormat="dd/MM/yyyy"
                                            calendarClassName="react-date-picker"
                                        />
                                    </div>
                                    <div class="col-md-4 col-6 mb-3 custom-search-sub">
                                        <DatePicker
                                            placeholderText="To Date"
                                            id="toDate"
                                            name="toDate"
                                            selected={this.state.toDate}
                                            onChange={this.handleChangeTo}
                                            dateFormat="dd/MM/yyyy"
                                            calendarClassName="react-date-picker"
                                        />
                                    </div>
                                    <div class="col-md-3 col-10 mb-3 custom-search-sub">
                                        <input
                                            type="submit"
                                            value="Search"
                                            class="formButton m-0"
                                            onClick={() => this.searchHandler()}
                                        />
                                    </div>
                                </div>
                                {this.state.doctorNetworkData.length > 0 ?
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style={{ minWidth: "150px" }}>Send by Doctor Id</th>
                                                    <th style={{ minWidth: "150px" }}>Send by Doctor Name</th>
                                                    <th style={{ minWidth: "150px" }}>Received by Doctor Name</th>
                                                    <th style={{ minWidth: "150px" }}>Received by Doctor Id</th>
                                                    <th style={{ minWidth: "150px" }}>Contact Number</th>
                                                    <th style={{ minWidth: "150px" }}>Hospital Id</th>
                                                    <th style={{ minWidth: "150px" }}>Hospital Name</th>
                                                    <th style={{ minWidth: "150px" }}>Status</th>
                                                    <th style={{ minWidth: "125px" }}>Created Date</th>
                                                    <th style={{ minWidth: "150px" }}>Modified Date</th>
                                                    <th style={{ minWidth: "200px" }}>Request Message Content</th>
                                                    <th style={{ minWidth: "200px" }}>Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ fontSize: "12px" }}>
                                                {this.state.doctorNetworkData.map((data) => {
                                                    let createdOnDate = moment(data.createdOnDate, "DD-MM-YYYY").format("DD-MMM-YYYY")
                                                    let modifiedOnDate = moment(data.modifiedOnDate, "DD-MM-YYYY").format("DD-MMM-YYYY")
                                                    return (
                                                        <tr>
                                                            <td>{data.fromDoctorId}</td>
                                                            <td>{data.fromDoctorName}</td>
                                                            <td>{data.toDoctorName}</td>
                                                            <td>{data.toDoctorId}</td>
                                                            <td>{data.ToDoctorMobileNumber}</td>
                                                            <td>{data.hospitalId}</td>
                                                            <td>{data.hospitalName}</td>
                                                            <td>{data.status}</td>
                                                            <td>{createdOnDate === 'Invalid date' ? '' : createdOnDate}</td>
                                                            <td>{modifiedOnDate === 'Invalid date' ? '' : modifiedOnDate}</td>
                                                            <td>{data.requestMessageContent}</td>
                                                            <td>{data.remark === 'undefined' ? '' : data.remark}</td>

                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        {this.state.totalCount > 5 ?
                                            <div className="paginationSection orderm-paginationSection">
                                                <Pagination
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={10}
                                                    totalItemsCount={this.state.totalCount}
                                                    pageRangeDisplayed={10}
                                                    onChange={this.handlePageChange}
                                                />
                                            </div>
                                            : ""}
                                    </div>
                                    :
                                    <p className="error-msg text-center mb-0"> {this.state.showMessage} </p>
                                }
                            </div>
                        </div>
                        {folderName !== null &&
                            <Footer />
                        }
                    </section>
                </Suspense>
            </div>
        );
    }
}

import React, { Component } from "react";
import $ from "jquery";
const category1 = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/samad-hospitals/category1.png";
const category2 = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/samad-hospitals/category2.png";
const category3 = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/samad-hospitals/category3.png";
const category4 = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/samad-hospitals/category4.png";
const category5 = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/samad-hospitals/category5.png";
const category6 = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/samad-hospitals/category6.png";
const category7 = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/samad-hospitals/category7.png";
const category8 = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/samad-hospitals/category8.png";

export default class Speciality extends Component {
  constructor(props) {
    super(props);
    this.state = {
      specialitiesData: [],
      specialitiesTitle: "",
    };
  }
  componentDidMount() {
    const spc = JSON.parse(window.localStorage.getItem("Specialities"));
    if (spc) {
      // this.setState({ specialitiesTitle: spc[0].parentName });
      // this.setState({ specialitiesData: spc[0].sectionList });
    } else {
      // window.location.reload(false);
    }
  }
  editClick() {
    $("#mentalCategotyList").addClass("editActive");
  }
  cancelClick() {
    $("#mentalCategotyList").removeClass("editActive");
  }
  render() {
    return (
      <div className="mentalCategory" id="speciality">
        {/*<div className="mainHeading">{this.state.specialitiesTitle}</div>
        <div className="mentalSectionOverflow">
          <div className="mentalCategorySection">
            {this.state.specialitiesData.map((obj, index) => {
              return (
                <div className="mentalCategotyList">
                  <div className="mentalCategoryImage">
                    <img src={obj.childImage} />
                  </div>
                  <div className="mentalCategoryContent">
                    <span>{obj.childName}</span>
                    <p>{obj.childDescription}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>*/}
        <div className="mainHeading">Samad Facilities
        <div className="subHeading">Wrote Mentioning to Worth Mentioning</div>
        </div>
        
        <div className="mentalSectionOverflow">
          <div className="mentalCategorySection">
            <div className="mentalCategotyList "id="mentalCategotyList"
            >
              <div className="mentalCategoryImage">
                <img src={category1} alt="Well Women Clinic"/>
              </div>
              <div className="mentalCategoryContent">
                <span>Well Women Clinic:</span>
                <p>
                  Women are providedthe care and compassion to
                  understandmenstrual problems, and also improve their quality
                  of life. Basic screening for cervicalcancer will be done
                </p>
              </div>
            </div>
            <div className="mentalCategotyList">
              <div className="mentalCategoryImage">
                <img src={category2} alt="Fertility clinic"/>
              </div>
              <div className="mentalCategoryContent">
                <span>Fertility clinic:</span>
                <p>
                  Helping childless couples achieve their goal of a healthy
                  pregnancy.
                </p>
              </div>
            </div>
            <div className="mentalCategotyList">
              <div className="mentalCategoryImage">
                <img src={category3} alt="Painless labour clinic"/>
              </div>
              <div className="mentalCategoryContent">
                <span>Painless labour clinic:</span>
                <p>Women are offered painless labour during delivery.</p>
              </div>
            </div>
            <div className="mentalCategotyList">
              <div className="mentalCategoryImage">
                <img src={category4} alt="Clinical Laboratory"/>
              </div>
              <div className="mentalCategoryContent">
                <span>Clinical Laboratory:</span>
                <p>
                  Accredited Inhouse laboratory for Biochemical andSerological
                  Tests
                </p>
              </div>
            </div>
            <div className="mentalCategotyList">
              <div className="mentalCategoryImage">
                <img src={category5} alt="Genetic screening"/>
              </div>
              <div className="mentalCategoryContent">
                <span>Genetic screening:</span>
                <p>
                  In house lab for all necessary hormone assessments. Genetic
                  screening- Reliable first trimester screening for Genetic
                  anomalies and diagnosis of dreaded genetic syndromes in foetus
                  are carried out through Foetal Anomaly ultrasonography/ PRISCA
                  Tests.
                </p>
              </div>
            </div>
            <div className="mentalCategotyList">
              <div className="mentalCategoryImage">
                <img src={category6} alt="3D - 4D Ultrasonography"/>
              </div>
              <div className="mentalCategoryContent">
                <span>3D - 4D Ultrasonography:</span>
                <p>
                  Our Doctor’s help you Monitor your baby beforeyou see them.
                </p>
              </div>
            </div>
            <div className="mentalCategotyList">
              <div className="mentalCategoryImage">
                <img src={category7} alt="Hormone Laboratory"/>
              </div>
              <div className="mentalCategoryContent">
                <span>Hormone Laboratory:</span>
                <p>In house lab for all necessary hormone assessments.</p>
              </div>
            </div>
            <div className="mentalCategotyList">
              <div className="mentalCategoryImage">
                <img src={category8} alt=""/>
              </div>
              <div className="mentalCategoryContent">
                <span>
                  Amniocentesis / Chorionic Villus sampling / Foetal Reduction
                  IVF Laboratory:
                </span>
                <p>
                  Women are providedthe care and compassion to
                  understandmenstrual problems, and also improve their quality
                  of life. Basic screening for cervicalcancer will be done
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component, Suspense } from "react";
import $ from "jquery";
import { apiCalling } from "../apiService";
import { Link } from "react-router-dom";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class Textchat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      appDocId: "",
      appHosId: "",
      chatDoctorPanelId: "",
      razorPayKey: "",
      razorpaykeysecret: "",
      b_discountPercentage:"",
      b_promoCode:"",
      promocode:"",
      promocodeAmount:"",
      promocodePercentage:"",
      promomessage:"",
      ratePerSession:window.localStorage.getItem("messaging_cost"),
      promoCodeData:[],
      applyPromocode:window.localStorage.getItem("messaging_cost"),
    };
   
    $(".loader").show();
  }
  componentDidMount() {
    $(".loader").show();
    this.checkService()
    $("#closePopUp").click(function(){
      $("#term-main").hide();
    }) ; 
    $("#promoApplySection").hide();


    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "messageChatPayment",
        chatDoctorPanelId: window.localStorage.getItem("chatDoctorPanelId"),
        memberId: window.localStorage.getItem("userId"),
        doctorId: window.localStorage.getItem("appDocId"),
        hospitalId: window.localStorage.getItem("appHosId"),
      }),
    };
   //alert(apiJson.body);
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        window.localStorage.setItem("chatGroupId", data.result[0].chatGroupId);
        this.setState({ razorPayKey: data.result[0].razorPayKey });
        this.setState({ razorpaykeysecret: data.result[0].razorPaySecretKey });
        if (data.result[0].isExpired === false) {
          this.getStartChat(
            data.result[0].chatGroupId,
            data.result[0].memberId,
            data.result[0].doctorId,
            data.result[0].hospitalId
          );
        }
      }
    });
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }
  havePromoCode() {
    $("#promoApplySection").show();
    $("#havePromoCode").hide();
  }

  getStartChat(chatGroupId, memberId, doctorId, hospitalId) {
    $(".loader").show();

    const json = {
      method: "POST",
      body: JSON.stringify({
        functionName: "startTextChat",
        chatGroupId: chatGroupId,
        userId: window.localStorage.getItem("userId"),
        hospitalId: hospitalId,
      }),
    };
   // console.log(json.body);
    apiCalling(json).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
       // console.log("Data: ", data);
        window.localStorage.setItem("chatStore", data.result[0]);
        window.localStorage.setItem("API_KEY", data.result[0].API_KEY);
        window.localStorage.setItem("sessionId", data.result[0].sessionId);
        window.localStorage.setItem("token", data.result[0].token);
        window.localStorage.setItem("appDocId", doctorId);
        window.localStorage.setItem("appHosId", hospitalId);
        window.localStorage.setItem("chatDoctorPanelId", chatGroupId);
        window.localStorage.setItem("defaultMessages", data.result[0].defaultMessages);
        window.location.href = "/chat/chatmessage";
      }
    });
  }
  paymentHandler() {
    if ($("#invalidCheck").is(":checked") === true) {
      let email = window.localStorage.getItem("emailId");
      let mobileNumber = window.localStorage.getItem("mobileNumber");
      let userName = parseFloat(window.localStorage.getItem("userName"));
      var string = this.state.razorPayKey + ":" + this.state.razorpaykeysecret;
      var encodedString = btoa(string);
      let encode = encodedString;
      let options = {
        key: this.state.razorPayKey,
        amount:
          parseFloat(this.state.ratePerSession).toFixed(2) * 100,
        name: window.localStorage.getItem("userName"),
        description: "",
        image: "",
        handler: function (response) {
         // console.log("Razorrr", response);
          const paymenTId = response.razorpay_payment_id;
          $(".loader").show();
          const apiJson = {
            method: "POST",
            body: JSON.stringify({
              functionName: "savePaymentDetails",
              chatGroupId: window.localStorage.getItem("chatGroupId"),
              memberId: window.localStorage.getItem("userId"),
              doctorId: window.localStorage.getItem("appDocId"),
              hospitalId: window.localStorage.getItem("appHosId"),
              siteId: window.localStorage.getItem("siteId"),
            }),
          };
         // console.log("Razorrr---", apiJson.body);
          $(".loader").show();

          const razorpay = {
            method: "POST",
            body: JSON.stringify({
              functionName: "razorpay",
              paymentId: paymenTId,
              hospitalId: window.localStorage.getItem("appHosId"),
            }),
          };
          apiCalling(razorpay).then((data) => {
            $(".loader").show();
            const apiJson = {
              method: "POST",
              body: JSON.stringify({
                functionName: "savePaymentDetails",
                chatGroupId: window.localStorage.getItem("chatGroupId"),
                memberId: window.localStorage.getItem("userId"),
                doctorId: window.localStorage.getItem("appDocId"),
                hospitalId: window.localStorage.getItem("appHosId"),
                siteId: window.localStorage.getItem("siteId"),
                amount: data.razorPayData.amount,
                amount_refunded: data.razorPayData.amount_refunded,
                bank: data.razorPayData.bank,
                captured: data.razorPayData.captured,
                card_id: data.razorPayData.card_id,
                contact: data.razorPayData.contact,
                created_at: data.razorPayData.created_at,
                currency: data.razorPayData.currency,
                description: data.razorPayData.description,
                email: data.razorPayData.email,
                entity: data.razorPayData.entity,
                error_code: data.razorPayData.error_code,
                error_description: data.razorPayData.error_description,
                error_reason: data.razorPayData.error_reason,
                error_source: data.razorPayData.error_source,
                error_step: data.razorPayData.error_step,
                fee: data.razorPayData.fee,
                id: data.razorPayData.id,
                international: data.razorPayData.international,
                invoice_id: data.razorPayData.invoice_id,
                method: data.razorPayData.method,
                notes: [],
                order_id: data.razorPayData.order_id,
                refund_status: data.razorPayData.refund_status,
                status: data.razorPayData.status,
                tax: data.razorPayData.tax,
                vpa: data.razorPayData.vpa,
                wallet: data.razorPayData.wallet,
              }),
            };
            var element = JSON.parse(window.localStorage.getItem("appArray"));

           // console.log(">>apiJson>>>" + apiJson.body);

            apiCalling(apiJson).then((dataa) => {
              //$(".loader").hide();
              if (dataa.success === "1") {
                window.localStorage.setItem(
                  "paymentStatus",
                  dataa.result[0].paymentStatus
                );
                window.localStorage.setItem(
                  "chatGroupId",
                  dataa.result[0].chatGroupId
                );
                window.localStorage.setItem("transactionId", data.razorPayData.id);
                window.localStorage.setItem("orderNo", dataa.result[0].orderNo);
                window.localStorage.setItem("paymentId", dataa.result[0].paymentId);
                window.localStorage.setItem("amount", dataa.result[0].amount);
                window.location.href = "/patient/textchatsuccess";
              } else {
                alert("Error Found");
              }
            });
           });
        },
        prefill: {
          name: userName,
          email: email,
          contact: mobileNumber,
        },
        theme: {
          color: "#514886",
        },
      };

      let rzp = new window.Razorpay(options);
      rzp.open();
    } else {
      alert("Please agree with terms and conditions");
    }
  }
  checkService() {
   
    $(".loader").show();
    const doctorDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "hospitalService",
        siteId: window.localStorage.getItem("siteId"),
        doctorId: window.localStorage.getItem("appDocId"),
        hospitalId: window.localStorage.getItem("appHosId"),
      }),
    };   
    apiCalling(doctorDetails).then((data) => { 
     
      $(".loader").hide();
      if (data.success === "1") {     
        if(data.promoCodeData.length===0){
          $("#havePromoCode").hide();
        }    
        this.setState({"promoCodeData":data.promoCodeData});
      } else {
        this.checkService();
      }
    });
  }
  applyPromocode() {
    var applyPromocodeAmt = this.state.applyPromocode;
    let promoCode=$("#promoCode").val();
    let discountPercentage="";
    if(promoCode!=""){
   
    this.state.promoCodeData.map((obj) => {
      if(obj.promoCode===promoCode){
        discountPercentage=obj.discountPercentage;
      }
    });
    if(discountPercentage!=""){
      alert("Promo code applied successfully")
      let ratePerSession=applyPromocodeAmt
      let amountNew=(ratePerSession*discountPercentage)/100;
      let amount=ratePerSession-((ratePerSession*discountPercentage)/100)   
      this.setState({ratePerSession:amount===0 ? "0.00" : amount});
      this.setState({promocode:promoCode});
      this.setState({promocodePercentage:discountPercentage});
      this.setState({promocodeAmount:amount});
      this.setState({promomessage:"You have saved Rs. "+amountNew+" on the bill "})
    } 
    else{
      alert("Invalid promo code !")
      this.setState({ratePerSession:window.localStorage.getItem("messaging_cost")});
      this.setState({promomessage:""})
    }
  }
  else{
    alert("Please enter promo code properly ");
    $("#promoCode").focus();
  }
  }
  
  termcondtn() {
    $("#term-main").show();
  }
  
  NonpaymentHandler(){
    if ($("#invalidCheck").is(":checked") === true) {
    $(".loader").show();
    const jsonData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "messageChatFree",
        chatDoctorPanelId: window.localStorage.getItem("chatDoctorPanelId"),
        memberId: window.localStorage.getItem("userId"),
        doctorId: window.localStorage.getItem("appDocId"),
        hospitalId:  window.localStorage.getItem("appHosId"),
      }),
    };
    //console.log(jsonData.body);
    apiCalling(jsonData).then((data) => {
      if (data.success === "1") {
        window.localStorage.setItem("API_KEY", data.API_KEY);
        window.localStorage.setItem("sessionId", data.sessionId);
        window.localStorage.setItem("token", data.token);
        window.localStorage.setItem("appDocId", window.localStorage.getItem("appDocId"));
        window.localStorage.setItem("appHosId", window.localStorage.getItem("appHosId"));
        window.localStorage.setItem("chatDoctorPanelId", data.chatGroupId); //chatDoctorPanelId);
        window.location.href = "/chat/chatmessage";
      } else {
       
        //console.log("Error: API : messageChatFree");
      }
    });
  }else{
    alert("Please agree with terms and conditions");
  }
  }
  render() {
    //$(".loader").show();
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper">
            <div class="text-center form-head">Message Chat Payment</div>
            <div class="form-section">
              <div class="row chat-amount-outer">
                <label class="col-6 label-text-right label-text-cln">
                  Amount(Rs.) <span className="star-red">*</span>
                </label>
                <label class="col-6 text-center">
                  <i class="fa fa-rupee"></i>{" "}
                  {this.state.ratePerSession}
                </label>
              </div>

              <div class="alert alert-success col-md-7 col-lg-4 col-11 text-center cursor-pointer m-auto" id="havePromoCode" >
                <label
                  class="m-0 cursor-pointer"
                  onClick={(e) => {
                    this.havePromoCode();
                  }}
                >
                  Have a Promo code?
                </label>
              </div>

              <div class="row row-margin promoCodeBox m-auto" id="promoApplySection">
                <label class="col-sm-3 label-text pr-0 pt-1">
                  Promo code
                </label>
                <label class="col-sm-9">                 
                  <input type="text" class="input-design input-box-w-150 mb-2" id="promoCode"/>  
                 <input type="hidden" class="input-design input-box-w-150" id="promoCode_s" defaultValue={this.state.promocode}/>                
                 <input type="hidden" class="input-design input-box-w-150" id="promocodeAmount" defaultValue={this.state.promocodeAmount}/>                
                 <input type="hidden" class="input-design input-box-w-150" id="promocodePercentage" defaultValue={this.state.promocodePercentage}/>                

                  <span class="formButton-apply ml-2" onClick={(e) => {
                    this.applyPromocode();
                  }}>Apply</span><br></br>
                  <span id="promomessage" className="promomessage">{this.state.promomessage} </span>
                </label>
              </div>

              <div
                class="col-sm-12 term-condtn-main p-0 mb-3 text-center mt-3"
                for="invalidCheck"
              >
                <label className="checkbox-main">
                  <input type="checkbox" id="invalidCheck" />
                  <span class="checkbox"></span>
                </label>
                I agree with{" "}
                <a
                  href="#"
                  onClick={this.termcondtn}
                  style={{ color: "#464646" }}
                >
                  Terms and Conditions
                </a>
              </div>
            </div>

            <div className="col-sm-12 text-center mt-4">
              {/* <a href="../patient/textchatsuccess"> */}
              {this.state.ratePerSession !== "0.00" ? (
              <input
                type="submit"
                onClick={(e) => {
                  this.paymentHandler();
                }}
                value="Pay Now"
                className="formButton formButtonBold"
                id="paynowButton"
              ></input>
 ):(
  <input
  type="submit"
  onClick={(e) => {
    this.NonpaymentHandler();
  }}
  value="Chat Now"
  className="formButton formButtonBold"
  id="paynowButton"
></input>
 )}
              {/* </a> */}
              <Link to="/home/doctor-list-main/2">
                <input
                  type="button"
                  value="Cancel"
                  className="cancelButton formButtonBold"
                ></input>
              </Link>
            </div>
          </div>
        </div>
        <div class="popup-overlay" id="term-main" style={{ display: "none" }}>
          <div className="popup-content">
            <div className="modal-popup">
              <span
                className="close-popup  popup-hide"
               id="closePopUp"
              >
                &times;
              </span>
              <div className="header border-0"> Terms and Conditions</div>
              <div className="content">
                <div className="col-md-12">
                <p>{window.localStorage.getItem("book_appointment_terms")==="" ? <span  className='text-center d-block'>No, Terms and Condition</span> :window.localStorage.getItem("book_appointment_terms") }</p>

                </div>
              </div>
            </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../css/style.css";
import "../css/purpleresponsive.css";
import Logoheader from "../image/theme/purplelogo.png";
// import arrowDown from "../image/theme/down-aro.png";
// import arrowDownclr from "../image/theme/down-blue.png";
// import arrowredDown from "../image/theme/red-arrow.png";
// import arrowwhtDown from "../image/theme/white-back.png";
import menu from "../image/theme/hamburger.png";
import { Link } from "react-router-dom";
import Login from "./login";
import Signup from "./signup";
import ForgotPassword from "./forgot-password";
import $ from "jquery";
import { apiCalling } from "../apiService";
// import loader from "../image/theme/loader.gif";
export default class header extends Component {
  menuClick() {
    $(".menu ul").slideToggle();
  }
  componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    $("#signUpWrap,#signInWrap,#forgotPswdWrap").hide();
    $(".layoutPatientMenuTop").hide();
    $(".layoutDoctorMenuTop").hide();
    $("#loginMenu").click(function () {
      $("#signUpWrap").hide();
      $("#signInWrap").toggle();
      $("#forgotPswdWrap").hide();
    });
    $("#signUp").click(function () {
      $("#signInWrap").hide();
      $("#signUpWrap").show();
      $("#forgotPswdWrap").hide();
    });
    $("#signIn,#signInNew").click(function () {
      $("#signUpWrap").hide();
      $("#signInWrap").show();
      $("#forgotPswdWrap").hide();
    });
    $("#forgotPswd").click(function () {
      $("#forgotPswdWrap").toggle();
      $("#signUpWrap").hide();
      $("#signInWrap").hide();
    });
    $("html").click(function () {
      $(".signInWrap").hide();
    });

    $(".signInWrap,#loginMenu").click(function (event) {
      event.stopPropagation();
    });

    if (window.localStorage.getItem("isLogin") === "true") {
      $("#loginMenu").hide();
    }
    if (window.localStorage.getItem("role") === "ROLE_DOCTOR") {
      $(".layoutDoctorMenuTop").show();
    }
    if (window.localStorage.getItem("role") === "ROLE_USER") {
      $(".layoutPatientMenuTop").show();
    }

    $(".signOutPatientClick").click(function () {
      $(".loader").show();
      const logoutReq = {
        method: "POST",
        body: JSON.stringify({
          functionName: "logout",
          siteId: window.localStorage.getItem("siteId"),
          userId: window.localStorage.getItem("userId"),
        }),
      };

      apiCalling(logoutReq).then((data) => {
        if (data.success == "1") {
          localStorage.clear();
          window.location.href = "/";
          alert(data.successMessage);
        } else {
          alert("Logout Failed");
        }
        $(".loader").hide();
      });
    });
  }

  render() {
    return (
      <header>
        <span className="mobileToggle">
          <span></span>
        </span>
        <div className="container header">
          <div className="logo">
            <Link to="/">
              <img src={Logoheader}></img>
            </Link>
          </div>
          <div className="menu">
            <span className="menuIcon" onClick={() => this.menuClick()}>
              <img src={menu} alt=""></img>
            </span>
            <ul className="menu-ul">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/home/aboutus">About us</Link>
              </li>
              {
                <li>
                  <Link to="/home/doctor-list-main">Doctor List</Link>
                </li>
              }
              <li>
                <Link className="imgshowhide">
                  Solutions
                  <i
                    class="fa fa-chevron-down ml-1"
                    style={{ fontSize: "10px" }}
                  ></i>
                </Link>
                <span className=""></span>
                <div className="subMenuMain arrow-up">
                  <div className="subMenuList">
                    <Link to="/home/branded-apps-website">
                      Branded Apps & Websites
                    </Link>
                  </div>
                  <div className="subMenuList">
                    <Link to="/home/telehealth">Telemedicine Technology</Link>
                  </div>
                  <div className="subMenuList">
                    <Link to="/home/clinic-management">
                      Practice Management Solutions
                    </Link>
                  </div>
                </div>
              </li>
              {/* <li>
                <Link to="/home/price">Price</Link>
              </li> */}
              <li>
                <Link to="/home/clients">Clients</Link>
              </li>
              <li>
                <Link to="/home/contactus">Contact us</Link>
              </li>
              <li className="layoutPatientMenuTop menu-role">
                <Link className="imgshowhide menu-role-link">
                  Hi Patient
                  <i
                    class="fa fa-chevron-down ml-1"
                    style={{ fontSize: "10px" }}
                  ></i>
                </Link>
                {/* <span className=""></span> */}
                <div className="subMenuMain arrow-up">
                  <div className="subMenuList">
                    <Link to="/patient/profile"> My Profile</Link>
                  </div>
                  <div className="subMenuList">
                    <Link to="/patient/inbox"> Inbox</Link>
                  </div>
                  <div className="subMenuList">
                    <Link to="/patient/videocalllist">
                      Video/Audio Appointments
                    </Link>
                  </div>
                  <div className="subMenuList">
                    <Link to="/chatmessagegroup"> Chat</Link>
                  </div>
                  <div className="subMenuList">
                    <Link to="/patient/appointmentlist"> Appointments</Link>
                  </div>
                  <div className="subMenuList">
                    <Link to="/patient/healthrecordlogin"> Health Records</Link>
                  </div>
                  <div className="subMenuList">
                    <Link to="/patient/history"> History</Link>
                  </div>
                  <div className="subMenuList">
                    <Link to="#" className="signOutPatientClick">
                      {" "}
                      Sign Out
                    </Link>
                  </div>
                </div>
              </li>
              <li className="layoutDoctorMenuTop menu-role">
                <Link className="imgshowhide menu-role-link">
                  Hi Doctor
                  <i
                    class="fa fa-chevron-down ml-1"
                    style={{ fontSize: "10px" }}
                  ></i>
                </Link>
                {/* <span className=""></span> */}
                <div className="subMenuMain arrow-up">
                  <div className="subMenuList">
                    <Link to="/doctor/doctorprofile"> Profile</Link>
                  </div>
                  <div className="subMenuList">
                    <Link to="/doctor/dashboard"> My Dashboard</Link>
                  </div>
                  <div className="subMenuList">
                    <Link to="/doctor/doctorinbox"> Inbox</Link>
                  </div>
                  <div className="subMenuList">
                    <Link to="#" className="signOutPatientClick">
                      {" "}
                      Sign Out
                    </Link>
                  </div>
                </div>
              </li>

              <li>
                <span className="commonButton" id="loginMenu">
                  Login
                </span>
                <Login></Login>
                <Signup></Signup>
                <ForgotPassword></ForgotPassword>
              </li>
              {/* <li>
                <Link to="/home/contactus">
                  <span className="commonButton">Request a Demo</span>
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </header>
    );
  }
}

import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import tick from "../image/media/tick.png";
import { apiCalling } from "../apiService";
import attachment  from "../image/media/attachgrey.png";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class OldChatMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldChats: [],
      errorMessage:"Fetching data..."
    };
  }
  componentDidMount() {
    $("#promoApplySection").hide();
    this.oldChatShow();
  }
  oldChatShow() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "chat/getOldChatQuestions",
        siteId: window.localStorage.getItem("siteId"),
        hospitalId: "",
        userId: window.localStorage.getItem("userId"), //"19906",
        bundleId: "",
      }),
    };
    //console.log(apiJson.body);
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({
          oldChats: data.resultArray.oldChats,
        });
      }
      else{
        this.setState({errorMessage:"No Old Chat Available"})
      }
    });
  }
  havePromoCode() {
    $("#promoApplySection").show();
    $("#havePromoCode").hide();
  }
  getStartChat(chatGroupId, memberId, doctorId, hospitalId) {
    $(".loader").show();
    const json = {
      method: "POST",
      body: JSON.stringify({
        functionName: "startTextChat",
        chatGroupId: chatGroupId,
        userId: window.localStorage.getItem("userId"),
        hospitalId: hospitalId,
      }),
    };
   // console.log(json.body);
    apiCalling(json).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
       // console.log("Data: ", data);
        window.localStorage.setItem("chatStore", data.result[0]);
        window.localStorage.setItem("API_KEY", data.result[0].API_KEY);
        window.localStorage.setItem("sessionId", data.result[0].sessionId);
        window.localStorage.setItem("token", data.result[0].token);
        window.localStorage.setItem("appDocId", doctorId);
        window.localStorage.setItem("appHosId", hospitalId);
        window.localStorage.setItem("chatDoctorPanelId", chatGroupId);
        window.localStorage.setItem("defaultMessages", data.result[0].defaultMessages);
        window.location.href = "/chat/chatmessage";
      }
    });
  }
  onRefresh() {
    this.oldChatShow();
  }
  render() {
    //alert(this.state.oldChats.length);
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper-smwidth1">
            <div class="col-md-12 p-0">
              <ul class="breadcrumb">
                <li>
                  {window.localStorage.getItem("role") === "ROLE_USER" ? (
                    <Link to="/home/doctor-list-main/2">New Chat</Link>
                  ) : (
                    <Link to="/doctor/dashboard">Dashboard</Link>
                  )}
                  <i class="fa fa-chevron-right"></i>
                </li>
                <li>Old Chat(s)</li>
              </ul>
            </div>
            <div class="chat-outer">
              <div class="chat-header">
                <span class="form-head"> Old Chat(s)</span>
                <span class="chat-span">
                  <button
                    type="button"
                    class="btn-default"
                    onClick={() => this.onRefresh()}
                  >
                    Refresh
                  </button>
                </span>
                <span class="chat-span">
                  <Link to="/chat/chatmessagegroup">
                    <button type="button" class="btn-default">
                      Inbox
                    </button>
                  </Link>
                </span>
                <span class="chat-span">
                  {window.localStorage.getItem("role") === "ROLE_USER" ? (
                    <Link to="/home/doctor-list-main/2">
                      <button type="button" class="btn-default">
                        Start New Chat
                      </button>
                    </Link>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div class="chat-bodygroup">
                {this.state.oldChats.length > 0 ? (
                  <ul class="messages">
                    {this.state.oldChats.map((old) =>{
                       let messages =old.message;
                       if(typeof messages==='undefined'){
                         messages="";
                       }
                       if (messages) {
                         messages = messages
                           .replace("?-0", "?")
                           .replace("?-1", "?")
                           .replace("-1", "?");
                       }
                      return (
                      <li class="message message-brdr">
                        <Link
                          to="#"
                          class="text-decoration-none"
                          onClick={() =>
                            this.getStartChat(
                              old.chatGroupId,
                              old.memberId,
                              old.doctorId,
                              old.hospitalId
                            )
                          }
                        >
                          <div class="avatar">
                            <img src={old.imageName} class="avatarimg"></img>
                            {/* <span class="chatBubleOnline">
                              {old.isLogged === true ? <img src={tick} /> : ""}
                            </span> */}
                          </div>
                          <div class="textwrappergroup">
                            <div class="text-header">
                              {window.localStorage.getItem("role") === "ROLE_USER" ? (
                                <div className="textchat-namehead">
                                  {old.doctorName} - {old.hospitalName}
                                </div>
                              ) : (
                                <div className="textchat-namehead">
                                  {old.memberName}
                                </div>
                              )}
                              <time class="message-time">
                                {" "}
                                <i class="fa fa-clock-o"></i> {old.modifiedOn}
                              </time>
                            </div>
                            <p class="text">   
                                  <p> {messages.includes('https://') ? 
                                  <span ><img src={attachment} />  Attachment </span> 
                                   : messages}
                                   </p>
                               
                              </p>
                          </div>
                        </Link>
                      </li>
                    )})}
                  </ul>
                ) : (
                  <div class='p-2 text-center' style={{color:"red"}}>{this.state.errorMessage}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

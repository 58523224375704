import React, { Component, Suspense } from "react";
import * as ReactDOM from "react-dom";
import Dashboardleftmenu from "../doctor/dashboardleftmenu";
import $ from "jquery";
import { apiCalling, changeDateFormat } from "../apiService";
import DateTimePopup from "../doctor/datetime-popup";
import { Link } from "react-router-dom";
import moment from "moment";
import rx from "../image/media/rx.png";
import smsNotification from "../image/icons/notification.png";
import docsImg from "../image/icons/google-docs.png";
import prescriptionImg from "../image/theme/prescription.png";
import attachImg from "../image/media/attachgrey.png";
const folderName = window.localStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class VideoSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAppointments: [],
      missedAppointments: [],
      todayAppointments: [],
      completedAppointments: [],
      futureAppointments: [],
      currentAppointments: [],
      videoAppointments: [],
      hospitalList: [],
      doctorCurrentAppointments: [],
      confirmedAppointments: [],
      timePickerValue: moment(),
      breakStatus: "Need Break",
      callCompletedAppointments: [],
      isDoctorInit: false,
    };
    this.chatNow = this.chatNow.bind(this);
    this.confirmAppointment = this.confirmAppointment.bind(this);
    this.rejectOpenAppointment = this.rejectOpenAppointment.bind(this);
    this.enableDisableChat = this.enableDisableChat.bind(this);
    this.enterChatButton = this.enterChatButton.bind(this);

  }

  enterChatButton() {
    let hospitalId = $("#enterchatHospital").val();
    if (hospitalId) {
      $(".loader").show();
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "enterChat",
          doctorId: window.localStorage.getItem("userId"),
          hospitalId: hospitalId,
        }),
      };
      apiCalling(apiJson).then((data) => {
        if (data.success === "1") {
          alert("Successfully updated");
        } else {
          alert(data.errorMessage);
        }
        $(".loader").hide();
      });
    } else {
      alert("Please select billing hospital");
    }
  }
  rejectOpenAppointment(id) {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "rejectVideoChat",
        requestVideoAppointmentId: id,
      }),
    };
   // console.log(apiJson.body)
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $("#rowOpenApp"+id).hide();
        alert("Rejected Successfully");
      }
      else{
        alert("Can't reject please try again later")
      }
      $(".loader").hide();
     // window.location.reload();
    });
  }
  enableDisableChat(openByAdmin, videoChatTransactionId) {
    $(".loader").show();

    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "enable/disableVideoChat",
        status: openByAdmin === false ? "1" : "0",
        videoChatTransactionId: videoChatTransactionId,
      }),
    };

    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
      }
      $(".loader").hide();
    });
  }
  Call_Completed(appId) {
    //alert(appId);
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "completeChatStatus",
        requestVideoAppointmentId: appId,
        appointmentStatus: "Call Completed",
      }),
    };
   // console.log(apiJson.body)
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
       // alert(data.successMessage);
       alert("Call Completed Successfully")
       window.location.reload();
      }
      
      $(".loader").hide();
    });
  }
  doctorIniChatNow(
    reqAppointmentId,
    doctorVideoScheduleId,
    memberId,
    hospitalId,
    doctorId,
    callType
  ) {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "startChat",
        siteId: window.localStorage.getItem("siteId"),
        doctorVideoscheduleId: doctorVideoScheduleId,
        userId: memberId,
        hospitalId: hospitalId,
        doctorId: window.localStorage.getItem("userId"),
        cardId: "",
        siteId: window.localStorage.getItem("siteId"),
        bundleId: "",
        isSessionBased: "1",
        isFree: "0",
        sessionChatNo: "",
      }),
    };
    window.localStorage.setItem("vcPatientId", memberId);
    window.localStorage.setItem("vcDoctorVideoScheduleId", doctorVideoScheduleId);
    window.localStorage.setItem("vcHospitalId", hospitalId);
    window.localStorage.setItem("vcDoctorId", window.localStorage.getItem("userId"));
    window.localStorage.setItem("reqAppointmentId", reqAppointmentId)
    //console.log(apiJson.body);
    apiCalling(apiJson).then((data) => {
      //console.log(data)
      if (data.success === "1") {
        window.localStorage.setItem("apiKey", data.result[0].apiKey);
        window.localStorage.setItem("sessionId", data.result[0].sessionId);
        window.localStorage.setItem("token", data.result[0].token);
        window.localStorage.setItem(
          "doctorVideoScheduleId",
          data.result[0].doctorVideoscheduleId
        );
        window.localStorage.setItem(
          "vcScheduledTimeSlotId",
          data.result[0].scheduledTimeSlotId
        );
        window.localStorage.setItem("vcSessionChatNo", data.result[0].sessionChatNo);
        window.localStorage.setItem("drcallType", callType);
        window.localStorage.setItem("startChatData", JSON.stringify(data.result[0]));
        window.localStorage.setItem("vcchatGroupId", data.result[0].chatGroupId);
        //alert(data.result[0].chatGroup.chatGroupId)
        window.localStorage.setItem(
          "vc_showName1",
          data.result[0].loggedinDisplayName
        );
        window.localStorage.setItem("vc_showName2", "");
        window.localStorage.setItem("vc_showName3", "");

        this.pushNotification(
          data.result[0].doctorVideoscheduleId,
          memberId,
          hospitalId,
          data.result[0].doctorId,
          data.result[0].chatRoomId,
          data.result[0].calltype,
          data.result[0].name
        );

        window.location.href = "/doctor/Videocall";
      } else {
        alert(data.errorMessage);
      }
      $(".loader").hide();
    });
  }

  async pushNotification(
    doctorVideoScheduleId,
    memberId,
    hospitalId,
    doctorId,
    videoChatRoomId,
    calltype,
    username
  ) {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "sendNotification",
        doctorVideoscheduleId: doctorVideoScheduleId,
        patientId: memberId,
        hospitalId: hospitalId,
        doctorId: doctorId,
        videoChatRoomId: videoChatRoomId,
        siteId: window.localStorage.getItem("siteId"),
        calltype: calltype,
        username: username,
      }),
    };

    // alert(apiJson.body);
    apiCalling(apiJson).then((data) => { });
  }

  chatNow(
    room,
    doctorVideoScheduleId,
    memberId,
    hospitalId,
    doctorId,
    callType
  ) {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "acceptCall",
        doctorVideoScheduleId: doctorVideoScheduleId,
        patientId: memberId,
        hospitalId: hospitalId,
        doctorId: doctorId,
        videoChatRoomId: room,
        siteId: window.localStorage.getItem("siteId"),
        bundleId: "",
      }),
    };
   // console.log(apiJson.body);
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        window.localStorage.setItem("apiKey", data.result[0].apiKey);
        window.localStorage.setItem("sessionId", data.result[0].sessionId);
        window.localStorage.setItem("token", data.result[0].token);
        window.localStorage.setItem("doctorVideoScheduleId", doctorVideoScheduleId);
        window.localStorage.setItem("drcallType", callType);
        window.location.href = "/doctor/Videocall";
      } else {
        alert(data.errorMessage);
      }
      $(".loader").hide();
    });
  }
  confirmAppointment(id, dateTime,appTime) {   
    let curDateTime=new Date().getTime();;
    let aa=moment(appTime)
    let bb=moment(curDateTime)
    if(bb<=aa){     
    
    $(".loader").show();
    let newDate = new Date(parseInt(dateTime));
    let appDate = moment(newDate).format("DD/MM/YYYY");
    let appTime = moment(newDate).format("h:mm A");
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "confirmVideoAppointment",
        appointmentDate: appDate,
        appointmentTime: appTime,
        appointmentDateTime: new Date(
          moment(appDate, "DD/MM/YYYY").format("YYYY-MM-DD") +
            " " +
            moment(appTime, ["h:mm A"]).format("kk:mm:ss")
        ).getTime(),
        requestVideoAppointmentId: id,
        ratePerSession: "",
        ratePerSessionAudio: "",
        siteId: window.localStorage.getItem("siteId"),
        isConfirmAndDeduct: "",
        isReferralWithOtp: "",
      }),
    };
    apiCalling(apiJson).then((data) => { 

      if (data.success === "1") {
        $(".loader").hide();
        alert(data.successMessage);
        $("#rowOpenApp"+id).hide();
        this.confirmVideoAppointmentMail(data);
        //window.location.reload()
      } else {
        $(".loader").hide();
        alert(data.errorMessage);
      }
    });
  }
  else{
    alert("Appointment date & time is invalid please re-schedule")
  }
  }
  async confirmVideoAppointmentMail(data){
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "confirmVideoAppointmentMail",
        siteId: "",
        jsonMailOptions: data.jsonMailOptions,
        jsonAdminDocMailOptions: data.jsonAdminDocMailOptions,
        smsObj: data.smsObj,
        smsDoctorObj: data.smsDoctorObj,
        smsAdminObj: data.smsAdminObj,
       
      }),
    };
    //console.log(apiJson.body);
    apiCalling(apiJson).then((data) => {

    });

  }
  needbreak() {
    $("#needbreak-text").hide();
    $("#Resume-text").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "resumeChat",
        doctorVideoScheduleId: "",
      }),
    };
  }
  resumeChat() {
    $("#needbreak-text").show();
    $("#Resume-text").hide();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "needBreak",
        doctorVideoScheduleId: "",
      }),
    };
  }

  dateTimePopup(id, startTime) {
    window.localStorage.setItem("confirmId", id);
    window.localStorage.setItem("confirmStartTime", startTime);
    $("#dateTime-popup" + id).show();
    $("body").addClass("overflow-hidden");
  }

  componentDidMount() {
    $("#videoChat").addClass("dashBoardLeftMenu-active");
    $("#Resume-text").hide();
    //$(".callCompletedAppointments").hide();
    //$(".confirmedAppointments").hide();
    //$(".openAppointments").hide();
    //$(".missedAppointments").hide();
    //alert(window.localStorage.getItem("userId"));
    // $(".loader").show();
    this.checkFunction("init");
    // $(".loader").hide();

    setInterval(async () => {
      this.checkFunction("loop");
    }, 60000);
  }
  
  checkFunction(fg) {
    if (fg.includes("init")) {
      $(".loader").show();
    }
    let todate = moment(new Date()).format("DD/MM/YYYY");

    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "videoAudioDoctorAppointment",
        siteId: window.localStorage.getItem("siteId"),
        doctorId: window.localStorage.getItem("userId"),
        fromDate: todate,
        toDate: todate,
      }),
    };
    // console.log("apiJson.body  ", apiJson.body);
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({
          openAppointments: data.openAppointments,
          missedAppointments: data.missedAppointments,
          //todayAppointments: data.todayAppointments,
          // completedAppointments: data.completedAppointments,
          //futureAppointments: data.futureAppointments,
          confirmedAppointments: data.confirmedAppointments,
          //currentAppointments: data.currentAppointments,
          callCompletedAppointments: data.callCompletedAppointments,
          videoAppointments: data.videoAppointments,
          hospitalList: data.hospitalList,
          //doctorCurrentAppointments: data.doctorCurrentAppointments,
        }); 
        data.hospitalList.map((obj, vin) => {
         // alert(obj.isRecordingAvailable)
          window.localStorage.setItem("isRecordingAvailable" + obj.hospitalId, obj.isRecordingAvailable)
        });
        // if (data.openAppointments.length !== 0) {
        //   $(".openAppointments").show();
        // }
        // if (data.missedAppointments.length !== 0) {
        //   $(".missedAppointments").show();
        // }        
        // if (data.callCompletedAppointments.length !== 0) {
        //   $(".callCompletedAppointments").show();
        // }
        // if (data.confirmedAppointments.length !== 0) {
        //   $(".confirmedAppointments").show();
        // }
        // if (data.currentAppointments.length !== 0) {
        //   $(".CurrentAppointments").show();
        // }
        // if (data.doctorCurrentAppointments.length !== 0) {
        //   $(".doctorCurrentAppointments").show();
        // } //else {
        ////  $(".CurrentAppointments").show();
        // $(".doctorCurrentAppointments").show();
        // }
      } else {
       // console.log(data.errorMessage);
      }
      if (fg.includes("init")) {
        $(".loader").hide();
      }
    });
  }

  //callcompltd(memberId, hospitalId, doctorId) {
  // $("#pre_memberId").val(memberId);
  // $("#pre_hospitalId").val(hospitalId);
  // $("#pre_doctorId").val(doctorId);
  // $("#prescription").val("");
  // $("#callcompltd").show();
  //}

  savePrescription() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "prescribedMedicineContent",
        siteId: window.localStorage.getItem("siteId"),
        doctorId: window.localStorage.getItem("userId"),
        userId: $("#pre_memberId").val(),
        hospitalId: $("#pre_hospitalId").val(),
        prescription: $("#prescription").val(),
      }),
    };
   // console.log(apiJson.body);
    apiCalling(apiJson).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        alert("Saved Successfully");
        $("#callcompltd").hide();
        $("#prescription").val("");
      }
    });
  }

  cancelPrescription() {
    $("#prescription").val("");
    $("#callcompltd").hide();
  }


  sendNotification(id) {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "sendChatNotification",
        doctorVideoScheduleId: id,
        siteId: window.localStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        alert("SMS reminder sent to patient successfully");

      }
    });

  } 


  showAttachments(appId) {
    $("#showMyAttachments").show();
    const LazyPage = React.lazy(() => import("../doctor/showAttachments"));
    ReactDOM.render(
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <section>
            <LazyPage appId={appId} />
          </section>
        </Suspense>
      </div>,
      document.getElementById("attachmentCnt")
    );

  }
  openAppointments() {
    $(".openAppointments").show();
    $(".missedAppointments").hide();
    $(".confirmedAppointments").hide();
    $(".callCompletedAppointments").hide();
    $(".appntmnt-list-tab").removeClass("appntmnt-list-tab-active");
    $(".current-appntmnt").addClass("appntmnt-list-tab-active");
  }
  missedAppointments() {
    $(".missedAppointments").show();
    $(".openAppointments").hide();
    $(".confirmedAppointments").hide();
    $(".callCompletedAppointments").hide();
    $(".appntmnt-list-tab").removeClass("appntmnt-list-tab-active");
    $(".mis-appntmnt").addClass("appntmnt-list-tab-active");
  }
  confirmedAppointments() {
    $(".confirmedAppointments").show();
    $(".openAppointments").hide();
    $(".missedAppointments").hide();
    $(".callCompletedAppointments").hide();
    $(".appntmnt-list-tab").removeClass("appntmnt-list-tab-active");
    $(".today-appntmnt").addClass("appntmnt-list-tab-active");
    this.checkFunction("loop");
  }
  callCompletedAppointments() {
    $(".callCompletedAppointments").show();
    $(".confirmedAppointments").hide();
    $(".openAppointments").hide();
    $(".missedAppointments").hide();
    $(".appntmnt-list-tab").removeClass("appntmnt-list-tab-active");
    $(".complete-appntmnt").addClass("appntmnt-list-tab-active");
    this.checkFunction("loop");
  }
  allAppointments() {
    $(".callCompletedAppointments").show();
    $(".confirmedAppointments").show();
    $(".openAppointments").show();
    $(".missedAppointments").show();
    $(".appntmnt-list-tab").removeClass("appntmnt-list-tab-active");
    $(".all-appntmnt").addClass("appntmnt-list-tab-active");
  }
  cancelAttachment() {
    $("#showMyAttachments").hide()
  }
  render() {
    return (
      <div className="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div className="loader"></div>
        <div className="loader2"></div>
        <div className="container container-auto">
          <div className="main-wrapper1 mt-2 pt-0">
            <div className="row">
              <div className="col-lg-3">
                <Dashboardleftmenu></Dashboardleftmenu>
              </div>
              <div className="col-lg-9 mt-4 video-shed">
                <div className="dashboard-right">
                  <div class="appntmnt-list-tabmain appntmnt-list-tabmain-web">
                    <a class="appntmnt-list-tab all-appntmnt appntmnt-list-tab-active"
                      onClick={() => this.allAppointments()}>All </a>
                    <a class="appntmnt-list-tab current-appntmnt"
                      onClick={() => this.openAppointments()}>
                        <span class="videoSpan">Open Appointments</span> 
                      </a>
                    <a class="appntmnt-list-tab mis-appntmnt"
                      onClick={() => this.missedAppointments()}>Missed Appointments </a>
                    <a class="appntmnt-list-tab today-appntmnt"
                      onClick={() => this.confirmedAppointments()}>Confirmed Appointments </a>
                    <a class="appntmnt-list-tab complete-appntmnt"
                      onClick={() => this.callCompletedAppointments()}>Call Completed Appointments </a>

                  </div>

                  <div class="appntmnt-list-tabmain appntmnt-list-tabmain-mob">
                    <a class="appntmnt-list-tab all-appntmnt appntmnt-list-tab-active"
                      onClick={() => this.allAppointments()}>All </a>
                    <a class="appntmnt-list-tab current-appntmnt"
                      onClick={() => this.openAppointments()}>Open </a>
                    <a class="appntmnt-list-tab mis-appntmnt"
                      onClick={() => this.missedAppointments()}>Missed </a>
                    <a class="appntmnt-list-tab today-appntmnt"
                      onClick={() => this.confirmedAppointments()}>Confirmed </a>
                    <a class="appntmnt-list-tab complete-appntmnt"
                      onClick={() => this.callCompletedAppointments()}>Completed </a>
                  </div>

                  {this.state.openAppointments.length > 0 ?
                    <div>
                      <div
                        className="form-head mb-1 openAppointments videoSpan"
                        style={{ fontSize: "16px" }}
                      >
                        Open Appointments
                        <div class="notification-tag">{this.state.openAppointments.length}</div>
                      </div>
                      <div className="table-responsive openAppointments">
                        <table className="table border">
                          <thead style={{ fontSize: "12px" }}>
                            <th className="border-0">Requested Date </th>
                            <th className="border-0">Patient Name</th>
                            <th className="border-0">Service</th>
                            <th className="border-0">Attachments</th>
                            <th className="border-0">Appointment Date & Time</th>
                            <th className="border-0">Edit</th>
                            <th className="border-0"></th>
                          </thead>
                          <tbody>
                            {this.state.openAppointments.map((obj, index) => {
                              let appD = new Date(obj.appTime);
                              let reqD = new Date(obj.requestedDate);
                              var year = appD.getFullYear();
                              var month = ("0" + (appD.getMonth() + 1)).slice(-2);
                              var day = ("0" + appD.getDate()).slice(-2);
                              let showDate = year + "-" + month + "-" + day; //"2020-8-3";
                              // $("#" + obj.id).val(showDate);
                              return (
                                <tr id={"rowOpenApp"+obj.id}>
                                  <td>{reqD.toDateString()} </td>
                                  <td>{obj.patientName} </td>
                                  <td>{obj.appointmentType}</td>
                                  <td className="text-center">
                                    {obj.attachmentCount != "0" && (
                                      <img src={attachImg} width="15" alt="" className="cursor-pointer" title="File attachment"
                                        onClick={() =>
                                          this.showAttachments(
                                            obj.id
                                          )
                                        }
                                      ></img>
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap">
                                    {appD.toDateString()}{" "}
                                    {appD.toLocaleTimeString("en-IN", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })}
                                  </td>
                                  <td>
                                    <i
                                      class="fa fa-edit ml-2"
                                      onClick={() =>
                                        this.dateTimePopup(obj.id, obj.appTime)
                                      }
                                    ></i>
                                    <DateTimePopup
                                      id="datetimepopuoer"
                                      appId={obj.id}
                                      appTime={obj.appTime}
                                    ></DateTimePopup>
                                  </td>

                                  <td className="text-center">
                                    <p className="d-flex m-0">
                                      <span
                                        href="#"
                                        onClick={() =>
                                          this.confirmAppointment(
                                            obj.id,
                                            obj.appTime,
                                            obj.appTime
                                          )
                                        }
                                      >
                                        <button className="formButton formButton-sm m-0">
                                          Confirm
                                    </button>
                                      </span>
                                      <span
                                        onClick={() =>
                                          this.rejectOpenAppointment(obj.id)
                                        }
                                      >
                                        <button className="cancelButton formButton-sm m-0 ml-2">
                                          Reject
                                    </button>
                                      </span>
                                    </p>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    :
                  <div></div>
                  }
                  {this.state.missedAppointments.length > 0 ?
                    <div className="my-3">
                      <div
                        className="form-head mb-1 missedAppointments"
                        style={{ fontSize: "16px" }}

                      >
                        Missed Appointments
                        <div class="notification-tag">{this.state.missedAppointments.length}</div>
                    </div>
                      <div className="table-responsive missedAppointments">
                        <table className="table border">
                          <thead style={{ fontSize: "12px" }}>
                            <th className="border-0">Appointment Date & Time</th>
                            <th className="border-0">Patient Name</th>
                            <th className="border-0">Service</th>
                            <th className="border-0">Attachments</th>
                            {/* <th className="border-0">Requested Date</th> */}
                            <th className="border-0">Status</th>
                            <th className="border-0">Action</th>
                          </thead>
                          <tbody id="drcrtBody">
                            {this.state.missedAppointments.map((obj, index) => {
                              let appD = new Date(obj.appointmentDate);
                              //let reqD = new Date(obj.requestedDate);
                              return (
                                <tr>
                                  <td>
                                    {appD.toDateString()}{" "}
                                    {appD.toLocaleTimeString("en-IN", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })}
                                  </td>
                                  <td>{obj.patientName} </td>
                                  <td>
                                    {obj.hasAudioChat === 1 ? "Audio" : "Video"}
                                  </td>
                                  <td>
                                    {obj.attachmentCount != "0" && (
                                      <img src={attachImg} width="15" className="cursor-pointer" alt="" title="File attachment"
                                        onClick={() =>
                                          this.showAttachments(
                                            obj.reqAppointmentId
                                          )
                                        }
                                      ></img>
                                    )}
                                  </td>
                                  {/* <td>{reqD.toDateString()}</td> */}
                                  <td>{obj.appointmentStatus}</td>

                                  <td className="text-center">
                                    <span
                                      href="#"
                                      onClick={() =>
                                        this.doctorIniChatNow(
                                          obj.reqAppointmentId,
                                          obj.doctorVideoScheduleId,
                                          obj.memberId,
                                          obj.hospitalId,
                                          obj.doctorId,
                                          obj.hasAudioChat === 1 ? "audio" : "video"
                                        )
                                      }
                                    >
                                      <button className="formButton formButton-sm my-0" style={{ whiteSpace: "nowrap" }}>
                                        Chat Now
                                  </button>
                                    </span>
                                    <span
                                      onClick={() =>
                                        this.sendNotification(
                                          obj.doctorVideoScheduleId,

                                        )
                                      }
                                    >
                                      <img src={smsNotification} width="15" alt="" title="Send SMS reminder for video/audio chat"></img>

                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                   </div> : <div>
                    {/* <div
                        className="form-head mb-1 confirmedAppointments"
                        style={{ fontSize: "16px" }}
                      >
                        Missed Appointments
                        <div class="notification-tag">{this.state.missedAppointments.length}</div>
                      </div>
                      <div>No appointment(s) found </div> */}
                       </div>}

                  {this.state.confirmedAppointments.length > 0 ?
                    <div>
                      <div
                        className="form-head mb-1 confirmedAppointments"
                        style={{ fontSize: "16px" }}
                        
                      >
                        Confirmed Appointments
                      <div class="notification-tag">{this.state.confirmedAppointments.length}</div>
                      </div>
                      <div className="table-responsive confirmedAppointments">
                        <table className="table border">
                          <thead style={{ fontSize: "12px" }}>
                            <th className="border-0">Appointment Date & Time</th>
                            <th className="border-0">Patient Name</th>
                            <th className="border-0">Service</th>
                            <th className="border-0">Attachment </th>
                            <th className="border-0">Prescriptions Details</th>
                            <th className="border-0">Status</th>
                            <th className="border-0">Action</th>
                          </thead>
                          <tbody id="drcrtBody">
                            {this.state.confirmedAppointments.map((obj, index) => {
                              let appD = new Date(obj.appointmentDate);
                             // alert(appD)
                              let newDate = new Date();
                              //if(){alert("ok")}
                              return (
                                <tr>
                                  <td>
                                    {appD.toDateString()}{" "}
                                    {appD.toLocaleTimeString("en-IN", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })}
                                  </td>
                                  <td>{obj.patientName} </td>
                                  <td>
                                    {obj.hasAudioChat === 1 ? "Audio" : "Video"}
                                  </td>
                                  <td>
                                    {obj.attachmentCount != "0" && (
                                      <img src={attachImg} width="15" alt="" className="cursor-pointer" title="File attachment"
                                        onClick={() =>
                                          this.showAttachments(
                                            obj.reqAppointmentId
                                          )
                                        }
                                      ></img>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <div>
                                      <Link to={`/doctor/prescriptionList-${obj.hospitalId}-${obj.memberId}-${obj.patientName}-${obj.scheduledTimeSlotId}`}
                                      >
                                        <img src={prescriptionImg} width="15" className="" alt=""></img>
                                      </Link>

                                    </div>
                                  </td>
                                  <td>{obj.appointmentStatus}</td>

                                  <td
                                    className="text-center"
                                    style={{
                                      whiteSpace: "nowrap",
                                      display: "flex",
                                    }}
                                  >
                                    {/* {obj.appointmentStatus === "Call Completed" ? ( */}
                                    <div> 
                                      <Link to={`/prescriptionAdd-${obj.hospitalId}-${obj.memberId}-${obj.scheduledTimeSlotId}-${obj.patientName}`}
                                      >
                                        <img src={rx} width="15" className="" alt=""></img>
                                      </Link>

                                    </div>

                                    {/* ) : ( */}
                                    {obj.appointmentStatus !== "Call Completed" && newDate > appD && (
                                      <span
                                        href="#"
                                        onClick={() =>
                                          this.doctorIniChatNow(
                                            obj.reqAppointmentId,
                                            obj.doctorVideoScheduleId,
                                            obj.memberId,
                                            obj.hospitalId,
                                            obj.doctorId,
                                            obj.hasAudioChat === 1
                                              ? "audio"
                                              : "video"
                                          )
                                        }
                                      >
                                        <button className="formButton formButton-sm my-0">
                                          Chat Now
                                      </button>
                                      </span>
                                    )}
                                    <span
                                      onClick={() =>
                                        this.sendNotification(
                                          obj.doctorVideoScheduleId,

                                        )
                                      }
                                    >
                                      <img src={smsNotification} width="15" alt="" className=" ml-2" title="Send SMS reminder for video/audio chat"></img>

                                    </span>
                                    {obj.appointmentStatus === "Completed" ? (
                                      <span>
                                        <button
                                          className="cancelButton formButton-sm ml-2"
                                          onClick={() =>
                                            this.Call_Completed(
                                              obj.reqAppointmentId
                                            )
                                          }
                                        >
                                          Call Completed
                                    </button>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div> : <div>
                    {/* <div
                        className="form-head mb-1 confirmedAppointments"
                        style={{ fontSize: "16px" }}
                      >
                        Confirmed Appointments
                        <div class="notification-tag">{this.state.confirmedAppointments.length}</div>
                      </div>
                      <div>No appointment(s) found </div> */}
                       </div>}

                  {this.state.callCompletedAppointments.length > 0 ?
                    <div>
                      <div
                        className="form-head mb-1 callCompletedAppointments"
                        style={{ fontSize: "16px" }}
                      >
                        Call Completed Appointments 
                        <div class="notification-tag">{this.state.callCompletedAppointments.length}</div>
                      </div>
                      <div className="table-responsive callCompletedAppointments">
                        <table className="table border">
                          <thead style={{ fontSize: "12px" }}>
                            <th className="border-0">Date & Time </th>
                            <th className="border-0">Patient Name</th>
                            <th className="border-0">Service</th>
                            <th className="border-0">Attachments</th>
                            <th className="border-0">Status</th>
                            <th className="border-0">Prescriptions Details</th>
                            <th className="border-0">Prescriptions</th>
                          </thead>
                          <tbody>
                            {this.state.callCompletedAppointments.map((obj, index) => {
                              let appD = new Date(obj.appointmentDate);
                              let reqD = new Date(obj.requestedDate);

                              return (
                                <tr>
                                  <td>
                                    {" "}
                                    {appD.toDateString()}{" "}
                                    {appD.toLocaleTimeString("en-IN", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })}
                                  </td>
                                  <td>{obj.patientName} </td>
                                  <td>
                                    {obj.hasAudioChat === 1 ? "Audio" : "Video"}{" "}
                                  </td>
                                  <td>
                                    {obj.attachmentCount != "0" && (
                                      <img src={attachImg} width="15" className="cursor-pointer" alt="" title="File attachment"
                                        onClick={() =>
                                          this.showAttachments(
                                            obj.reqAppId
                                          )
                                        }
                                      ></img>
                                    )}
                                  </td>
                                  <td>{obj.appointmentStatus}</td>
                                  <td className="text-center">
                                    <div>
                                      <Link to={`/doctor/prescriptionList-${obj.hospitalId}-${obj.memberId}-${obj.patientName}-${obj.scheduledTimeSlotId}`}
                                      >
                                        <img src={prescriptionImg} width="15" className="" alt=""></img>
                                      </Link>

                                    </div>
                                  </td>
                                  <td className="text-center">
                                    <div>
                                      <Link to={`/prescriptionAdd-${obj.hospitalId}-${obj.memberId}-${obj.scheduledTimeSlotId}-${obj.patientName}`}
                                      >
                                        <img src={rx} width="15" className="" alt=""></img>
                                      </Link>

                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>:
                    <div>
                    {/* <div
                        className="form-head mb-1 confirmedAppointments"
                        style={{ fontSize: "16px" }}
                      >
                       Call Completed Appointments 
                        <div class="notification-tag">{this.state.callCompletedAppointments.length}</div>
                      </div>
                      <div>No appointment(s) found </div> */}
                       </div>
                    }

                  <div className="adminDashboardBox row mt-3">
                    {/* <div className="col-md-7">
                      <div className="adminLeftBox form-section-bgcolor">
                        <div className="adminLeftBoxBlock">
                          <p>
                            Profile
                            <a href="/doctor/doctorprofile">
                              <button className="formButtonBg pull-right">
                                Enter
                              </button>
                            </a>
                          </p>
                        </div>
                        <div className="adminLeftBoxBlock">
                          <p>
                            Appointment History
                            <a href="/doctor/chat-history">
                              <button className="formButtonBg pull-right">
                                Enter
                              </button>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-12">
                      <div className="adminRightBox form-section-bgcolor">
                        <div className="requirementBox">
                          <p>Minimum requirement for video chat</p>
                          <ul className="px-3">
                            <li>
                              Latest version of Chrome 30+
                              <a
                                className="linktextclr"
                                href="https://www.google.com/intl/en_uk/chrome/browser/"
                                target="_blank"
                              >
                                Download Chrome
                              </a>
                            </li>
                            <li>
                              Wired internet connection with least 500KBps speed
                            </li>
                            <li>A webcam </li>
                            <li>A microphone</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="popup-overlay" id="callcompltd" style={{ display: "none" }}>
          <div className="popup-content">
            <div className="modal-popup">
              <a className="close-popup popup-hide">&times;</a>
              <div className="header border-0"> Prescription Details</div>
              <div className="content">
                <div className="col-md-12">
                  <input type="hidden" id="pre_memberId" />
                  <input type="hidden" id="pre_hospitalId" />
                  <input type="hidden" id="pre_doctorId" />
                  <textarea
                    class="input-design"
                    rows="10"
                    id="prescription"
                  ></textarea>
                </div>
                <div class="text-center">
                  <input
                    type="button"
                    class="formButtonBg mx-1"
                    value="Save"
                    onClick={() => this.savePrescription()}
                  />
                  <input
                    type="button"
                    class="formButtonBg cancelButtonBg mx-1 popup-hide"
                    value="Cancel"
                    onClick={() => this.cancelPrescription()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="popup-overlay" id="showMyAttachments" style={{ display: "none" }}>
          <div className="popup-attach">
            <div className="modal-popup">
              <a className="close-popup  popup-hide" style={{ zIndex: "1" }} onClick={() => this.cancelAttachment()}>&times;</a>
              <div className="content" id="attachmentCnt" style={{ maxHeight: "fit-content" }}></div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
